import React, { useState, useEffect, Component, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import Select from 'react-select'; import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
import PhoneVerificationModel from '../../admin/PhoneVerificationModel';
import experienceProofType from '../../expocomp/ExperienceProof';
import JOB_SEARCH_TYPE from '../../json/jobsearchtype.json';
import ABBREVIATIONS from '../../json/abbreviations.json';
import AVAILABILITY_JSON from '../../json/availability.json';
import YearSelector from '../../expocomp/YearsNumbersLoopComp';
import MonthsNumberSelection from '../../expocomp/MonthsNumberLoopComp';
import GoogleAutocomplete from '../../expocomp/GoogleAutocomplate';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../expocomp/cropper-utils';
import TaxInput from '../../expocomp/AbnTfnTaxComp';
import customStyles from '../../expocomp/SelectStyleComp';
import JobTitleCAtegoryComp from '../../expocomp/JobTitleCAtegoryComp';

const Accountsetup = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   //const [isCustomizeVisible, isSetCustomizeVisible] = useState(true);
   const [signupId, setSignupId] = useState(true);
   const [userdetails, setUserdetails] = useState([]);
   const [editWorkExperienceID, setEditWorkExperienceID] = useState(0);
   const [editJobwantToApplyID, setEditJobwantToApplyID] = useState(0); const [step, setStep] = useState(1);

   const profilepic = (userdetails.profilepic) ? userdetails.profilepic : '/front/images/user.svg';
   const [profilePicUrlDefault, setProfilePicUrlDefault] = useState(true);
   const [profilePicUrl, setProfilePicUrl] = useState(profilepic);
   const [workExpLoading, setWorkExpLoading] = useState(false);
   const [workExpBtntxt, setWorkExpBtntxt] = useState('Next');
   const [userUploadIdentityDocuments, setUserUploadIdentityDocuments] = useState([]);
   const [userUploadAddressDocuments, setUserUploadAddressDocuments] = useState([]);
   const [userUploadPaySlipDocuments, setUserUploadPaySlipDocuments] = useState([]);
   const [userUploadResumeDocuments, setUserUploadResumeDocuments] = useState([]);
   const [userUploadVisaDocument, setUserUploadVisaDocument] = useState([]);
   const [countrySggestions, setCountrySggestions] = useState([])
   const [getCountryList, setCountryList] = useState([]);
   const [getCountryListOption, setCountryListOption] = useState([]);
   const [getAllJobtitle, setAllJobtitle] = useState([]);
   const [visaTypeList, setVisaTypeList] = useState([]);
   const [getAllStates, setGetAllStates] = useState([]);
   const [isIamaFresher, setIsIamaFresher] = useState(true);
   const [varifyDocumentList, setVarifyDocumentList] = useState([]);
   const [varifyDocumentListOption, setVarifyDocumentListOption] = useState([]);
   const [getAllCountry, setGetallCountry] = useState([]);
   const alphabeticRegex = /^[a-zA-Z]*$/;
   const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
   //const MonthNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">MM</option>); for (let i = 1; i < 13; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   //const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const YearsNumbersLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 2000; i < 2024; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const YearExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Years</option>); for (let i = 0; i < 50; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} {i == 1 ? 'Year' : 'Years'}</option>); } return itemElements }
   const MonthExpNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); for (let i = 0; i < 12; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i} Month{i == 1 ? '' : 's'}</option>); } return itemElements }
   const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
   const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }
   const currentYear = new Date().getFullYear();
   const YearFutureLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Year</option>); for (let i = currentYear; i <= currentYear + 10; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
   const getSignupUserData = () => { const signup_userdetails_string = localStorage.getItem('signup_userdetails'); return JSON.parse(signup_userdetails_string); }

   //const navigate = useNavigate();
   const [userloginToken, setUserLoginToken] = useState('');
   const getUserDetailsFun = (userid) => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=accountsetup');
            if (resData.data.status == 1) {
               const resDataArr = resData.data.data.results;

               if (resDataArr.is_loggedin_firsttime == 0) {
                  setUserdetails(resDataArr);
                  setUserLoginToken(resDataArr.login_token);
                  if (resDataArr.profilepic) {
                     setProfilePicUrl(resDataArr.profilepic);
                     setProfilePicUrlDefault(false)
                  }
                  setIsIamaFresher(parseInt(resDataArr.iam_fresher));
                  setUserUploadIdentityDocuments(resDataArr.identity_document_proof);
                  setUserUploadAddressDocuments(resDataArr.address_document_proof);
                  setUserUploadPaySlipDocuments(resDataArr.workexperience_payslips);
                  setUserUploadResumeDocuments(resDataArr.user_document_resume);
                  setUserUploadVisaDocument(resDataArr.user_document_visatype);
                  localStorage.setItem('signup_userdetails', JSON.stringify(resDataArr));
               } else {
                  navigate('/')
               }

            }
         } catch (error) { console.log(error); }
      })();
   }

   const localStorageData = () => {
      (async () => {

         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/localstorage_common_data?calling_by_api`);
            const data = response.data;
            if (data.status == 1) {
               const storageData = data.data;
               setCountryListOption(storageData.get_all_country_options);
               setVisaTypeList(storageData.get_all_visatype);
               setVarifyDocumentList(storageData.get_all_address_type);
               setGetallCountry(storageData.get_all_country);
               setVarifyDocumentListOption(storageData.get_all_address_type_options);
               setGetAllStates(storageData.get_all_states);
               localStorage.setItem('sessionStorageData', JSON.stringify(storageData));
            }
         } catch (error) { console.error(error); }

      })();
   }


   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      var is_user_login = localStorage.getItem('is_user_login');
      if (is_user_login == 1) {
         localStorage.clear();
      }
      if (userid) { setSignupId(userid); getUserDetailsFun(userid); }
      else { setTimeout(function () { window.location.replace("/login"); }, 100) }
      localStorageData();
   }, []);


   /**************************************/
   const handleNext = () => {
      if (step <= 10) {
         setStep((prevStep) => prevStep + 1);
         window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
         window.location.replace("/login");
      }
   };

   //const handleSkipStep = () => { setStep((prevStep) => prevStep + 1);  window.scrollTo({ top: 0, behavior: 'smooth' }); };
   const handlePrevious = () => { setStep((prevStep) => prevStep - 1); window.scrollTo({ top: 0, behavior: 'smooth' }); };
   const addMoreWorkExperinece = () => { setStep((prevStep) => prevStep - 1); };
   const setStepSeven = () => { setStep(7); };
   const setStepFive = () => { setStep(5); };
   const setStepNine = () => { setStep(9); };
   //const setStepNine = () => { setStep(9); };
   const editWorkExperience = (editid) => { setEditWorkExperienceID(editid); setStep((prevStep) => prevStep - 1); };
   const editJobwantToApply = (editid) => { setEditJobwantToApplyID(editid); setStep((prevStep) => prevStep - 1); };
   /******STEPS AND FUNCTION************/
   const formdataValue = (field, value) => {
      let length = value.length;
      if (field == 'address_user_state') { length = 3 }
      if (field == 'accept_agreeiment') { length = 3 }
      if (field == 'experience_years') { length = 3 }
      if (field == 'experience_months') { length = 3 }
      if (field == 'identity_proof_type') { length = 3 }
      if (field == 'dob_day') { length = 3 }
      if (field == 'iam_fresher') { length = 3 }
      if (field == 'type_of_visa') { length = 3 }
      if (field == 'profilepic') { length = 3 }

      if (length > 2) {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const formData = new FormData();
         formData.append('field_value', value);
         formData.append('uploaded_for', field);
         formData.append('userid', userid);
         axios.post('https://squadgoo.com.au/api.squadgoo.com.au/profile_field_update', formData)
            .then((response) => { getUserDetailsFun(userid) })
            .catch((error) => { console.error(error); });
      }
   };

   const editFileFromServer = async (field, id) => { }


   const formatOptionLabel = ({ label }) => (
      <span dangerouslySetInnerHTML={{ __html: label }} />
   );
   //editFileFromServer removeFileFromServer
   const redirectToDashboard = () => {
      const formData = new FormData();
      formData.append('email', userdetails.email);
      formData.append('login_token', userloginToken);
      formData.append('password', userloginToken);
      console.log("login_token=" + userdetails.login_token + '=' + userloginToken);
      //https://squadgoo.com.au/api.squadgoo.com.au/signup

      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/login', formData)
         .then((response) => {
            console.log(response.data);
            if (response.data.status == 1) {
               let getuserdata = response.data.data.user_data;
               let session_storage_data = response.data.data.session_storage_data;

               //localStorage.clear();
               // console.log(session_storage_data);
               //getuserdata.user_type  == 2 ? localStorage.setItem('squadGoUserType','recruiter') : localStorage.setItem('squadGoUserType','jobseeker');
               localStorage.setItem('app_version', 1);
               localStorage.setItem('squadGologin', JSON.stringify(getuserdata));
               localStorage.setItem('profileStatus', getuserdata.profile_enabled)
               localStorage.setItem('squadGologinSession', JSON.stringify(getuserdata))
               localStorage.setItem('sessionStorageData', JSON.stringify(session_storage_data))
               localStorage.setItem('validationdataCheckData', JSON.stringify(getuserdata.validationdata_check));
               dispatch(docStatusValueCheck({ doctype: 'all', type: 'onload', sessiondata: JSON.stringify(getuserdata.validationdata_check) }));
               if (getuserdata.user_type == 1) {
                  localStorage.setItem('squadGoUserType', 'admin');
                  navigate('/admin-dashboard')
               } else if (getuserdata.user_type == 4) {
                  localStorage.setItem('squadGoUserType', 'admin');
                  navigate('/admin-dashboard')
               } else if (getuserdata.user_type == 2) {
                  localStorage.setItem('squadGoUserType', 'recruiter');
                  navigate('/recruiter/dashboard')
               } else if (getuserdata.user_type == 3) {
                  localStorage.setItem('squadGoUserType', 'jobseeker');
                  navigate('/jobseeker/dashboard')
               } else {
                  navigate('/')
               }
            } if (response.data.status == 0) {
               // navigate('/login')
            } else {
               // navigate('/login')
            }
         })
         .catch((error) => {
            console.error(error);
         });

   }


   const Step1 = () => {

      const removeProfilePic = () => { setProfilePicUrl(''); setProfilePicUrlDefault(true); formdataValue('profilepic', '') }

      const profileUploadToServer = async (e) => {
         // fileUploadToServer('profilepic', e.target.files[0]);
         const file = e.target.files[0];
         const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            setProfilePicUrl(URL.createObjectURL(e.target.files[0])); setProfilePicUrlDefault(false);
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', 'profilepic');
               formData.append('userid', userid);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/profile_pic_upload`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               console.log('File uploaded successfully:', response.data);
               if (response.data.status == 1) {
                  // toast.success('Uploaded Successfully!');
                  setProfilePicUrl(response.data.data.fullurl); setProfilePicUrlDefault(false);
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      }


      /**********************************/
      const [showModal, setShowModal] = useState(false);
      const handleShowModal = () => { setShowModal(true); cameraOff() };
      const handleHideModal = () => { setShowModal(false); cameraOff(); setTakePicFromCam(false); };

      const videoRef = useRef(null);
      const [capturedImage, setCapturedImage] = useState(profilePicUrl ? profilePicUrl : '/front/images/user.svg');
      const [stream, setStream] = useState(null);
      const [takePicFromCam, setTakePicFromCam] = useState(false);
      const cameraOff = () => { if (stream) { const tracks = stream.getTracks(); tracks.forEach((track) => track.stop()); setStream(null); }; };

      const startCamera = async () => {
         setCapturedImage('')
         try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) { videoRef.current.srcObject = stream; setStream(stream); }
         } catch (error) { console.error('Error accessing camera:', error); }
      };

      const captureImage = () => {
         const canvas = document.createElement('canvas');
         canvas.width = videoRef.current.videoWidth;
         canvas.height = videoRef.current.videoHeight;
         const context = canvas.getContext('2d');
         context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
         const dataURL = canvas.toDataURL('image/png');
         setCapturedImage(dataURL);
         cameraOff(); setTakePicFromCam(true);
      };


      const stopCamera = () => {
         if (stream) {
            cameraOff();
            setCapturedImage(profilePicUrl ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + profilePicUrl : '/front/images/user.svg')
         }
         setCapturedImage(false);
         setTakePicFromCam(false);
      };

      const uploadImage = async () => {
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         try {
            const formData = new FormData();
            formData.append('file', capturedImage);
            formData.append('uploaded_for', 'profilepic');
            formData.append('userid', userid);
            const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/profile_pic_upload_bycamra`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            console.log('File uploaded successfully:', response.data);
            if (response.data.status == 1) {
               // toast.success('Uploaded Successfully!');
               setProfilePicUrl('https://squadgoo.com.au/api.squadgoo.com.au/' + response.data.data); setProfilePicUrlDefault(false);
            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      };


      /*-------------------------------------------------------------*/
      const [imageSrc, setImageSrc] = useState(null);
      const [crop, setCrop] = useState({ x: 0, y: 0 });
      const [zoom, setZoom] = useState(1);
      const [showModalCrop, setShowModalCrop] = useState(false);
      const [croppedImage, setCroppedImage] = useState(null);

      const handleCropFileChange = (event) => {
         const file = event.target.files[0];

         console.log(file);
         if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
               setImageSrc(e.target.result);
               setShowModalCrop(true); // Open the modal to crop the image
            };
            reader.readAsDataURL(file);

            event.target.value = null;
         }
      };

      const onCropComplete = async (croppedArea, croppedAreaPixels) => {
         try {
            const croppedData = await getCroppedImg(imageSrc, croppedAreaPixels);
            const reader = new FileReader();
            reader.onloadend = () => {
               setCapturedImage(reader.result);
            };
            if (croppedData.blob) {
               reader.readAsDataURL(croppedData.blob);
            }
            setCroppedImage(croppedData.fileUrl);
         } catch (error) {
            console.error('Error during cropping:', error);
         }
      };

      const handleSave = () => {
         if (croppedImage) {
            uploadImage();
            setProfilePicUrl(croppedImage);
            console.log('Cropped image to upload:', croppedImage);
         }
         setShowModalCrop(false);
      };


      return (
         <>
            <div>
               <div className="stepscont">
                  <div className="row">
                     <div className="col-md-12 stepscontright">
                        <form className="commonform">
                           <h3>Upload your photo</h3>
                           <div className="text-center form-group mb-3 mt-3">
                              <div className="profilephotodiv">

                                 <input className='signuppage_profile' type="file" accept="image/*" onChange={handleCropFileChange} />
                                 <img src={profilePicUrl}></img>
                                 {
                                    (profilePicUrlDefault) ?
                                       <>
                                          <div className="prfileupload">
                                             <input
                                                type="file"
                                                accept="image/x-png,image/jpeg"
                                                id="profilepic"
                                                onChange={profileUploadToServer}
                                             ></input>
                                             <span>Upload Photo</span>
                                          </div>
                                       </>
                                       : ''
                                 }
                                 <div className="dropdown pimgdrop">
                                    <button className=" dropdown-toggle" type="button" id="pimgdropmenu" data-bs-toggle="dropdown" aria-expanded="false">
                                       <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </button>
                                    {
                                       (!profilePicUrlDefault) ?
                                          <>
                                             <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                                <li>
                                                   <a className="dropdown-item updatepro" href="#">Update

                                                      {/* <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                                      id="profilepic"
                                                      onChange={profileUploadToServer} ></input> */}
                                                      <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                   </a>
                                                </li>
                                                <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                                <li  ><a onClick={(e) => removeProfilePic('profilepic')} className="dropdown-item" href="javascript:void(0)">Remove</a></li>
                                             </ul>
                                          </>
                                          :
                                          <>
                                             <ul className="dropdown-menu" aria-labelledby="pimgdropmenu">
                                                <li>
                                                   <a className="dropdown-item updatepro" href="#">Upload Photo
                                                      {/* <input type="file" accept="image/x-png,image/jpeg"
                                                         id="profilepic"
                                                         onChange={profileUploadToServer} ></input> */}
                                                      <input id="profilepic" type="file" accept="image/*" onChange={handleCropFileChange} />
                                                   </a>
                                                </li>
                                                <li  ><a onClick={(e) => handleShowModal()} className="dropdown-item" href="javascript:void(0)">Capture Image</a></li>
                                             </ul>
                                          </>
                                    }
                                 </div>
                                 <div className="editprofileicon">
                                 </div>
                              </div>
                           </div>
                           <div className="mb-3">
                              <p className="mb-0"><strong>Photo guidelines:</strong></p>
                              <p className="mb-0">1. Show your whole face and tops of your shoulders,</p>
                              <p className="mb-0">2. Take your sunglasses and hat off,</p>
                              <p className="mb-0">3. No photo of a photo, filters or alterations.</p>
                              <p className="mb-0">4. We only accepts JPG, JPEG  &amp; PNG images with the maximum size 1MB</p>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <Modal show={showModal} onHide={handleHideModal}>
               <div className="modal-dialog1 findastaffmodle">
                  <div className="modal-content">
                     <div className="modal-body">
                        <button type="button" className="btn-close" onClick={handleHideModal} data-bs-dismiss="modal" aria-label="Close"></button>
                        {
                           capturedImage ?
                              <>
                                 <img src={capturedImage} alt="Captured" className='captured_image' />
                                 <div className="form-group mb-2  text-center mt-2">

                                    {
                                       takePicFromCam ?
                                          <>
                                             <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Re-Capture</button>
                                             <button className='batn batn-blue  mr-2 ' onClick={uploadImage}>Set as Profile</button>
                                          </>
                                          :
                                          <>
                                             <button className='batn batn-orange mr-2  text-capitalize' onClick={startCamera}>Start Camera</button>
                                          </>
                                    }
                                 </div>
                              </>
                              :
                              <>
                                 <video className='captured_image' ref={videoRef} width="400" height="400" autoPlay />
                                 <div className="form-group mb-2 text-center mt-2">
                                    <button className='batn batn-orange mr-1 ml-1  text-capitalize' onClick={captureImage}>Take a picture</button>
                                    <button className='batn batn-blue  text-capitalize  mr-1 ml-1 ' onClick={stopCamera}>Stop Camara</button>
                                 </div>
                              </>
                        }
                     </div>
                  </div>
               </div>
            </Modal>


            <Modal show={showModalCrop} onHide={() => setShowModalCrop(false)}>
               <Modal.Body>
                  <button onClick={() => setShowModalCrop(false)} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  <div style={{ position: 'relative', width: '100%', height: '300px' }}>
                     <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1} // Makes the crop area square
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                        cropShape="round" // Set the cropping area to a circle
                     />
                  </div>
                  <input
                     type="range"
                     min="1"
                     max="3"
                     step="0.1"
                     value={zoom}
                     className='width-100percent mt-2'
                     onChange={(e) => setZoom(Number(e.target.value))}
                  />
               </Modal.Body>
               <div className='form-group mb-2  text-center mt-2'>

                  <Button className='batn batn-orange mr-2  text-capitalize' variant="secondary" onClick={() => setShowModalCrop(false)}>
                     Cancel
                  </Button>
                  <Button className='batn batn-orange mr-2  text-capitalize' variant="primary" onClick={handleSave}>
                     Save
                  </Button>
               </div>
            </Modal>


         </>
      );

   };

   const Step2 = () => {
      const [identityDocumentType, setIdentityDocumentType] = useState([])
      //const [inputValue, setInputValue] = useState('');
      const [fileBorderCss, setFileBorderCss] = useState(0);
      const DayNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">DD</option>); for (let i = 1; i < 32; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements; }
      const YearNumberLoop = (attr = '') => { const itemElements = []; itemElements.push(<option value="">YYYY</option>); for (let i = 1950; i < 2012; i++) { itemElements.push(<option selected={(attr != '' && attr.select_attr == i) ? true : false} value={i}>{i}</option>); } return itemElements }
      const Months = [{ name: 'January', key: 1, abbreviation: 'Jan' }, { name: 'February', key: 2, abbreviation: 'Feb' }, { name: 'March', key: 3, abbreviation: 'Mar' }, { name: 'April', key: 4, abbreviation: 'Apr' }, { name: 'May', key: 5, abbreviation: 'May' }, { name: 'June', key: 6, abbreviation: 'Jun' }, { name: 'July', key: 7, abbreviation: 'Jul' }, { name: 'August', key: 8, abbreviation: 'Aug' }, { name: 'September', key: 9, abbreviation: 'Sep' }, { name: 'October', key: 10, abbreviation: 'Oct' }, { name: 'November', key: 11, abbreviation: 'Nov' }, { name: 'December', key: 12, abbreviation: 'Dec' },];
      const MonthsHtml = (attr = '') => { const itemElements = []; itemElements.push(<option value="">Month</option>); Months.map((item, index) => (itemElements.push(<option selected={(attr != '' && attr.select_attr == item.name) ? true : false} value={item.name}>{item.name}</option>))); return itemElements }


      useEffect(() => {
         const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
         if (sessionStorageData) {
            setIdentityDocumentType(sessionStorageData.get_all_identity_documenttype);
         } else {
            (async () => {
               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_identity_documenttype");
                  if (resData.data.status == 1) { setIdentityDocumentType(resData.data.data.results); }
               } catch (error) { console.log(error); }
            })();
         }
      }, []);

      const [besicDetails, setBasicDetailsData] = useState({
         name_salute: userdetails.name_salute,
         identity_proof_type: userdetails.identity_proof_type,
         first_name: userdetails.first_name,
         middle_name: userdetails.middle_name,
         last_name: userdetails.last_name,
         dob_day: userdetails.dob_day,
         dob_month: userdetails.dob_month,
         dob_year: userdetails.dob_year,
         identity_document_proof_front: userdetails.identity_document_proof_front,
         identity_document_proof_backend: userdetails.identity_document_proof_backend
      });


      const basiDetailsUpdatefun = (event) => {
         const { name, value } = event.target;

         if ((name == 'first_name' || name == 'middle_name' || name == 'last_name') && !alphabeticRegex.test(value)) {
            return null;
         }

         setBasicDetailsData((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
      };



      const [validated, setValidated] = useState(false);
      const [documnetClassError, setCocumnetClassError] = useState('');

      const onBasicDetailsSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         console.log(userUploadIdentityDocumentsContent.length);

         setCocumnetClassError('');
         let isVarifiedfield = true;

         if (!event.target.identity_proof_type.value) {
            setCocumnetClassError('error_border');
            isVarifiedfield = false;
         }

         var lengthcheck = (besicDetails.identity_proof_type == 5 || besicDetails.identity_proof_type == 4) ? 0 : 1;
         console.log(lengthcheck);
         if (userUploadIdentityDocumentsContent.length > lengthcheck) { setFileBorderCss(0); console.log('ddd') } else { setFileBorderCss(1) }
         if (form.checkValidity() === false || !isVarifiedfield) {
            event.preventDefault(); event.stopPropagation(); console.log('error');
         } else if (userUploadIdentityDocumentsContent.length > lengthcheck) {
            try {
               setFileBorderCss(0);
               const queryParameters = new URLSearchParams(window.location.search)
               const userid = queryParameters.get("id");
               const formData = new FormData();
               formData.append('name_salute', event.target.name_salute.value);
               formData.append('first_name', event.target.first_name.value);
               formData.append('middle_name', event.target.middle_name.value);
               formData.append('last_name', event.target.last_name.value);
               formData.append('dob_day', event.target.dob_day.value);
               formData.append('dob_month', event.target.dob_month.value);
               formData.append('dob_year', event.target.dob_year.value);
               formData.append('identity_proof_type', event.target.identity_proof_type.value);
               formData.append('userid', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  handleNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
            // handleNext()
         }
         setValidated(true);
      };

      const [userUploadIdentityDocumentsContent, setUserUploadIdentityDocumentsContent] = useState(userUploadIdentityDocuments);
      const [fileLoadingContent, setLoadingContent] = useState(0);
      const userFileUploadToServerBasicFun = async (field, file) => {
         const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               setLoadingContent(1);
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setLoadingContent(0);
               if (response.data.status == 1) {
                  setUserUploadIdentityDocumentsContent(response.data.data.upload_array)
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };


      const removeFileFromServerBasic = async (field, id) => {
         if (window.confirm("Do you want to remove this file?")) {
            try {
               const formData = new FormData();
               formData.append('field', field);
               formData.append('file_id', id);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  toast.success('Removed Successfully!');
                  if (field == 'identity_document_proof') { setUserUploadIdentityDocumentsContent(response.data.data) }
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      }

      return (
         <div>
            <div className="stepscont">
               <Form noValidate validated={validated} onSubmit={onBasicDetailsSubmit}>
                  <div className="row">
                     <div className="col-md-12 stepscontright">
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span>
                           Basic Details
                        </h3>
                        <div className="row">
                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Salute<span className="starr">*</span></label>
                                    <select name="name_salute" className="form-control" onChange={basiDetailsUpdatefun} >
                                       {
                                          ABBREVIATIONS.map((item) => (
                                             <option selected={(besicDetails.name_salute == item.value) ? true : false} key={item.id} value={item.value}>{item.value}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                                 <div className="form-group mb-2 w-100">
                                    <label className="form-label">First name<span className="starr">*</span></label>
                                    <input type="text" className="form-control" name="first_name" required onChange={basiDetailsUpdatefun} value={besicDetails.first_name} placeholder="Andrew" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Middle name</label>
                                 <input type="text" className="form-control" name="middle_name" onChange={basiDetailsUpdatefun} value={besicDetails.middle_name} placeholder="Enter middle name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Last name<span className="starr">*</span></label>
                                 <input type="text" className="form-control" name="last_name" required value={besicDetails.last_name} onChange={basiDetailsUpdatefun} placeholder="Enderson" />
                              </div>
                           </div>
                           <div className="col-md-6">

                              <div className="form-group mb-2">
                                 <label className="form-label">Date of birth<span className="starr">*</span></label>
                                 <div className="d-flex datefildgruop">
                                    <select name="dob_day" required className="form-control mr-2 datefild" onChange={basiDetailsUpdatefun}>
                                       {<DayNumberLoop select_attr={besicDetails.dob_day} />}
                                    </select>
                                    <select name="dob_month" required className="form-control mr-2 monthfild" onChange={basiDetailsUpdatefun}>
                                       {<MonthsHtml select_attr={besicDetails.dob_month} />}
                                    </select>
                                    <select name="dob_year" required className="form-control yearfild" onChange={basiDetailsUpdatefun} >
                                       {<YearNumberLoop select_attr={besicDetails.dob_year} />}
                                    </select>
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Document for identity proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 <select disabled={(userUploadIdentityDocumentsContent.length > 0 && besicDetails.identity_proof_type) ? true : false} className="form-control" required={true} onChange={basiDetailsUpdatefun} name="identity_proof_type" fdprocessedid="m7dnpr">
                                    <option value="">Select document</option>
                                    {
                                       identityDocumentType.map((item, index) => (
                                          <option selected={(besicDetails.identity_proof_type == item.id) ? true : false} value={item.id}>{item.name}</option>
                                       ))
                                    }
                                 </select>
                              </div>
                           </div>

                           <div className="col-md-12 mb-1">
                              <div className="form-group mb-2">

                                 <label className="form-label">{userUploadIdentityDocumentsContent.length > 1 ? 'Uploaded identity proof' : userUploadIdentityDocumentsContent.length > 0 ? 'Upload your identity proof back side' : 'Upload your identity proof front side'}<span className="starr">*</span> </label>
                                 {

                                    <>
                                       {
                                          userUploadIdentityDocumentsContent.slice(0, ((besicDetails.identity_proof_type == 5 || besicDetails.identity_proof_type == 4) ? 1 : 2)).map((item, index) => (
                                             <>
                                                <div className="documentwrap d-flex align-items-center mb-2">
                                                   <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                      <p>Identity Document {index + 1}</p>
                                                   </a>
                                                   <div className="documentwrapinner d-flex align-items-center">
                                                      <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('identity_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                      <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerBasic('identity_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                    </>

                                 }
                                 {
                                    (userUploadIdentityDocumentsContent.length >= 2) ?
                                       ''
                                       :
                                       <>
                                          {((besicDetails.identity_proof_type == 5 || besicDetails.identity_proof_type == 4) && (userUploadIdentityDocumentsContent.length == 1)) ? '' : <div className={`form-group identityproof}`}  >
                                             <div className={`customuploadsec ${fileBorderCss ? 'bordercss' : ''}`} >
                                                {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerBasicFun('identity_document_proof', e.target.files[0], true, true)} ></input>}
                                                <div className="customuploadinner text-center">
                                                   <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                   <p className="mb-0">{fileLoadingContent ? 'Document is uploading....' : 'click here to upload a file'}</p>
                                                </div>
                                             </div>
                                          </div>}
                                       </>
                                 }

                              </div>
                              <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                           </div>

                        </div>
                     </div>
                  </div>
                  <div className="text-center">
                     <Button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >Save and Continue</Button>
                  </div>
                  <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
               </Form>
            </div>
         </div>
      );
   };

   const Step3 = () => {
      const [userUploadIdentityDocumentsContent, setUserUploadIdentityDocumentsContent] = useState(userUploadAddressDocuments);
      const [fileBorderCss, setFileBorderCss] = useState(0);
      const [addressSubrubId, setaddressSubrubId] = useState(userdetails.address_subrub_id);
      const [subRubSggestions, setSubRubSggestions] = useState([])
      const [inputValue, setInputValue] = useState(userdetails.address_suburbs);
      const [validated, setValidated] = useState(false);
      const [documentSelectError, setDocumentSelectError] = useState('');
      const [googleAddressError, setGoogleAddressError] = useState('');
      const [formValid, setFormValid] = useState(true);
      const [addressDetails, setAddressDetails] = useState({
         address_unit_no: userdetails.address_unit_no,
         address_building_no: userdetails.address_building_no,
         address_street_name: userdetails.address_street_name,
         address_suburbs: userdetails.address_suburbs,
         address_subrub_id: userdetails.address_subrub_id,
         address_user_state: userdetails.address_user_state,
         address_document_proof_type: userdetails.address_document_proof_type,
      });

      const setSubrubsIteminArray = (value) => { setInputValue(value); if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const getSubrubByli = (value, id = '') => { setInputValue(value); setaddressSubrubId(id); setSubRubSggestions([]); };


      const fetchSuggestions = async (value) => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_all_subrubs_name/${value}?responsetype=autocomplete`);
            const data = response.data;
            setSubRubSggestions(data);
         } catch (error) { console.error(error); }
      };


      /*google address component */
      const [address, setAddress] = useState({
         lat: '',
         lng: '',
         unit: '',
         houseNumber: '',
         streetName: '',
         suburb: '',
         city: '',
         state: '',
         country: '',
         placevalue: ''
      });

      //console.log(userdetails);
      const [googleAddressValue, setGoogleAddress] = useState(userdetails.full_address);
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddressError('');
         setGoogleAddress(`${newAddress.placevalue}`)
      };
      const handleInputChange = (e) => { setGoogleAddress(e.target.value); };




      //setInputValue(userdetails.address_suburbs);
      const addressDetailsUpdateFun = (event) => {
         const { name, value } = event.target;
         if (name === 'address_unit_no' && (!/^\d*$/.test(value))) {
            return; // Ignore non-numeric input
         }
         setAddressDetails((prevFormData) => ({
            ...prevFormData,
            [name]: value
         }));
      };




      const onAddressDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;

         let isChildFieldValid = true;
         setGoogleAddressError('');
         setDocumentSelectError('');
         setFileBorderCss('');
         if (!addressDetails.address_document_proof_type) {
            setDocumentSelectError('error_border');
            isChildFieldValid = false;
         }

         if (userUploadIdentityDocumentsContent.length < 2) {
            setFileBorderCss('bordercss');
            isChildFieldValid = false;
         }

         if (!googleAddressValue) {
            setGoogleAddressError('error_border');
            isChildFieldValid = false;
         }

         if (form.checkValidity() === false || !isChildFieldValid) {
            event.preventDefault();
            event.stopPropagation();
         } else if (userUploadIdentityDocumentsContent.length >= 1) {
            try {
               const formData = new FormData();
               formData.append('full_address', event.target.full_address.value);
               formData.append('address_unit_no', event.target.address_unit_no.value);
               formData.append('address_building_no', event.target.address_building_no.value);
               formData.append('address_street_name', event.target.address_street_name.value);
               formData.append('address_suburbs', event.target.address_subrub.value);
               formData.append('address_subrub_id', event.target.address_subrub_id.value);
               formData.append('address_user_state', event.target.address_user_state.value);
               formData.append('address_document_proof_type', addressDetails.address_document_proof_type);
               formData.append('userid', userid);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid);
                  handleNext()
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };


      const [fileLoadingContent, setLoadingContent] = useState(0);
      const userFileUploadToServerAddress = async (field, file) => {
         const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               setLoadingContent(1);
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setLoadingContent(0);
               if (response.data.status == 1) {
                  setUserUploadIdentityDocumentsContent(response.data.data.upload_array)
                  //toast.success('Uploaded Successfully!');
               } else { /*toast.warning(response.data.message);*/ }
            } catch (error) {  /*console.error('Error uploading file:', error);*/ }
         }
      };

      const removeFileFromServerAddress = async (field, id) => {
         if (window.confirm("Do you want to remove this file?")) {
            try {
               const formData = new FormData();
               formData.append('field', field);
               formData.append('file_id', id);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  toast.success('Removed Successfully!');
                  if (field == 'address_document_proof') { setUserUploadIdentityDocumentsContent(response.data.data) }

               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      }

      /*---------------------------*/
      const profileDocumentChange = (select) => {
         setAddressDetails((prevData) => ({ ...prevData, 'address_document_proof_type': select.value }));
         setDocumentSelectError('');
      }



      useEffect(() => {
         console.log(address);
         if (address && address.houseNumber) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_building_no']: address.houseNumber }));
         }
         if (address && address.state) {
            const stateObject = getAllStates.find((obj) => obj.state === address.state);
            if (stateObject) {
               setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_user_state']: stateObject.id }));
            }
         }

         if (address && address.streetName) {
            setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.streetName }));
         }

         if (address && address.city) {
            setInputValue(address.city);
            //setAddressDetails((prevFormData) => ({ ...prevFormData, ['address_street_name']: address.city }));
         }
      }, [address]);

      return (
         <div>

            <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
               <div className="stepscont">
                  <div className="row">
                     <div className="col-md-12 stepscontright">
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span ><img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img></span>
                           Address Details
                        </h3>
                        <div className="row">
                           <div className="col-md-12">
                              <div className="form-group mb-2 mr-2">
                                 <label className="form-label">Full address <span className="starr">*</span></label>
                                 <GoogleAutocomplete
                                    onSelect={googleAuotoCompelte} required
                                    name='full_address'
                                    value={googleAddressValue}
                                    // value={googleAddressValue}
                                    placeHolder='Enter full address'
                                    className={`form-control ${googleAddressError}`}
                                    onChange={handleInputChange}
                                 />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="d-flex">
                                 <div className="form-group mb-2 mr-2">
                                    <label className="form-label">Unit no</label>
                                    <input name="address_unit_no" value={addressDetails.address_unit_no} onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Unit no" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label className="form-label">House number<span className="starr">*</span></label>
                                    <input name="address_building_no" value={addressDetails.address_building_no} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="House number" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Street name<span className="starr">*</span></label>
                                 <input name="address_street_name" value={addressDetails.address_street_name} required onChange={addressDetailsUpdateFun} type="text" className="form-control" placeholder="Enter Street name" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">Suburb<span className="starr">*</span></label>
                                 <input type="text" className="form-control" required name='address_subrub' value={inputValue} placeholder="Enter your suburb" onChange={(e) => setSubrubsIteminArray(e.target.value)} />
                                 <input type="hidden" className="form-control" required name='address_subrub_id' value={addressSubrubId} />
                                 {
                                    subRubSggestions.length ?
                                       <>
                                          <ul className='auto_search_ul'>
                                             {
                                                subRubSggestions.map((suggestion) => (<li onClick={(e) => getSubrubByli(suggestion.name, suggestion.id)} key={suggestion.id}>{suggestion.name}</li>))
                                             }
                                          </ul>
                                       </>
                                       :
                                       ''
                                 }
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="form-group mb-2">
                                 <label className="form-label">State<span className="starr">*</span></label>
                                 <select className="form-control mr-2" required name="address_user_state" onChange={addressDetailsUpdateFun}>
                                    <option value="">Select state</option>
                                    {getAllStates.map((item, index) => (<option selected={addressDetails.address_user_state == item.id ? true : false} value={item.id} >{item.state}</option>))}
                                 </select>
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Document for address proof<span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                 {/* <select className="form-control" required disabled={(userUploadIdentityDocumentsContent.length > 0 && addressDetails.address_document_proof_type) ? true : false} name="address_document_proof_type" onChange={addressDetailsUpdateFun} >
                                    <option value="" >Select document</option>
                                    {
                                       varifyDocumentList.map((item, index) => (
                                          <option selected={addressDetails.address_document_proof_type == item.document_id ? true : false} value={item.document_id}>{item.document_type}</option>
                                       ))
                                    }
                                 </select> */}
                                 <Select className={`react_select  ${documentSelectError}`} name="address_document_proof_type" required aria-label="4"
                                    options={varifyDocumentListOption}
                                    isSearchable
                                    placeholder="Search..."
                                    styles={customStyles}
                                    onChange={profileDocumentChange}
                                    isDisabled={(userUploadIdentityDocumentsContent.length > 0 && addressDetails.address_document_proof_type) ? true : false}
                                    value={varifyDocumentListOption.find(item => item.value == addressDetails.address_document_proof_type)}
                                 />
                                 {/* varifyDocumentListOption */}
                                 {/* {
                                    (userUploadIdentityDocumentsContent.length > 0) && <input type='hidden' value={addressDetails.address_document_proof_type} name="address_document_proof_type" ></input>
                                 } */}
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <div className="form-group addressproofdoc">
                                    <label className="form-label">
                                       {
                                          userUploadIdentityDocumentsContent.length > 1
                                             ? 'Uploaded document'
                                             : userUploadIdentityDocumentsContent.length > 0 ? 'Upload address document second' : 'Upload address document first'
                                       }
                                       <span className="starr">*</span>
                                    </label>
                                    {
                                       <>
                                          {
                                             userUploadIdentityDocumentsContent.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Address Proof Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('address_document_proof', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerAddress('address_document_proof', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>
                                    }
                                    {
                                       userUploadIdentityDocumentsContent.length >= 2 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className={`customuploadsec ${fileBorderCss}`} >
                                                   {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerAddress('address_document_proof', e.target.files[0])}  ></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{fileLoadingContent ? 'Document is uploading....' : 'click here to upload a file'}</p>
                                                   </div>
                                                </div>
                                             </div>
                                          </>
                                    }
                                 </div>
                                 <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                              </div>
                           </div>
                           <div className="btnsgroup">
                              <div className="text-center">
                                 <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                              </div>
                              <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </Form>
         </div>
      );
   };

   const Step4 = () => {
      //const signup_userdetails = getSignupUserData();
      const [contactDetails, setContactDetails] = useState({
         unique_id: userdetails.unique_id,
         login_token: userdetails.login_token,
         email: userdetails.email,
         contact_country_code: userdetails.contact_country_code,
         contact_number: userdetails.contact_number,
         tax_type: userdetails.tax_type,
         tax_type_value: userdetails.tax_type_value,
         contact_status: userdetails.contact_status,
      });


      const [contactNumber, setContactNumber] = useState(userdetails.contact_number);
      const [storedContactNumber, setStoredContactNumber] = useState(userdetails.contact_number);
      const [contactNumberError, setContactNumberError] = useState('');
      const changeContactcode = (code) => { setContactDetails((preData) => ({ ...preData, ['contact_country_code']: code })) }
      const [validated, setValidated] = useState(false);
      const [validatedCnClass, setValidatedCnClass] = useState(false);

      const texttype = userdetails.tax_type ? userdetails.tax_type.toLowerCase() : 'abn';
      const [tax_type_value, setTaxTypeValue] = useState(userdetails.tax_type_value ? userdetails.tax_type_value : '');
      const [tax_type_value2, setTaxTypeValue2] = useState(userdetails.tax_type_value2 ? userdetails.tax_type_value2 : '');

      const [selectedOption, setSelectedOption] = useState(texttype);
      const [taxValues, setTaxValues] = useState({
         tfn: texttype == 'both' ? tax_type_value : texttype == 'tfn' ? tax_type_value : '',
         abn: texttype == 'both' ? tax_type_value2 : texttype == 'abn' ? tax_type_value : ''
      });

      const handleTaxValuesChange = (values) => {
         setTaxValues(values);
         if (selectedOption == 'both') { setTaxTypeValue(values.tfn); setTaxTypeValue2(values.abn); }
         if (selectedOption == 'abn') { setTaxTypeValue(values.abn); }
         if (selectedOption == 'tfn') { setTaxTypeValue(values.tfn); }
      };
      const handleOptionChange = (option) => { setSelectedOption(option); };





      const onContactDetailsSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         let isChildFieldValid = true;
         if (tax_type_value.trim() == "") {
            isChildFieldValid = false;
         }


         if (form.checkValidity() === false || !isChildFieldValid) {
            event.preventDefault(); event.stopPropagation();
         } else {

            try {
               const formData = new FormData();
               formData.append('tax_type_value', tax_type_value + ',' + tax_type_value2);
               formData.append('tax_type', selectedOption.toUpperCase());
               formData.append('userid', userid);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  getUserDetailsFun(userid); handleNext();
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }

         }
         setValidated(true);
      };


      // const [isContactNumberVerify, setisContactNumberVerify] = useState((contactDetails.contact_status == 1 ? false : false));
      // const isNumberVerificationDoneFun = (fromChidPass) => { setisContactNumberVerify(fromChidPass); setContactDetails((preData) => ({ ...preData, ['contact_status']: 1 })) }




      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onContactDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center"> <span className="stepbackarrow mr-1" ><img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img></span>
                           Tax Information
                        </h3>

                        <div className="row">
                           <TaxInput
                              onValuesChange={handleTaxValuesChange}
                              onOptionChange={handleOptionChange}
                              defaultOption={selectedOption}
                              defTaxValues={taxValues}
                              rowsizeCol="12"
                              className={' square-switch-field mr-0'}
                           />
                        </div>

                        <div className="btnsgroup">
                           <div className="text-center">
                              {/* disabled={contactDetails.contact_status == 1 ? false : true} */}
                              <button className="batn batn-orange w-100" type="submit" >Save and Continue</button>
                           </div>
                           {contactDetails.contact_status == 1 && <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>}
                        </div>
                     </Form>




                  </div>
               </div>
            </div>
         </div>
      )
   };

   const Step5 = () => {
      const signup_userdetails = getSignupUserData();
      //const [allJobRoleName, setAllJobRoleName] = useState('');
      const [workExperienceData, setWorkExperienceData] = useState([]);
      const [workExperienceReferenceData, setWorkExperienceReferenceData] = useState([{
         name: '',
         position: '',
         contactno: '',
         email: '',
      }]);
      const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
      const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);
      const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
      const [isAddNewJobtitleSuccessMessage, setIsAddNewJobtitleSuccessMessage] = useState('');
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");
      const [isChecked, setIsChecked] = useState(false);
      const [checkExperienceType, setCheckExperienceType] = useState(typeof (workExperienceData.work_expe_prooftype) == 'undefined' ? 'both' : workExperienceData.work_expe_prooftype);
      const [setNewJobTitleValue, setSetNewJobTitleValue] = useState('');
      const [workExperienceTitle, setWorkExperienceTitle] = useState('');
      const [defaultJobTitle, setDefaultJobTitle] = useState(workExperienceData.work_expe_jobtitle_id);

      const [uploadPaySlipDocumentsContents, setUserUploadPaySlipDocumentsContents] = useState(userUploadPaySlipDocuments);
      const [userUploadResumeDocumentsContents, setUserUploadResumeDocumentsContents] = useState(userUploadResumeDocuments);
      const [fileLoadingContent, setLoadingContent] = useState(0);
      const [workExpSlipsLoading, setWorkExpSlipsLoading] = useState(0);
      const [resumeLoading, setResumeLoading] = useState(0);
      const [jobTitleList, setJobtitleList] = useState([]);
      const [workExpDurationFrom, setWorkExpDurationFrom] = useState('');

      const [address, setAddress] = useState(null);
      const [getCurrentCountry, setGetCurrentCountry] = useState('');
      const [classForCategoryError, setClassForCategoryError] = useState('');
      const [classForTitleError, setClassForTitleError] = useState('');
      const [classForCountryError, setClassForCountryError] = useState('');




      const getUserDetailsFun = () => {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + signupId + '?callingby=accountsetup');
               if (resData.data.status == 1) {
                  setUserUploadResumeDocumentsContents(resData.data.data.results.user_document_resume)
               }
            } catch (error) { console.log(error); }
         })();
      }

      useEffect(() => {
         getUserDetailsFun();
         if (editWorkExperienceID) {
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience_byid/${editWorkExperienceID}`);
                  if (resData.data.status == 1) {
                     setWorkExperienceData(resData.data.data.results);
                     setGetCurrentCountry(resData.data.data.results.work_expe_countryname);
                     setGoogleAddress(resData.data.data.results.work_expe_subrub);
                     setWorkExpDurationFrom(resData.data.data.results.work_expe_duration_from_year);
                     setWorkExperienceTitle(resData.data.data.results.work_expe_jobtitle);
                     setDefaultJobTitle(resData.data.data.results.work_expe_jobtitle_id);
                     setCheckExperienceType(resData.data.data.results.work_expe_prooftype);
                     setIsChecked(parseInt(resData.data.data.results.is_currently_working));
                     setWorkExperienceReferenceData(resData.data.data.results.workexperience_referencetype);
                     setUserUploadPaySlipDocumentsContents(resData.data.data.results.workexperience_payslips);
                  }
               } catch (error) { console.log(error); }
            })();
         }

         const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
         if (sessionStorageData) {
            setJobtitleList(sessionStorageData.get_jobtitle_options);
         } else {
            (async () => {
               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobtitle_options");
                  if (resData.data.status == 1) {
                     setJobtitleList(resData.data.data.results);
                  }
               } catch (error) { console.log(error); }
            })();
         }
      }, []);



      /*-------start--------*/

      const getNewJobtitle = (e) => {
         setSetNewJobTitleValue(e.target.value);
      }

      const addJobTitleInList = (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         setIsAddNewJobtitleSuccessMessage('');
         if (setNewJobTitleValue && setNewJobTitleValue.length > 3) {
            setIsAddNewJobtitleError(false);
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${userid}/${setNewJobTitleValue}`);
                  if (resData.data.status == 1) {
                     //setJobtitleList(resData.data.data.results);
                     setJobtitleList(resData.data.data.jobtitle_option);
                     setIsAddNewJobModalShow(false);
                     setDefaultJobTitle(resData.data.data.last_id)
                     setIsAddNewJobtitleSuccessMessage(resData.data.message);
                  } else {
                     setIsAddNewJobtitleErrorMessage(resData.data.message)
                  }
               } catch (error) { console.log(error); }
            })();
         } else {
            setIsAddNewJobtitleError(true);
            setIsAddNewJobtitleErrorMessage('Please enter title');
         }
      }
      /*------end-------*/

      const handleAddMore = () => { setWorkExperienceReferenceData([...workExperienceReferenceData, '']); };
      const handleRemove = (index) => { const newInputs = [...workExperienceReferenceData]; newInputs.splice(index, 1); setWorkExperienceReferenceData(newInputs); };

      //handleAddMore
      const hideAndSeekSection = (event) => {
         const { name, checked } = event.target;
         if (checked) { formdataValue('iam_fresher', 1) } else { formdataValue('iam_fresher', 0) }
         setIsIamaFresher(prevState => !prevState);
      }


      const checkExperienceProof = (event) => { setCheckExperienceType(event.target.value); }

      const [validated, setValidated] = useState(false);
      const onWorkExperienceSubmit = async (event) => {
         event.preventDefault();
         const form = event.currentTarget;
         let isChildFieldValid = true;
         setClassForCategoryError('');
         setClassForTitleError('');
         setClassForCountryError('');
         //  const formData = new FormData(event.target);
         // if (!defaultJobTitle) {
         //    setClassForCategoryError('error_border');
         //    setClassForTitleError('error_border');
         //    isChildFieldValid = false;
         // }
         if (!getCurrentCountry) {
            setClassForCountryError('error_border');
            isChildFieldValid = false;
         }
         
        // alert();


         if (form.checkValidity() === false || !isChildFieldValid) {
            event.preventDefault();
            event.stopPropagation();
         } else {
            const formData = new FormData(event.target);

            const referenceDataValue = {};
            formData.forEach((value, name) => {
               if (name.endsWith('[]')) {
                  const inputName = name.slice(0, -2); // Remove the '[]' suffix
                  if (!referenceDataValue[inputName]) {
                     referenceDataValue[inputName] = [value];
                  } else {
                     referenceDataValue[inputName].push(value);
                  }
               } else {
                  referenceDataValue[name] = value;
               }
            });

            if (editWorkExperienceID) {
               formData.append('editid', editWorkExperienceID);
            }
            setWorkExpLoading(true);
            setWorkExpBtntxt('Please Wait...');
            try {
               let res = await fetch("https://squadgoo.com.au/api.squadgoo.com.au/work_experience_action", {
                  method: "POST",
                  body: formData,
               });
               if (res.status === 200) {
                  setWorkExpLoading(false);
                  setWorkExpBtntxt('Next');
                  if (editWorkExperienceID) { setEditWorkExperienceID(0); }
                  setStep((prevStep) => prevStep + 1);
               }
            } catch (err) { console.log(err); }
         }
         setValidated(true);
      };

      const handleCheckboxChange = () => { setIsChecked(!isChecked); };

      const handleChange = (event, id, fieldName) => {
         const updatedRows = workExperienceReferenceData.map((row) => {
            if (row.id === id) {
               const regex = /^\d+$/; // 10-digit number pattern
               if (fieldName == 'contactno' && !regex.test(event.target.value)) {
                  return { ...row, [fieldName]: '' };
               } else {
                  return { ...row, [fieldName]: event.target.value };
               }
            }
            return row;
         });
         setWorkExperienceReferenceData(updatedRows);
      };


      const customStyles = {
         option: (provided, state) => ({
            ...provided,
            padding: '3px 10px',
            fontSize: '13px'
         }),
         groupHeading: (provided) => ({
            ...provided,
            fontSize: '13px', // Change the font size for the category labels
            fontWeight: 'bold', // You can also make the category label bold if desired
            color: '#000'
         })
      };

      const joBTitleSelected = (select) => {
         setIsAddNewJobModalShow(false);
         setWorkExperienceTitle(select.label);
         setDefaultJobTitle(select.value);
         if (select.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
      }


      const userFileUploadToServerPaymentSlipFun = async (field, file) => {
         const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               setLoadingContent(1);
               setWorkExpSlipsLoading(0);
               setResumeLoading(0);
               if (field == 'workexperience_payslips') {
                  setWorkExpSlipsLoading(1);
               }
               if (field == 'user_document_resume') {
                  setResumeLoading(1);
               }
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('reference_id', editWorkExperienceID ? editWorkExperienceID : 0);
               formData.append('userid', userid);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               //console.log('File uploaded successfully:', response.data);
               setLoadingContent(0);
               if (response.data.status == 1) {
                  // console.log(response.data.data);
                  if (field == 'workexperience_payslips') {
                     setUserUploadPaySlipDocumentsContents(response.data.data.upload_array);
                     setWorkExpSlipsLoading(0);
                  }
                  if (field == 'user_document_resume') {
                     setUserUploadResumeDocumentsContents(response.data.data.upload_array);
                     setResumeLoading(0);
                  }
               } else { /*toast.warning(response.data.message);*/ }
            } catch (error) {  /*console.error('Error uploading file:', error);*/ }
         }
      };

      const removeFileFromServerWe = async (field, id) => {
         if (window.confirm("Do you want to remove this file?")) {
            try {
               setLoadingContent(1);
               const formData = new FormData();
               formData.append('field', field);
               formData.append('file_id', id);
               formData.append('reference_id', editWorkExperienceID ? editWorkExperienceID : 0);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               setLoadingContent(0);
               if (response.data.status == 1) {
                  console.log(response.data.data);
                  //toast.success('Removed Successfully!');
                  // if (field == 'identity_document_proof') { setUserUploadIdentityDocuments(response.data.data) }
                  // if (field == 'address_document_proof') { setUserUploadAddressDocuments(response.data.data) }
                  if (field == 'workexperience_payslips') { setUserUploadPaySlipDocumentsContents(response.data.data) }
                  if (field == 'user_document_resume') { setUserUploadResumeDocumentsContents(response.data.data) }
                  // if (field == 'user_document_resume') { setUserUploadResumeDocuments(response.data.data) }
                  // if (field == 'user_document_visatype') { setUserUploadVisaDocument(response.data.data) }

               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      }


      const [googleAddressValue, setGoogleAddress] = useState('');
      const googleAuotoCompelte = (newAddress) => {
         setAddress(newAddress);
         setGoogleAddress(`${newAddress.placevalue}`)
      };

      const handleInputChange = (e) => {
         setGoogleAddress(e.target.value);
      };


      /*-------------------------------------*/
      const countryNameChange = (select) => { setGetCurrentCountry(select.value); setClassForCountryError(''); }
      // const defaultOption = jobTitleList.flatMap(group => group.options).find(option => option?.value && option.value == defaultJobTitle);

      const handleJobTitleChange = (selectedJobTitle) => {
         setDefaultJobTitle(selectedJobTitle.value);

         if (selectedJobTitle.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
         console.log("Selected Job Title:", selectedJobTitle);
      };


      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright commonform">
                     <Form noValidate validated={validated} onSubmit={onWorkExperienceSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span>
                           Add Work Experience
                        </h3>
                        <div className="row">

                           <div className="col-md-12">
                              <div className="commonchecbox d-flex">
                                 <span className="agrrimentbox">
                                    <input type="checkbox" checked={isIamaFresher} onClick={hideAndSeekSection} id="i_am_freshaer" name="i_am_freshaer" />
                                    <span className="checkafter"></span>
                                 </span>
                                 <label className="checklabel" for="i_am_freshaer">I am a Fresher</label>
                              </div>
                           </div>
                           {/* {defaultJobTitle} */}
                           {

                              !isIamaFresher && <>
                                 <div className="col-md-12">
                                    <input type='hidden' value={workExperienceTitle} name="work_expe_jobtitle" id="work_expe_jobtitle" />
                                    <div className="form-group mb-2">


                                       <JobTitleCAtegoryComp
                                          defaultJobID={defaultJobTitle}
                                          onJobTitleChange={handleJobTitleChange}
                                          classForCategory={classForCategoryError}
                                          classForTitle={classForTitleError}
                                          fieldName={'work_expe_jobtitle_id'}
                                       />
                                       {/* <label className="form-label">Job title<span className="starr">*</span></label>
                                       <Select className='react_select' name="work_expe_jobtitle_id" required aria-label="4"
                                          options={jobTitleList}
                                          isSearchable
                                          placeholder="Search..."
                                          styles={customStyles}
                                          onChange={joBTitleSelected}
                                          value={defaultJobTitle}
                                       /> */}

                                       <input type='hidden' name="work_expe_jobtitle_id" value={defaultJobTitle} />

                                       {
                                          isAddNewJobModalShow && <>
                                             <div className="form-group mb-2 mt-2 graybox ">
                                                <label className="form-label">Add Job title <span className="starr">*</span> </label>
                                                <div className={isAddNewJobtitleError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                                   <input type="text" required={true} className="form-control" onChange={getNewJobtitle} value={setNewJobTitleValue} name="add_job_title" placeholder="Enter job title" />
                                                   <div className='input-group-append'>
                                                      <button className='batn batn-orange  add_newjobtitle' onClick={addJobTitleInList} >Add Title</button>
                                                   </div>

                                                </div>
                                                {isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>}
                                                {isAddNewJobtitleSuccessMessage && <><div className='error_message'>{isAddNewJobtitleSuccessMessage}</div></>}
                                             </div>
                                          </>
                                       }
                                    </div>
                                 </div>



                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Company name <span className="starr">*</span></label>
                                       <input type="text" required={true} name="work_expe_companyname" className="form-control" value={workExperienceData.work_expe_companyname} placeholder="Enter company name" />
                                    </div>
                                 </div>

                                 <div className="col-md-6">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Country <span className="starr">*</span></label>
                                       <div className="form-group mb-2">

                                          <Select
                                             className={`react_select ${classForCountryError}`}
                                             name="work_expe_countryname"
                                             required aria-label="4"
                                             options={getCountryListOption}
                                             isSearchable
                                             placeholder="Search..."
                                             styles={customStyles}
                                             onChange={countryNameChange}
                                             value={getCountryListOption.find(item => item.value == getCurrentCountry)}
                                          />
                                          {/* getCurrentCountry, setGetCurrentCountry */}
                                          {/* getCurrentCountry, setGetCurrentCountry */}
                                          {/* <select required={true} className="form-control" onChange={(e) => setGetCurrentCountry(e.target.value)} name="work_expe_countryname" >
                                             <option value="">Select</option>
                                             {
                                                getCountryList.map((item, index) => (
                                                   <option selected={workExperienceData.work_expe_countryname == item.id ? true : false} value={item.id}>{item.nicename}</option>
                                                ))
                                             }
                                          </select> */}
                                       </div>
                                    </div>
                                 </div>

                                 {
                                    (getCurrentCountry == 13) &&

                                    <>
                                       <div className="col-md-12">
                                          <div className="form-group mb-2">
                                             <label className="form-label">Suburb<span className="starr">*</span></label>
                                             <GoogleAutocomplete
                                                onSelect={googleAuotoCompelte} required
                                                name='work_expe_subrub'
                                                value={googleAddressValue}
                                                placeHolder='Enter your suburb'
                                                className="form-control"
                                                onChange={handleInputChange}
                                             />
                                             {/* <input type="text" className="form-control" required name='work_expe_subrub' value={subrubName} placeholder="Enter your suburb" onChange={(e) => setSubrubName(e.target.value)} /> */}
                                          </div>
                                       </div>
                                    </>

                                 }

                                 <div className="col-md-12">
                                    <div className="companyduration">
                                       <div className="d-flex  justify-content-between">
                                          <label className="form-label">Work duration <span className="starr">*</span></label>
                                          <div className="commonchecbox d-flex">
                                             <span className="agrrimentbox">
                                                <input type="checkbox" id="is_currently_working" name="is_currently_working" onChange={handleCheckboxChange} checked={isChecked} />
                                                <span className="checkafter"></span></span>
                                             <label className="checklabel" for="is_currently_working">Currently working here</label>
                                          </div>
                                       </div>

                                       {/* 
                                       workExpDurationFrom , setWorkExpDurationFrom
                                       */}
                                       <div className="form-group d-flex align-items-center">
                                          <div className="d-flex w-100">

                                             <div className="w-100 mr-2">
                                                <select required={true} className="form-control" name="work_expe_duration_from_month">
                                                   <MonthsNumberSelection startMonthName={workExperienceData.work_expe_duration_from_month} selectedMonth={workExperienceData.work_expe_duration_from_month} />
                                                </select>
                                             </div>
                                             <div className=" w-100">
                                                <select required={true} className="form-control" onChange={(e) => setWorkExpDurationFrom(e.target.value)} name="work_expe_duration_from_year">
                                                   <YearSelector selectedYear={workExperienceData.work_expe_duration_from_year} />
                                                </select>
                                             </div>

                                          </div>
                                          <div><label className="form-label label px-3">To</label></div>
                                          <div className="d-flex w-100">

                                             <div className="w-100 mr-2">
                                                <select required={true} className="form-control" name="work_expe_duration_to_month">
                                                   <MonthsNumberSelection startMonthName={workExperienceData.work_expe_duration_from_month} selectedMonth={workExperienceData.work_expe_duration_to_month} />
                                                </select>
                                             </div>
                                             <div className="mr-21 w-100">
                                                <select required={true} className="form-control" name="work_expe_duration_to_year">
                                                   {
                                                      workExpDurationFrom ?
                                                         <YearSelector selectedYear={workExperienceData.work_expe_duration_to_year} startYear={workExpDurationFrom} />
                                                         : <option value={''}>YYYY</option>
                                                   }
                                                </select>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="col-md-12">
                                    <div className="form-group mb-2">
                                       <label className="form-label">Job description <span className="starr">*</span></label>
                                       <div className="textareawrap">
                                          <textarea required={true} maxlength="1000" className="form-control" name="work_expe_jobdescription" placeholder="Describe your job details here" value={workExperienceData.work_expe_jobdescription}></textarea>
                                          <p>Max 1000 characters</p>
                                       </div>
                                    </div>
                                 </div>

                                 <label className="form-label">Experience proof <span className="starr">*</span></label>
                                 <div className="col-md-12">
                                    <div className="form-group mb-2">
                                       <div className="textareawrap">
                                          <select required={true} name='work_expe_prooftype' onChange={(e) => setCheckExperienceType(e.target.value)} className="form-control">
                                             <option value={''}>Select</option>
                                             {
                                                experienceProofType.map((item, index) => (
                                                   <option selected={workExperienceData.work_expe_prooftype == item.key} value={item.key}>{item.value}</option>
                                                ))
                                             }

                                          </select>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <input type='hidden' name="work_expe_prooftype" value={'both'} /> */}
                                 {/* {
                                    console.log(workExperienceReferenceData)
                                 } */}
                                 {
                                    (checkExperienceType == 'reference' || checkExperienceType == 'both') &&
                                    <>

                                       <div className="col-md-12" >

                                          {workExperienceReferenceData.map((item, indexText) => (
                                             <div key={indexText} className="form-group graybox mt-1 refreanceblockk">
                                                <label className="form-label">Reference {indexText + 1}
                                                   {indexText == 0 ? <span className="starr">*</span> : ''}
                                                </label>
                                                {
                                                   (indexText) ?
                                                      <>
                                                         <span className="cursor_pointer close_reference_btn1" onClick={() => handleRemove(indexText)}><img src="/front/images/workedit.svg" /></span>
                                                      </>
                                                      :
                                                      <></>
                                                }

                                                <div className="row">
                                                   <div className="col-md-6 mb-2">
                                                      <input required={indexText == 0 ? true : false} onChange={(event) => handleChange(event, item.id, 'name')} value={item.name} type="text" className="form-control" name="reference_name[]" placeholder="Full name" />
                                                   </div>
                                                   <div className="col-md-6 mb-2">
                                                      <input required={indexText == 0 ? true : false} onChange={(event) => handleChange(event, item.id, 'position')} value={item.position} type="text" className="form-control" name="reference_position[]" placeholder="Position" />
                                                   </div>
                                                   <div className="col-md-6 mb-2">
                                                      <input required={indexText == 0 ? true : false} maxLength={10} onChange={(event) => handleChange(event, item.id, 'contactno')} value={item.contactno} type="tel" className="form-control" name="reference_contactno[]" placeholder="Contact number" />
                                                   </div>
                                                   <div className="col-md-6 mb-2">
                                                      <input required={indexText == 0 ? true : false} onChange={(event) => handleChange(event, item.id, 'email')} value={item.email} type="email" className="form-control" name="reference_email[]" placeholder="Email address" />
                                                   </div>
                                                </div>

                                             </div>
                                          ))}
                                          <div className="addworkexp mb-4" onClick={handleAddMore}>
                                             <span className="cursor_pointer linktxt d-flex align-items-center">
                                                <img className="mr-2" src="/front/images/addwork.svg" />
                                                Add another reference
                                             </span>
                                          </div>

                                       </div>
                                    </>
                                 }
                                 {
                                    (checkExperienceType == 'payslips' || checkExperienceType == 'both') &&
                                    <>
                                       <div className="col-md-12">
                                          <div className="form-group addressproofdoc">
                                             <label className="form-label">{uploadPaySlipDocumentsContents.length > 0 ? 'Upload payslips' : 'Upload payslips'} (Maximum 12)

                                                {(checkExperienceType == 'payslips') ? <><span class="starr">*</span></> : <></>}
                                             </label>
                                             {
                                                <>
                                                   {
                                                      uploadPaySlipDocumentsContents.map((item, index) => (
                                                         <>
                                                            <div className="documentwrap d-flex align-items-center mb-2">
                                                               <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                                  <p>Payslip {index + 1}</p>
                                                               </a>
                                                               <div className="documentwrapinner d-flex align-items-center">
                                                                  <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('workexperience_payslips', item.id)}><img src="/front/images/edit.png" /></span>
                                                                  <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerWe('workexperience_payslips', item.id)}><img src="/front/images/delete.png" /></span>
                                                               </div>
                                                            </div>
                                                         </>
                                                      ))
                                                   }
                                                </>
                                             }
                                             {/* workExpSlipsLoading resumeLoading */}
                                             {
                                                uploadPaySlipDocumentsContents.length >= 12 ?
                                                   ''
                                                   :
                                                   <>
                                                      <div className="form-group identityproof">
                                                         <div className="customuploadsec">
                                                            {!workExpSlipsLoading && <input required={(checkExperienceType == 'payslips') ? true : false} type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerPaymentSlipFun('workexperience_payslips', e.target.files[0])} ></input>}
                                                            <div className="customuploadinner text-center">
                                                               <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                               <p className="mb-0">
                                                                  {
                                                                     workExpSlipsLoading ?
                                                                        <>Document is uploading.....</>
                                                                        :
                                                                        <>click here to upload a pay slip {uploadPaySlipDocumentsContents.length + 1}</>
                                                                  }

                                                               </p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </>
                                             }
                                          </div>
                                          <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                                       </div>
                                    </>
                                 }

                              </>
                           }


                           <div className="col-md-12 mb-3">
                              <label className="form-label">
                                 {userUploadResumeDocumentsContents.length > 0 ? 'Uploaded Resume' : 'Upload Resume'}
                                 <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span>
                              </label>
                              {
                                 <>
                                    {
                                       userUploadResumeDocumentsContents.map((item, index) => (
                                          <>
                                             <div className="documentwrap d-flex align-items-center mb-2">
                                                <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                   <p>Resume Document {index + 1}</p>
                                                </a>
                                                <div className="documentwrapinner d-flex align-items-center">
                                                   <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('user_document_resume', item.id)}><img src="/front/images/edit.png" /></span>
                                                   <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerWe('user_document_resume', item.id)}><img src="/front/images/delete.png" /></span>
                                                </div>
                                             </div>
                                          </>
                                       ))
                                    }
                                 </>
                              }
                              {
                                 userUploadResumeDocumentsContents.length >= 1 ?
                                    ''
                                    :
                                    <>
                                       <div className="form-group identityproof">
                                          <div className="customuploadsec">
                                             {!resumeLoading && <input type="file" accept=".doc, .docx, application/msword, application/pdf" onChange={(e) => userFileUploadToServerPaymentSlipFun('user_document_resume', e.target.files[0], true)}></input>}
                                             <div className="customuploadinner text-center">
                                                <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                <p className="mb-0">{resumeLoading ? 'Document is uploading.....' : 'click here to upload a file'}</p>
                                             </div>
                                          </div>
                                       </div>
                                       <p className="mt-2 purpleclr font12">Accept only doc and pdf files (Max file size 10 MB)</p>
                                    </>
                              }
                           </div>

                        </div>
                        <div className="btnsgroup">
                           <input type='hidden' name="userid" value={userid} ></input>
                           {
                              isIamaFresher ?
                                 <>
                                    <div className="text-center">
                                       <button className="batn batn-orange w-100" type="button" onClick={setStepSeven}  >{(fileLoadingContent == 1) ? 'Please wait...' : workExpBtntxt}</button>
                                    </div>
                                    <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={setStepSeven} >Skip this step</button></div>
                                 </>
                                 :
                                 <>
                                    <div className="text-center">
                                       {
                                          isIamaFresher ?
                                             <><button disabled={fileLoadingContent} className="batn batn-orange w-100" type="button" onClick={handleNext}  >{(fileLoadingContent == 1) ? 'Please wait...' : workExpBtntxt}</button></>
                                             :
                                             <><button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit"  >{(fileLoadingContent == 1) ? 'Please wait...' : workExpBtntxt}</button></>

                                       }
                                    </div>
                                    <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={setStepSeven} >Skip this step</button></div>
                                 </>
                           }


                        </div>

                     </Form>

                  </div>
               </div>
            </div>
         </div>
      );
   }

   const Step6 = () => {
      const [workExperienceArray, setWorkExperienceArray] = useState([]);
      const [workExperienceSize, setWorkExperiencesize] = useState([]);
      const [dataIsLoad, setDataIsLoad] = useState(false);
      const [onLoadDiv, setonLoadDiv] = useState(true);

      const loadWorkExperiences = (API) => {
         (async () => {
            try {
               const resData = await axios.get(API);
               if (resData.data.status == 1) {
                  setWorkExperienceArray(resData.data.data.results);
                  setWorkExperiencesize(resData.data.data.getsize);
               }
            } catch (error) { console.log(error); }
         })();
      }

      useEffect(() => {
         if (onLoadDiv) {
            console.log('caling...')
            loadWorkExperiences(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userdetails.unique_id}`);
         }
      }, []);

      const getMoreExperenc = (type) => {
         setonLoadDiv(false)
         if (type == 'load') {
            setDataIsLoad(true);
            loadWorkExperiences(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userdetails.unique_id}?limit=10`);
         } else {
            setDataIsLoad(false);
            loadWorkExperiences(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${userdetails.unique_id}`);
         }
      }

      const removeWorkExperience = (editid) => {
         const result = window.confirm('Are you sure ?');
         if (result) {
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/delete_work_experience/${userdetails.unique_id}/${editid}`);
                  if (resData.data.status == 1) {
                     toast.success('Removed Successfully!');
                     setWorkExperienceArray(resData.data.data.results)
                  }
               } catch (error) { console.log(error); }
            })();
         }
      }

      // console.log(workExperienceArray);
      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span> Work Experience
                        </h3>

                        {
                           workExperienceArray.length ?
                              <>
                                 {
                                    <>
                                       {
                                          workExperienceArray.map((item, index) => (
                                             <>
                                                <div className="mt-3 graybox workexperiencebox">
                                                   <div className='row'>
                                                      <div className="workexp_btns d-flex align-items-center justify-content-between">
                                                         <span className='cursor_pointer' onClick={(e) => editWorkExperience(item.id)}  ><img src="/front/images/workdelete.svg"></img></span>
                                                         <span className='cursor_pointer' onClick={(e) => removeWorkExperience(item.id)} ><img src="/front/images/workedit.svg"></img></span>
                                                      </div>

                                                      <div className="col-md-12 pt-2">
                                                         <div className="form-group mb-2">
                                                            <label className="form-label">Job title <span className="starr">*</span> </label>
                                                            {
                                                               item.is_currently_working == 1 ?
                                                                  <>
                                                                     <div className="commonchecbox d-flex float-right">
                                                                        <label className="checklabel">Currently working here</label>
                                                                     </div>
                                                                  </>
                                                                  : ''
                                                            }
                                                            <input type="readonly" disabled={true} className="form-control" name='work_expe_jobtitle' value={item.work_expe_jobtitle} placeholder="Enter job title" />
                                                         </div>

                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group mb-2">
                                                            <label className="form-label">Company name <span className="starr">*</span></label>
                                                            <input type="readonly" disabled={true} className="form-control" name='work_expe_companyname' value={item.work_expe_companyname} placeholder="Enter company name" />
                                                         </div>
                                                      </div>
                                                      <div className="col-md-6">
                                                         <div className="form-group mb-2">
                                                            <label className="form-label">Country<span className="starr">*</span></label>

                                                            <input type="readonly" disabled={true} className="form-control" name='work_expe_countryname' value={item.work_expe_country_name} placeholder="Enter your country" />

                                                         </div>
                                                      </div>

                                                      {
                                                         (item.work_expe_country_name == 'Australia') &&
                                                         <>
                                                            <div className="col-md-12">
                                                               <div className="form-group mb-2">
                                                                  <label className="form-label">Suburb<span className="starr">*</span></label>

                                                                  <input type="readonly" disabled={true} className="form-control" name='work_expe_countryname' value={item.work_expe_subrub} />

                                                               </div>
                                                            </div>
                                                         </>
                                                      }

                                                      <div className="col-md-12">
                                                         <div className="companyduration">
                                                            <div className="d-flex  justify-content-between">
                                                               <label className="form-label">Duration <span className="starr">*</span></label>
                                                            </div>
                                                            <div className="form-group d-flex align-items-center">
                                                               <div className="d-flex w-100">
                                                                  <div className="mr-2 yearexp">
                                                                     <select disabled={true} className="form-control" name='work_expe_duration_from_year'>
                                                                        <option value={''}>{item.work_expe_duration_from_year}</option>
                                                                     </select>
                                                                  </div>
                                                                  <div className="w-100  monthexp">
                                                                     <select disabled={true} className="form-control" name='work_expe_duration_from_month'>
                                                                        <option value={''}>{item.work_expe_duration_from_month}</option>
                                                                     </select>
                                                                  </div>
                                                               </div>
                                                               <div><label className="form-label label px-3">To</label></div>
                                                               <div className="d-flex w-100">
                                                                  <div className="mr-2 yearexp">
                                                                     <select disabled={true} className="form-control" name='work_expe_duration_to_year'>
                                                                        <option value={''}>{item.work_expe_duration_to_year}</option>
                                                                     </select>
                                                                  </div>
                                                                  <div className="w-100 monthexp">
                                                                     <select disabled={true} className="form-control" name='work_expe_duration_to_month'>
                                                                        <option value={''}>{item.work_expe_duration_to_month}</option>
                                                                     </select>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </>
                                          ))
                                       }
                                       {
                                          (workExperienceSize > 2) ?
                                             <>
                                                <p className='load_more_exp_link'>
                                                   <span onClick={() => getMoreExperenc(dataIsLoad ? 'hide' : 'load')} className={`text_safron`} >{dataIsLoad ? 'Hide Experiences' : 'Load more ...'}</span>
                                                </p>
                                             </>
                                             :
                                             <></>
                                       }
                                    </>

                                 }
                              </>
                              :
                              <>
                                 <div className="mt-3 graybox workexperiencebox">
                                    <div className='row'>
                                       <div className="workexp_btns d-flex align-items-center justify-content-between">
                                          <span className='cursor_pointer' onClick={addMoreWorkExperinece} ><img src="/front/images/workdelete.svg"></img></span>
                                          <span className='cursor_pointer' onClick={addMoreWorkExperinece} ><img src="/front/images/workedit.svg"></img></span>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="form-group mb-2">
                                             <label className="form-label d-flex">Job title <span className="starr">*</span> <span className="ml-auto curruntlylbl">Currently working here</span></label>
                                             <input type="text" className="form-control" placeholder="Enter job title" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group mb-2">
                                             <label className="form-label">Company name <span className="starr">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter company name" />
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group mb-2">
                                             <label className="form-label">Country<span className="starr">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter you country" />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="companyduration">
                                             <div className="d-flex  justify-content-between">
                                                <label className="form-label">Duration <span className="starr">*</span></label>
                                             </div>
                                             <div className="form-group d-flex align-items-center">
                                                <div className="d-flex w-100">
                                                   <div className="mr-2 w-100">
                                                      <select className="form-control">
                                                         <option>Year</option>
                                                      </select>
                                                   </div>
                                                   <div className="w-100">
                                                      <select className="form-control">
                                                         <option>Month</option>
                                                      </select>
                                                   </div>
                                                </div>
                                                <div><label className="form-label label px-3">To</label></div>
                                                <div className="d-flex w-100">
                                                   <div className="mr-2 w-100">
                                                      <select className="form-control">
                                                         <option>Year</option>
                                                      </select>
                                                   </div>
                                                   <div className="w-100">
                                                      <select className="form-control">
                                                         <option>Month</option>
                                                      </select>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                        }
                        <div className="addworkexp mb-4">
                           <span onClick={addMoreWorkExperinece} className="cursor_pointer linktxt d-flex align-items-center">
                              <img className="mr-2" src="/front/images/addwork.svg" />
                              Add more experiences
                           </span>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      )
   };

   const Step7 = () => {
      const [weekNameArray, setWeekNameArray] = useState(AVAILABILITY_JSON);


      const [jobwantToApplyArray, setJobwantToApply] = useState([]);
      const [isValidateLocation, setIsValidateLocation] = useState(false);
      const [isAddNewJobModalShow, setIsAddNewJobModalShow] = useState(false);
      const [isAddNewJobtitleError, setIsAddNewJobtitleError] = useState(false);
      const [isAddNewJobtitleErrorMessage, setIsAddNewJobtitleErrorMessage] = useState('');
      const [defaultJobTitle, setDefaultJobTitle] = useState('');

      //const [getChckAvailibility, setGetChckAvailibility] = useState([]);


      const [expectedSalaryHourlyMinimum, setExpectedSalaryHourlyMinimum] = useState('');
      const [expectedSalaryHourlyMaximum, setExpectedSalaryHourlyMaximum] = useState('');


      const expectedSalaryHourlyMinimumFun = (event) => { let value = event.target.value; if (value.length > 5) { return false } else { setExpectedSalaryHourlyMinimum(value); } }
      const expectedSalaryHourlyMaximumFun = (event) => { let value = event.target.value; if (value.length > 5) { return false } else { setExpectedSalaryHourlyMaximum(value); } }

      // const expectedSalaryHourlyMinimumFun = (event) => { let value = event.target.value; if (value.length <= 3 && value > 0) { setExpectedSalaryHourlyMinimum(value) } }
      // const expectedSalaryHourlyMaximumFun = (event) => { let value = event.target.value; if (value.length <= 3 && value > 0) { setExpectedSalaryHourlyMaximum(value) } }

      const handleBlur = () => {
         if (expectedSalaryHourlyMinimum !== '' && expectedSalaryHourlyMaximum !== '' && parseInt(expectedSalaryHourlyMinimum, 10) > parseInt(expectedSalaryHourlyMaximum, 10)) {
            const temp = expectedSalaryHourlyMinimum;
            setExpectedSalaryHourlyMinimum(expectedSalaryHourlyMaximum);
            setExpectedSalaryHourlyMaximum(temp);
         }
      };
      /*--------preferd location--------*/
      const [locationTags, setLocationTags] = useState([]);
      const [inputValueTags, setInputValueTags] = useState('');
      const handleInputChange = (event) => { setInputValueTags(event.target.value); };
      const handleInputKeyDown = (event) => { if (event.key === 'Enter' && inputValueTags !== '') { event.preventDefault(); setLocationTags([...locationTags, inputValueTags]); setInputValueTags(''); } };
      // const handleInputKeyDown = (value) => { setInputValue(value);   if (value.length > 2) { fetchSuggestions(value); } else { setSubRubSggestions([]) } };
      const handleTagRemove = (tagToRemove) => { const updatedTags = locationTags.filter((tag) => tag !== tagToRemove); setLocationTags(updatedTags); };


      const [jobTitleList, setJobtitleList] = useState([]);
      const [jobTypeList, setJobTypeList] = useState([]);
      useEffect(() => {
         const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
         if (sessionStorageData) {
            setJobTypeList(sessionStorageData.get_all_jobtype);
            setJobtitleList(sessionStorageData.get_jobtitle_options);
         } else {
            (async () => {
               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_jobtype");
                  if (resData.data.status == 1) {
                     setJobTypeList(resData.data.data.results);
                  }
               } catch (error) { console.log(error); }

               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobtitle_options");
                  if (resData.data.status == 1) {
                     setJobtitleList(resData.data.data.results);
                  }
               } catch (error) { console.log(error); }
            })();
         }

         if (editJobwantToApplyID) {
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply_byid/${userdetails.unique_id}/${editJobwantToApplyID}`);
                  if (resData.data.status == 1) {
                     setJobwantToApply(resData.data.data.results)
                     setExpectedSalaryHourlyMinimum(resData.data.data.results.expect_salary_from)
                     setExpectedSalaryHourlyMaximum(resData.data.data.results.expect_salary_to);
                     setLocationTags(resData.data.data.results.prefered_location_array);
                     setDefaultJobTitle(resData.data.data.results.job_title);
                     setWeekNameArray(resData.data.data.results.availability_data);
                     // console.log(resData.data.data.results.availability)
                     //setLocationTags
                  }
               } catch (error) { console.log(error); }
            })();
         }
      }, []);


      /*-----Availibility  copy function ------*/
      const inputRefs = useRef([]);
      const inputRefs2 = useRef([]);
      const [activeIndex, setActiveIndex] = useState(null);
      const checkValueofDay = (key, type, value, index_key) => {
         const newArray = [...weekNameArray];

         console.log(key, type, value, index_key)
         const index = newArray.findIndex(item => item.key === key);
         if (index !== -1) {
            if (type === 'from') {
               newArray[index].from_value = value;
               inputRefs2.current[index].focus();
            }
            //inputRefs2
            else if (type === 'to') { newArray[index].to_value = value; }
            else {
               newArray[index].checked = value;

               newArray[index].from_value = "";
               newArray[index].to_value = "";
               if (value == true && !newArray[index].from_value && !newArray[index].to_value) {
                  newArray[index].from_value = "00:00";
                  newArray[index].to_value = "23:59";
                  inputRefs.current[index].focus();
                  setActiveIndex(index);
               }

               // if (value == false) {
               //    newArray[index].from_value = '';
               //    newArray[index].to_value = '';
               // }

               // newArray[index].checked = false;
               // if (newArray[index].from_value && newArray[index].to_value) {
               //    newArray[index].checked = true;
               // }
            }

            // if (newArray[index].from_value && newArray[index].to_value) {
            //    newArray[index].checked = true;
            // }
            setWeekNameArray(newArray);
         }
      }

      const handleFocusAvailibility = (index) => {
         setActiveIndex(index); // Set active input when focused
      };

      const handleBlurAvailibility = () => {
         setActiveIndex(null); // Remove active input when blurred
      };




      const copyFromAndToTime = (index, key) => {
         console.log(key)
         const newArray = [...weekNameArray];
         const index_key = newArray.findIndex(item => item.key === key);
         if (index_key !== -1) {
            const newArray = weekNameArray.map(obj => ({
               ...obj,
               ['from_value']: weekNameArray[index_key].from_value,
               ['to_value']: weekNameArray[index_key].to_value,
               ['checked']: true
            }));
            setWeekNameArray(newArray);
         }
      }

      /*----------------submit function--------------------*/
      const [validated, setValidated] = useState(false);
      const [classJobTitleError, setClassJobTitleError] = useState('');

      const onAddressDetailsSubmit = async (event) => {
         event.preventDefault();
         if (event.key != 'Enter') {

            //console.log(locationTags);
            //console.log(locationTags.length);
            (locationTags.length == 0) ? setIsValidateLocation(true) : setIsValidateLocation(false);
            // console.log(isValidateLocation);
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            const form = event.currentTarget;
            let isChildFieldValid = true;

            setClassJobTitleError('')
            if (!defaultJobTitle) {
               setClassJobTitleError('error_border')
            }


            if (form.checkValidity() === false || !isChildFieldValid) {
               event.preventDefault(); event.stopPropagation();
            } else {
               try {
                  const formData = new FormData(event.target);
                  const job_want_to_apply = {};
                  formData.forEach((value, name) => {
                     if (name.endsWith('[]')) {
                        const inputName = name.slice(0, -2);
                        if (!job_want_to_apply[inputName]) { job_want_to_apply[inputName] = [value]; }
                        else { job_want_to_apply[inputName].push(value); }
                     } else { job_want_to_apply[name] = value; }
                  });
                  formData.append('userid', userid);
                  if (editJobwantToApplyID) {
                     formData.append('editid', editJobwantToApplyID);
                  }

                  formData.append('prefered_location', locationTags);
                  const response = await axios.post(
                     `https://squadgoo.com.au/api.squadgoo.com.au/user_job_wanttoapply`,
                     formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                  if (response.data.status == 1) { handleNext() }
                  else { toast.warning(response.data.message); }
               } catch (error) { console.error('Error uploading file:', error); }
            }
            setValidated(true);
         }
      };


      /*****************************************/


      //bordercss
      const [setNewJobTitleValue, setSetNewJobTitleValue] = useState('');
      const getJobTitleValue = (e) => { setIsAddNewJobModalShow(false); setSetNewJobTitleValue(''); if (e.target.value === 'other') { setIsAddNewJobModalShow(true) } }
      const getNewJobtitle = (e) => {
         setSetNewJobTitleValue(e.target.value);
      }

      const addJobTitleInList = (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         if (setNewJobTitleValue && setNewJobTitleValue.length > 3) {
            setIsAddNewJobtitleError(false);
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_jobtitle/${userid}/${setNewJobTitleValue}`);
                  if (resData.data.status == 1) {
                     //setJobtitleList(resData.data.data.results);
                     setJobtitleList(resData.data.data.jobtitle_option);
                     setIsAddNewJobModalShow(false);
                     setDefaultJobTitle(resData.data.data.last_id)
                  } else {
                     setIsAddNewJobtitleErrorMessage(resData.data.message)
                  }
               } catch (error) { console.log(error); }
            })();
         } else {
            setIsAddNewJobtitleError(true);
            setIsAddNewJobtitleErrorMessage('Please enter title');
         }
      }
      const joBTitleSelected = (select) => {

         setIsAddNewJobModalShow(false);
         setDefaultJobTitle(select.value);
         setClassJobTitleError('')
         if (select.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
      }

      // const customStyles = { option: (provided, state) => ({ ...provided, padding: '3px 10px', }), };
      const customStyles = {
         option: (provided, state) => ({
            ...provided,
            padding: '3px 10px',
            fontSize: '13px'
         }),
         groupHeading: (provided) => ({
            ...provided,
            fontSize: '13px', // Change the font size for the category labels
            fontWeight: 'bold', // You can also make the category label bold if desired
            color: '#000'
         })
      };

      const [classForTitleError, setClassForTitleError] = useState('');
      const [classForCategoryError, setClassForCategoryError] = useState('');
      const handleJobTitleChange = (selectedJobTitle) => {
         setDefaultJobTitle(selectedJobTitle.value);

         if (selectedJobTitle.value === 'other') { setSetNewJobTitleValue(''); setIsAddNewJobModalShow(true) }
         console.log("Selected Job Title:", selectedJobTitle);
      };

      //getNewJobtitle
      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <Form noValidate validated={validated} onSubmit={onAddressDetailsSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span className="stepbackarrow mr-1" ><img src="/front/images/stepbackarrow.svg" onClick={isIamaFresher ? setStepFive : handlePrevious}></img> </span>
                           Jobs I am available for
                           <span className="infowrap"><i className="fa fa-info"></i><span className='onhoverinfo'>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span>
                        </h3>
                        <div className="mt-3">
                           <div className="row">


                              {/* <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Job title <span className="starr">*</span></label>


                                    <Select
                                       className={`react_select ${classJobTitleError}`}
                                       name="job_title"
                                       required aria-label="4"
                                       options={jobTitleList}
                                       isSearchable
                                       placeholder="Search..."
                                       styles={customStyles}
                                       onChange={joBTitleSelected}
                                       value={jobTitleList.find(item => item.value == defaultJobTitle)}
                                       isGrouped={true}
                                       zindex={999}
                                    />

                                 </div>
                              </div> */}

                              <JobTitleCAtegoryComp
                                 defaultJobID={defaultJobTitle}
                                 onJobTitleChange={handleJobTitleChange}
                                 classForCategory={classForCategoryError}
                                 classForTitle={classForTitleError}
                                 classContainerName={'col-md-6'}
                                 fieldName={'job_title'}
                              />
                              {
                                 isAddNewJobModalShow && <>
                                    <div className="col-md-12 ">
                                       <div className="form-group mb-2 graybox ">
                                          <label className="form-label">Add Job title <span className="starr">*</span> </label>
                                          <div className={isAddNewJobtitleError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                             <input type="text" required={true} className="form-control" onChange={getNewJobtitle} value={setNewJobTitleValue} name="add_job_title" placeholder="Enter job title" />
                                             <div className='input-group-append'>
                                                <button className='batn batn-orange  add_newjobtitle' onClick={addJobTitleInList} >Add Title</button>
                                             </div>

                                          </div>
                                          {
                                             isAddNewJobtitleErrorMessage && <><div className='error_message'>{isAddNewJobtitleErrorMessage}</div></>
                                          }
                                       </div>
                                    </div>
                                 </>
                              }
                              <div className="col-md-12">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Job type <span className="starr">*</span></label>
                                    <select required={true} name="job_type" className="form-control" aria-label="Default select example">
                                       <option value=''>Select</option>
                                       {
                                          jobTypeList.map((item, index) => (
                                             <option selected={jobwantToApplyArray.job_type == item.id ? true : false} value={item.id} datatitle={item.jobtype} >{item.jobtype}</option>
                                          ))
                                       }
                                    </select>
                                 </div>
                              </div>



                              

                              <div className="col-md-12 ">
                                 <div className="form-group mb-2 ">
                                    <label className="form-label">Total experience <span className="starr">*</span></label>
                                    <div className="d-flex">
                                       <div className="mr-2 w-100">
                                          <select required={true} className="form-control" name="total_experience_year" >
                                             {<YearExpNumberLoop select_attr={jobwantToApplyArray.total_experience_year} />}
                                          </select>
                                       </div>
                                       <div className="w-100">
                                          <select required={true} className="form-control" name="total_experience_months">
                                             {<MonthExpNumberLoop select_attr={jobwantToApplyArray.total_experience_months} />}
                                          </select>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12"> <label className="form-label">Expected Salary (Hourly)</label></div>

                              {/* <div className="input-group mb-2 salaryfield">
                                 <div className="input-group-prepend"><div className="input-group-text">$</div></div>
                                 <input required={true}  type="number" name="salary_minimum" maxlength="3" className="form-control" placeholder="Minimum" value=""/>

                              </div> */}

                              {/*  ${expectedSalaryHourlyMinimum ? '' : 'bordercss'} */}
                              <div className="col-md-6 salaryblock">
                                 <div className="form-group mb-2">

                                    <label className="form-label">Minimun</label>
                                    <div className={`input-group mb-2 salaryfield2`}>
                                       <div className="input-group-prepend">
                                          <div className="input-group-text">$</div>
                                       </div>
                                       <input type="number" style={{ zIndex: 0 }} onChange={expectedSalaryHourlyMinimumFun} name="expect_salary_from" onBlur={handleBlur} required={true} maxLength={5} value={expectedSalaryHourlyMinimum} className="form-control" placeholder="0" />
                                       {/* <p>/per hour</p> */}
                                    </div>
                                 </div>
                              </div>
                              {/* ${expectedSalaryHourlyMaximum ? '' : 'bordercss'} */}
                              <div className="col-md-6 salaryblock">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Maximum</label>
                                    <div className={`input-group mb-2 salaryfield2 `} >
                                       <div className="input-group-prepend">
                                          <div className="input-group-text">$</div>
                                       </div>
                                       <input type="number" style={{ zIndex: 0 }} onChange={expectedSalaryHourlyMaximumFun} name="expect_salary_to" onBlur={handleBlur} required={true} maxLength={5} className="form-control" value={expectedSalaryHourlyMaximum} placeholder="000" />
                                       {/* <p>/per hour</p> */}
                                    </div>
                                 </div>
                              </div>

                              <input
                                 type="hidden"
                                 value={inputValueTags}
                                 onChange={handleInputChange}
                                 onKeyDown={handleInputKeyDown}
                                 style={{ width: '300px' }}
                                 className="form-control" placeholder="Write your location and press ENTER"
                              />
                           </div>
                           <div className="col-md-12">
                              <div className="form-group mb-3">
                                 <label className="form-label">Availability to work <span className="starr">*</span></label>
                                 {
                                    weekNameArray.map((item, index) => (
                                       <div className="availability-sec d-flex align-items-center  mb-2" key={item.name}>
                                          <div className="checkboxwraper">
                                             <input type="checkbox" name={`availability[${item.key}][checkebox][]`} onChange={(e) => checkValueofDay(item.key, 'checkbox', e.target.checked, index)} value={item.from_value} checked={item.checked} ></input>
                                             <span className="checkspan"></span>
                                          </div>
                                          <p>{item.name}</p>
                                          <input
                                             ref={(el) => (inputRefs.current[index] = el)}
                                             type="time"
                                             name={`availability[${item.key}][from][]`}
                                             onChange={(e) => checkValueofDay(item.key, 'from', e.target.value, index)}
                                             value={item.from_value}
                                             className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`}

                                             placeholder="00:00 am" >
                                          </input>
                                          <div className="subheadpera">To</div>
                                          <input
                                             ref={(el) => (inputRefs2.current[index] = el)}
                                             type="time"
                                             name={`availability[${item.key}][to][]`}
                                             onChange={(e) => checkValueofDay(item.key, 'to', e.target.value, index)}
                                             value={item.to_value}
                                             className={`form-control ${activeIndex === index ? 'availibility_active_from' : ''}`}
                                             placeholder="00:00 am">
                                          </input>
                                          {
                                             (item.from_value && item.to_value) ?
                                                <>
                                                   <span className="copyin ml-3 cursor_pointer infowrap" onClick={(e) => copyFromAndToTime(index, item.key)}><img src="/front/images/copuicon.png" /><span className="onhoverinfo">Duplicate the same time for week availability</span></span>
                                                </>
                                                : ''
                                          }
                                       </div>
                                    ))
                                 }
                              </div>
                           </div>

                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Job search type <span className="starr">*</span></label>
                                 <select id="jobSearchType" name="job_search_type" className="form-control" >
                                    {JOB_SEARCH_TYPE.map((option) => (
                                       <option key={option.value} value={option.value}>
                                          {option.name}
                                       </option>
                                    ))}
                                 </select>
                              </div>
                           </div>

                           <div className="btnsgroup">
                              <div className="text-center">
                                 <button className="batn batn-orange w-100" type="submit">Next</button>
                              </div>
                              <div className="text-center  mt-2"><button className="btn-link" type="submit" onClick={setStepNine}>Skip this step</button></div>
                           </div>
                        </div>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
      )
   };

   const Step8 = () => {
      const [weekNameArray, setWeekNameArray] = useState(AVAILABILITY_JSON);
      const [workExpWntToApplyArray, setWorkExpWntToApplyArray] = useState([]);
      const [workExpWntToApplyArrayLoading, setworkExpWntToApplyArrayLoading] = useState(true);
      //const [workExperienceArray, setWorkExperienceArray] = useState([]);
      const [jobTypeList, setJobTypeList] = useState([]);
      const [jobApplySize, setjobApplySize] = useState(0);

      const getDataofJobWantToapply = (API) => {
         (async () => {
            try {
               const resData = await axios.get(API);
               if (resData.data.status == 1) {
                  setWorkExpWntToApplyArray(resData.data.data.results);
                  setjobApplySize(resData.data.data.getsize);
                  setworkExpWntToApplyArrayLoading(false);
               }
            } catch (error) { console.log(error); }
         })();
      }

      useEffect(() => {

         getDataofJobWantToapply(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userdetails.unique_id}`);
         const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
         if (sessionStorageData) {
            setJobTypeList(sessionStorageData.get_all_jobtype);
         } else {

            (async () => {
               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_jobtype");
                  if (resData.data.status == 1) {
                     setJobTypeList(resData.data.data.results)
                  }
               } catch (error) { console.log(error); }
            })();
         }
      }, []);

      const [dataIsLoad, setDataIsLoad] = useState(false);

      const getMoreJowanttoapply = (type) => {
         if (type == 'load') {
            setDataIsLoad(true);
            getDataofJobWantToapply(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userdetails.unique_id}?limit=10`);
         } else {
            setDataIsLoad(false);
            getDataofJobWantToapply(`https://squadgoo.com.au/api.squadgoo.com.au/get_job_wanttoapply/${userdetails.unique_id}`);
         }
      }

      //console.log(jobTypeList);
      const [isCheckedAccept, setIsCheckedAccept] = useState(false);
      const handleCheckboxAcceptFun = (event) => {
         setIsCheckedAccept(event.target.checked);
         if (event.target.checked) { formdataValue('accept_agreeiment', 1); }
         else { formdataValue('accept_agreeiment', 0); }
      }

      const [isHidden, setIsHidden] = useState(true);
      //const [viewMoreBtnText, setViewMoreBtnText] = useState('View More');
      const hideAndSeekAvailibility = () => { setIsHidden(!isHidden); };
      const addMoreJobProfileOption = () => { setStep(7); };

      //console.log(workExpWntToApplyArray);
      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">

                     <form className="commonform">
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span>Jobs I am available for
                           <span className="infowrap"><i className="fa fa-info"></i><span className='onhoverinfo'>Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span>
                        </h3>

                        {
                           workExpWntToApplyArray.length ?
                              <>
                                 {
                                    workExpWntToApplyArray.map((item, index) => (
                                       <>
                                          <div className="mt-3 graybox workexperiencebox">
                                             <div className="row">
                                                <div className="workexp_btns d-flex align-items-center justify-content-between">
                                                   <span className="cursor_pointer" onClick={(e) => editJobwantToApply(item.id)}  ><img src="/front/images/workdelete.svg" /></span>
                                                   <span className="cursor_pointer" ><img src="/front/images/workedit.svg" /></span>
                                                </div>
                                                <div className="col-md-6">
                                                   <div className="form-group mb-2">
                                                      <label className="form-label">I work as <span className="starr">*</span></label>
                                                      <select disabled={true} className="form-control" name="work_expe_jobrole">
                                                         <option>{item.job_id_title}</option>
                                                      </select>
                                                   </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                   <div className="form-group mb-2 ">
                                                      <label className="form-label">Total experience <span className="starr">*</span></label>
                                                      <div className="d-flex">

                                                         <div className="mr-2 w-100">
                                                            <select disabled={true} className="form-control" name="work_expe_total_year" >
                                                               <option>{item.total_experience_year + ' ' + (item.total_experience_year > 1 ? 'Years' : 'Year')}</option>
                                                            </select>
                                                         </div>
                                                         <div className="w-100">
                                                            <select disabled={true} className="form-control" name="work_expe_total_month">
                                                               <option>{item.total_experience_months + ' ' + (item.total_experience_months > 1 ? 'Months' : 'Month')}</option>
                                                               {/* <option>{item.total_experience_months ? item.total_experience_months : 'Months'}</option> */}
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>


                                                <div className="col-md-12"> <label className="form-label">Salary</label></div>
                                                <div className="col-md-6">
                                                   <div className="form-group mb-2">

                                                      <label className="form-label">From</label>
                                                      <div className="input-group mb-2 salaryfield">
                                                         <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                         </div>
                                                         <input disabled={true} type="number" value={item.expect_salary_from} className="form-control" placeholder="0" />
                                                         <p>/per hour</p>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-md-6">
                                                   <div className="form-group mb-2">
                                                      <label className="form-label">To</label>
                                                      <div className="input-group mb-2 salaryfield">
                                                         <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                         </div>
                                                         <input disabled={true} type="number" value={item.expect_salary_to} className="form-control" placeholder="000" />
                                                         <p>/per hour</p>
                                                      </div>
                                                   </div>
                                                </div>
                                                {
                                                   !isHidden ?
                                                      <>
                                                         <label className="form-label">Availability to work </label>
                                                         {
                                                            weekNameArray.map((item2, index) => (
                                                               <div className="availability-sec d-flex align-items-center  mb-2" key={item2.name}>

                                                                  <div className="checkboxwraper">
                                                                     <input type="checkbox" name={`availability[${item2.key}][checkebox][]`} checked={item.availability_by_key[item2.key].check_available == 1 ? true : false} ></input>
                                                                     <span className="checkspan"></span>
                                                                  </div>
                                                                  <p>{item2.name}</p>
                                                                  <input type="time" className="form-control" value={item.availability_by_key[item2.key].availability_from_time} ></input>
                                                                  <div className="subheadpera">To</div>
                                                                  <input type="time" className="form-control" value={item.availability_by_key[item2.key].availability_to_time} ></input>

                                                               </div>
                                                            ))
                                                         }
                                                      </>
                                                      : ''
                                                }
                                                {/* <div className="col-md-12"><span onClick={hideAndSeekAvailibility} className="cursor_pointer linktxt d-flex align-items-center">{ isHidden ? 'View More ' : 'Hide Details'}</span></div> */}
                                                <div className="col-md-12"><span onClick={(e) => editJobwantToApply(item.id)} className="cursor_pointer linktxt d-flex align-items-center">{isHidden ? 'View More ' : 'Hide Details'}</span></div>
                                             </div>
                                          </div>
                                       </>
                                    ))
                                 }

                              </>
                              : <> {workExpWntToApplyArrayLoading ? 'Loading.....' : 'There is no data found.'}</>
                        }

                        {
                           (jobApplySize > 2) ?
                              <>
                                 <p className='load_more_exp_link'>
                                    <span onClick={() => getMoreJowanttoapply(dataIsLoad ? 'hide' : 'load')} className={`text_safron`} >{dataIsLoad ? 'Hide Experiences' : 'Load more ...'}</span>
                                 </p>
                              </>
                              :
                              <></>
                        }



                        <div className="addworkexp mb-4">
                           <span onClick={addMoreJobProfileOption} className="cursor_pointer linktxt d-flex align-items-center">
                              <img className="mr-2" src="/front/images/addwork.svg" />Add more job profiles
                           </span>
                        </div>

                     </form>

                  </div>
               </div>
            </div>
         </div>
      )
   };

   const Step9 = () => {

      const [allQualificationList, setAllQualificationList] = useState([]);
      const [allEducationsListOptions, setAllEducationsListOptions] = useState([]);
      //const [allLanguageList, setAllLanguageList] = useState([]);
      const [allLanguageListOption, setAllLanguageListOption] = useState([]);
      const [selectedOptionsLanguage, setSelectedOptionsLanguage] = React.useState([{ value: '1', label: 'English' }]);
      const [selectedOptionsEductions, setSelectedOptionsEductions] = React.useState([]);
      const [selectedOptionsQulification, setSelectedOptionsQulification] = React.useState([]);
      const [fileLoadingContent, setLoadingContent] = useState(0);
      const [userUploadVisaDocumentContents, setUserUploadVisaDocumentContents] = useState(userUploadVisaDocument);
      const [qualificationProofDocuments, setqualificationProofDocuments] = useState([]);
      const [educationProofDocuments, setEducationProofDocuments] = useState([]);
      const [isAddedEducation, setisAddedEducation] = useState(false);
      const [isAddedQulification, setisAddedQulification] = useState(false);
      const [isAddedLanguage, setisAddedLanguage] = useState(false);
      const [iAmAustralian, setIAmAustralian] = useState(0);


      const getUserDetailsFun = () => {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + signupId + '?callingby=accountsetup');
               if (resData.data.status == 1) {
                  const getServeData = resData.data.data.results;
                  setIAmAustralian(getServeData.i_am_australian);
                  setUserUploadVisaDocumentContents(getServeData.user_document_visatype);
                  //console.log(resData.data.data.results);
                  setEducationProofDocuments(getServeData.user_education_documents);
                  setqualificationProofDocuments(getServeData.user_qualification_documents);

                  setSelectedOptionsEductions(getServeData.all_educations_proof ? JSON.parse(getServeData.all_educations_proof) : []);
                  setSelectedOptionsQulification(getServeData.all_qualifications ? JSON.parse(getServeData.all_qualifications) : []);
               }
            } catch (error) { console.log(error); }
         })();
      }

      useEffect(() => {
         (async () => {
            const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
            if (sessionStorageData) {
               setAllLanguageListOption(sessionStorageData.get_all_language.option);
               setAllEducationsListOptions(sessionStorageData.get_all_eduqualification.option);
               setAllQualificationList(sessionStorageData.get_all_qualifications_options);
               //console.log(sessionStorageData)
               // setAllEducationsListOptions(sessionStorageData.get_all_eduqualification.option);
            } else {

               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_eduqualification");
                  if (resData.data.status == 1) {
                     setAllEducationsListOptions(resData.data.data.option);
                  }
               } catch (error) { console.log(error); }
               try {
                  const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_language");
                  if (resData.data.status == 1) { setAllLanguageListOption(resData.data.data.option); }
               } catch (error) { console.log(error); }
            }

         })();
         getUserDetailsFun();
      }, []);

      const handleSelectChangeLanguage = (selected) => { setSelectedOptionsLanguage(selected); };
      const handleSelectChangeEduction = (selected) => { setSelectedOptionsEductions(selected); setisAddedEducation(''); };
      const handleSelectChangeQulification = (selected) => {
         setSelectedOptionsQulification(selected);
         console.log(selected)
         selected.map(item => {
            setIsAddNewQulifiModalShow(false);
            if (item.value == 'others') {
               setIsAddNewQulifiModalShow(true);
            }
         })
      };

      //selectedOptionsQulification
      const disablePastDate = () => {
         const today = new Date();
         const dd = String(today.getDate() + 1).padStart(2, "0");
         const mm = String(today.getMonth() + 1).padStart(2, "0");
         const yyyy = today.getFullYear();
         return yyyy + "-" + mm + "-" + dd;
      };


      const [visaDetailsStep, setVisaDetailsStep] = useState({
         type_of_visa: userdetails.type_of_visa,
         visa_grant_number: userdetails.visa_grant_number,
         visa_expire_on: userdetails.visa_expire_on,
         visa_expire_day: userdetails.visa_expire_day,
         visa_expire_month: userdetails.visa_expire_month,
         visa_expire_year: userdetails.visa_expire_year,
         visa_subclass: userdetails.visa_subclass,
         highest_qualification: userdetails.highest_qualification,
      });

      //setInputValue(userdetails.address_suburbs);
      const visaDetailsStepUpdateFun = (event) => {
         const { name, value } = event.target;
         setVisaDetailsStep((prevFormData) => ({ ...prevFormData, [name]: value }));
      };

      const [isChecked, setIsChecked] = useState(userdetails.terms_and_condition);

      const [validated, setValidated] = useState(false);

      const handleCheckboxChange = () => { setIsChecked(!isChecked); };

      const onFinalSubmit = async (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         const form = event.currentTarget;
         let isChildFieldValid = true;

         setisAddedQulification(false);
         setisAddedLanguage(false);
         setisAddedEducation('');

         if (!selectedOptionsEductions?.value) {
            isChildFieldValid = false;
            setisAddedEducation('error_border');
         }
         if (selectedOptionsEductions?.value && !selectedOptionsEductions?.value) {
            isChildFieldValid = false;
            setisAddedEducation('error_border');
         }

         //console.log();
         //return false 
         if (form.checkValidity() === false || !isChildFieldValid) {
            event.preventDefault();
            event.stopPropagation();
         } else {
            try {
               const formData = new FormData(event.target);
               const job_want_to_apply = {};
               formData.forEach((value, name) => {
                  if (name.endsWith('[]')) {
                     const inputName = name.slice(0, -2);
                     if (!job_want_to_apply[inputName]) { job_want_to_apply[inputName] = [value]; }
                     else { job_want_to_apply[inputName].push(value); }
                  } else { job_want_to_apply[name] = value; }
               });
               formData.append('userid', userid);
               formData.append('language', JSON.stringify(selectedOptionsLanguage));
               formData.append('all_educations_proof', JSON.stringify(selectedOptionsEductions));
               formData.append('all_qualifications', JSON.stringify(selectedOptionsQulification));
               formdataValue('terms_and_condition', isChecked);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) { handleNext() }
               else { console.log(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
         setValidated(true);
      };


      const userFileUploadToServerVisaFun = async (field, file, reference_id = 0) => {
         const maxSizeInBytes = 10 * 1024 * 1024; // For example, 1 MB
         if (file.size > maxSizeInBytes) {
            //console.log("File size is greater than the specified limit.");
            toast.warning('File size is greater than the specified limit.');
         } else {
            // console.log("File size is within the specified limit.");
            const queryParameters = new URLSearchParams(window.location.search)
            const userid = queryParameters.get("id");
            try {
               setLoadingContent(1);
               const formData = new FormData();
               formData.append('file', file);
               formData.append('uploaded_for', field);
               formData.append('userid', userid);
               formData.append('reference_id', reference_id);
               formData.append('maxSizeInBytes', maxSizeInBytes);
               const response = await axios.post(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               setLoadingContent(0);
               if (response.data.status == 1) {
                  if (field == 'user_document_visatype') {
                     setUserUploadVisaDocumentContents(response.data.data.upload_array)
                  } else if (field == 'eductions_marksheet') {
                     setEducationProofDocuments(response.data.data.upload_array)
                  } else if (field == 'qualification_proof') {
                     setqualificationProofDocuments(response.data.data.upload_array)
                  }
                  //qualification_proof
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      };

      const customStyles = { option: (provided, state) => ({ ...provided, padding: '3px 10px', }), };


      // const [getHighQulification, setHighQualification] = useState(visaDetailsStep.highest_qualification);
      const removeFileFromServerstep9 = async (field, id) => {
         if (window.confirm("Do you want to remove this file?")) {
            try {
               const formData = new FormData();
               formData.append('field', field);
               formData.append('file_id', id);
               const response = await axios.post(
                  `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
                  formData, { headers: { 'Content-Type': 'multipart/form-data' } });
               if (response.data.status == 1) {
                  toast.success('Removed Successfully!');
                  //if (field == 'eductions_marksheet') { setqualificationProofDocuments(response.data.data) }
                  //if (field == 'user_document_visatype') { setUserUploadVisaDocument(response.data.data) }

                  if (field == 'user_document_visatype') {
                     setUserUploadVisaDocumentContents(response.data.data)
                  } else if (field == 'eductions_marksheet') {
                     setEducationProofDocuments(response.data.data)
                  } else if (field == 'qualification_proof') {
                     setqualificationProofDocuments(response.data.data)
                  }
               } else { toast.warning(response.data.message); }
            } catch (error) { console.error('Error uploading file:', error); }
         }
      }


      /*----------------------------add qualification--------------------------------*/
      const [isAddNewQulificError, setisAddNewQulificError] = useState(false);
      const [isAddNewQulifiModalShow, setIsAddNewQulifiModalShow] = useState(false);
      const [qualifiOther, setQulificationOther] = useState('');
      const [isAddNewQulifiTitleErrorMessage, setisAddNewQulifiTitleErrorMessage] = useState('');
      const [isAddNewQulificaTitleSuccessMessage, setisAddNewQulificaTitleSuccessMessage] = useState('');

      const qualficationTitleChange = (e) => {
         setQulificationOther(e.target.value);
      }

      const qualficationTitleChangeBtnClk = (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         setisAddNewQulificaTitleSuccessMessage('');
         if (qualifiOther && qualifiOther.length > 3) {
            setisAddNewQulificError(false);
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_qualification_title/${userid}/${qualifiOther}`);
                  if (resData.data.status == 1) {
                     setIsAddNewQulifiModalShow(false);
                     setisAddNewQulificaTitleSuccessMessage(resData.data.message);
                     //const newItem = { value: '1', label: 'new item' };
                     //selectedOptionsQulification, setSelectedOptionsQulification
                     removeItemByValue('others')
                     setSelectedOptionsQulification([...selectedOptionsQulification, { value: resData.data.data.last_id, label: qualifiOther }])
                  } else {
                     setisAddNewQulifiTitleErrorMessage(resData.data.message)
                  }
               } catch (error) { console.log(error); }
            })();
         } else {
            setisAddNewQulificError(true);
            setisAddNewQulifiTitleErrorMessage('Please enter title');
         }
      }

      const removeItemByValue = (valueToRemove) => {
         const updatedTtt = selectedOptionsQulification.filter(item => item.value !== valueToRemove);
         setSelectedOptionsQulification(updatedTtt);
      };


      /*--------------------------------education-----------------------------------*/
      const [isAddNewEducationcError, setisAddNewEducationcError] = useState(false);
      const [isAddNewEducationModalShow, setIsAddNewEducationModalShow] = useState(false);
      const [educationOther, setEducationtionOther] = useState('');
      const [isAddNewEducationTitleErrorMessage, setisAddNewEducationTitleErrorMessage] = useState('');
      const [isAddNewEducationTitleSuccessMessage, setisAddNewEducationTitleSuccessMessage] = useState('');

      const educationTitleChange = (e) => {
         setEducationtionOther(e.target.value);
      }

      const educationTitleChangeBtnClk = (event) => {
         event.preventDefault();
         const queryParameters = new URLSearchParams(window.location.search)
         const userid = queryParameters.get("id");
         setisAddNewEducationTitleSuccessMessage('');
         if (educationOther && educationOther.length > 3) {
            setisAddNewEducationcError(false);
            (async () => {
               try {
                  const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/set_new_education_title/${userid}/${''}`);
                  if (resData.data.status == 1) {
                     setIsAddNewEducationModalShow(false);
                     setisAddNewEducationTitleSuccessMessage(resData.data.message);
                  } else {
                     setisAddNewEducationTitleErrorMessage(resData.data.message)
                  }
               } catch (error) { console.log(error); }
            })();
         } else {
            setisAddNewEducationcError(true);
            setisAddNewEducationTitleErrorMessage('Please enter title');
         }
      }

      return (
         <div>
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">

                     <Form noValidate validated={validated} onSubmit={onFinalSubmit} >
                        <h3 className="step-backbtnwrap d-flex align-items-center">
                           <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span>Visa details
                        </h3>
                        <div className="row mt-3">

                           <div className="col-md-12">
                              <div className="commonchecbox d-flex">
                                 <span className="agrrimentbox">
                                    <input type="checkbox" onChange={() => setIAmAustralian(iAmAustralian == 0 ? 1 : 0)} checked={iAmAustralian == 1 ? true : false} value={iAmAustralian} id="i_am_australian" name="i_am_australian" />
                                    <span className="checkafter"></span>
                                 </span>
                                 <label className="checklabel" for="i_am_australian">I am an australian citizen</label>
                              </div>
                           </div>
                           {iAmAustralian == 0 && <>
                              <div className="col-md-6">
                                 <div className="form-group mb-2">
                                    <label className="form-label">Visa type </label>
                                    <select className="form-control" name='type_of_visa' onChange={visaDetailsStepUpdateFun} >
                                       <option value="">Select</option>
                                       {
                                          <>
                                             {
                                                visaTypeList.map((item, index) => (
                                                   <option selected={visaDetailsStep.type_of_visa == item.id ? true : false} value={item.id}>{item.visa}</option>
                                                ))
                                             }
                                          </>
                                       }
                                    </select>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group mb-0 ">
                                    <label className="form-label">Visa subclass</label>
                                    <div className="">
                                       <input type="text" name="visa_subclass" class="form-control" length={50} placeholder="Visa subclass" onChange={visaDetailsStepUpdateFun} value={visaDetailsStep.visa_subclass} />
                                    </div>
                                 </div>
                              </div>


                              {(visaDetailsStep.type_of_visa == 6 || visaDetailsStep.type_of_visa == 1) && <></>
                                 // <div className="col-md-12">
                                 //    <div className="form-group mb-0 ">
                                 //       <label className="form-label">Visa name</label>
                                 //       <div className="">
                                 //          <div class="mt-2">
                                 //             <input type="text" required={true} name="visa_subclass" class="form-control" length={50} placeholder="Visa subclass" onChange={visaDetailsStepUpdateFun} value={visaDetailsStep.visa_subclass} />
                                 //          </div>
                                 //       </div>
                                 //    </div>
                                 // </div>
                              }



                              <div className="col-md-6">
                                 <div className="form-group mb-0 ">
                                    <label className="form-label">Visa expiry</label>

                                 </div>
                                 {/* --------------------- */}
                                 <div className="d-flex datefildgruop  mb-2">
                                    <select name="visa_expire_day" className="form-control mr-2 datefild" onChange={visaDetailsStepUpdateFun}>
                                       {<DayNumberLoop select_attr={visaDetailsStep.visa_expire_day} />}
                                    </select>
                                    <select name="visa_expire_month" className="form-control mr-2 monthfild" onChange={visaDetailsStepUpdateFun}>
                                       {<MonthsHtml select_attr={visaDetailsStep.visa_expire_month} />}
                                    </select>
                                    <select name="visa_expire_year" className="form-control yearfild" onChange={visaDetailsStepUpdateFun} >
                                       {<YearFutureLoop select_attr={visaDetailsStep.visa_expire_year} />}
                                    </select>
                                 </div>
                                 {/* --------------------- */}

                              </div>

                              <div className="col-md-6">
                                 <div className="form-group mb-0 ">
                                    <label className="form-label">Visa grant number</label>
                                    <div className="">
                                       <Form.Control
                                          required={false}
                                          maxLength={16}
                                          name='visa_grant_number'
                                          value={visaDetailsStep.visa_grant_number}
                                          className="form-control"
                                          placeholder={'Visa grant number'}
                                       />
                                       {/* <input type="text" maxLength={16} name='visa_grant_number' value={visaDetailsStep.visa_grant_number} className="form-control" placeholder={'Visa grant number'} /> */}
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="form-group mb-2">
                                    <label className="form-label">{userUploadVisaDocumentContents.length > 1 ? 'Uploaded visa document' : 'Upload your visa proof'}
                                    </label>
                                    {
                                       <>
                                          {
                                             userUploadVisaDocumentContents.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Visa Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('user_document_visatype', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerstep9('user_document_visatype', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>

                                    }
                                    {
                                       userUploadVisaDocumentContents.length >= 2 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className="customuploadsec">
                                                   {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" onChange={(e) => userFileUploadToServerVisaFun('user_document_visatype', e.target.files[0], true)}  ></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{fileLoadingContent ? 'Document is uploading.....' : 'click here to upload a visa file'}</p>
                                                   </div>
                                                </div>
                                             </div>
                                             <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                                          </>
                                    }
                                 </div>
                              </div>
                           </>}

                           {iAmAustralian == 1 && <>
                              <div className="col-md-12">
                                 <div className="form-group mb-2">
                                    <label className="form-label">
                                       {userUploadVisaDocumentContents.length > 1 ? 'Uploaded citizenship/passport' : 'Upload citizenship/passport'}
                                       <span class="starr">*</span>
                                    </label>
                                    {
                                       <>
                                          {
                                             userUploadVisaDocumentContents.map((item, index) => (
                                                <>
                                                   <div className="documentwrap d-flex align-items-center mb-2">
                                                      <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + item.document_url}>
                                                         <p>Document {index + 1}</p>
                                                      </a>
                                                      <div className="documentwrapinner d-flex align-items-center">
                                                         <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('user_document_visatype', item.id)}><img src="/front/images/edit.png" /></span>
                                                         <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerstep9('user_document_visatype', item.id)}><img src="/front/images/delete.png" /></span>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </>

                                    }
                                    {
                                       userUploadVisaDocumentContents.length >= 1 ?
                                          ''
                                          :
                                          <>
                                             <div className="form-group identityproof">
                                                <div className={`customuploadsec bordercss`}>
                                                   {!fileLoadingContent && <input type="file" accept="image/x-png,image/gif,image/jpeg ,application/pdf" required={true} onChange={(e) => userFileUploadToServerVisaFun('user_document_visatype', e.target.files[0], true)}  ></input>}
                                                   <div className="customuploadinner text-center">
                                                      <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                      <p className="mb-0">{fileLoadingContent ? 'Document is uploading.....' : 'click here to upload a citizenship/passport file'}</p>
                                                   </div>
                                                </div>
                                             </div>
                                             <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png and pdf files (Max file size 10 MB)</p>
                                          </>
                                    }
                                 </div>
                              </div>
                           </>}

                           <div className="col-md-12"><h3 className="mb-2">Education and qualification</h3></div>
                           <>
                              <div className="col-md-12">
                                 <div className="form-group mb-2 taxtype">
                                    <label className="form-label">Education </label>

                                    <Select
                                       className={`react_select ${isAddedEducation}`}
                                       options={allEducationsListOptions}
                                       value={selectedOptionsEductions}
                                       onChange={handleSelectChangeEduction}
                                       placeholder="Select options..."
                                       styles={customStyles}
                                    />

                                    {

                                       isAddNewEducationModalShow && <> <div className="form-group mb-2 mt-2 graybox ">
                                          <label className="form-label">Add Job title <span className="starr">*</span> </label>
                                          <div className={isAddNewEducationcError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                             <input type="text" required={true} className="form-control" onChange={educationTitleChange} value={educationOther} name="qualification_title_change" placeholder="Enter job title" />
                                             <div className='input-group-append'>
                                                <button className='batn batn-orange  add_newjobtitle' onClick={educationTitleChangeBtnClk} >Add Title</button>
                                             </div>

                                          </div>
                                          {isAddNewEducationTitleErrorMessage && <><div className='error_message'>{isAddNewEducationTitleErrorMessage}</div></>}
                                          {isAddNewEducationTitleSuccessMessage && <><div className='error_message'>{isAddNewEducationTitleSuccessMessage}</div></>}
                                       </div>
                                       </>
                                    }




                                    {
                                       (selectedOptionsEductions.value == 2 || selectedOptionsEductions.value == 3) ? <></> :
                                          <div class="col-md-12">
                                             <div class="form-group mb-2">
                                                <label class="form-label">Enter the exact education <span class="starr">*</span></label>
                                                <input type="text" required={true} class="form-control" name="enter_the_exact_education" placeholder="Enter the exact education" />
                                             </div>
                                          </div>
                                    }

                                    {
                                       (selectedOptionsEductions.length > 0) &&
                                       selectedOptionsEductions.map((item, index) => (
                                          <>
                                             {
                                                (!educationProofDocuments[index]) ?
                                                   <>
                                                      {
                                                         <div className="form-group identityproof mt-2">
                                                            <div className="customuploadsec">
                                                               {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf"
                                                                  onChange={(e) => userFileUploadToServerVisaFun(`eductions_marksheet`, e.target.files[0], item.value)}
                                                               ></input>}
                                                               <div className="customuploadinner text-center">
                                                                  <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                  <p className="mb-0">{fileLoadingContent ? `Docuemnt is uploading.....` : `Document upload for ${item.label}`}</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      }
                                                      <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png, doc and pdf files (Max file size 10 MB)</p>
                                                   </>
                                                   :
                                                   <>
                                                      <div className="documentwrap d-flex align-items-center mt-2 mb-2">
                                                         <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + educationProofDocuments[index].document_url}>
                                                            <p>Education proof {index + 1}</p>
                                                         </a>
                                                         <div className="documentwrapinner d-flex align-items-center">
                                                            <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('eductions_marksheet', educationProofDocuments[index].id)}><img src="/front/images/edit.png" /></span>
                                                            <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerstep9('eductions_marksheet', educationProofDocuments[index].id)}><img src="/front/images/delete.png" /></span>
                                                         </div>
                                                      </div>
                                                   </>
                                             }
                                          </>
                                       ))
                                    }
                                 </div>
                              </div>
                           </>

                           <>
                              <div className="col-md-12">
                                 <div className="form-group mb-2 taxtype">
                                    <label className="form-label">Extra Qualification</label>

                                    <Select
                                       className={`react_select ${isAddedQulification ? 'bordercss' : ''}`}
                                       options={allQualificationList}
                                       isMulti
                                       value={selectedOptionsQulification}
                                       onChange={handleSelectChangeQulification}
                                       placeholder="Select options..."
                                       styles={customStyles}
                                    />
                                    {
                                       isAddNewQulifiModalShow && <> <div className="form-group mb-2 mt-2 graybox ">
                                          <label className="form-label">Add qualification <span className="starr">*</span> </label>
                                          <div className={isAddNewQulificError ? 'input-group bordercss mb-2' : 'input-group mb-2'}>
                                             <input type="text" required={true} className="form-control" onChange={qualficationTitleChange} value={qualifiOther} name="qualification_title_change" placeholder="Enter qualification" />
                                             <div className='input-group-append'>
                                                <button className='batn batn-orange  add_newjobtitle' onClick={qualficationTitleChangeBtnClk} >Add</button>
                                             </div>

                                          </div>
                                          {isAddNewQulifiTitleErrorMessage && <><div className='error_message'>{isAddNewQulifiTitleErrorMessage}</div></>}
                                          {isAddNewQulificaTitleSuccessMessage && <><div className='error_message'>{isAddNewQulificaTitleSuccessMessage}</div></>}
                                       </div>
                                       </>
                                    }

                                    {
                                       (selectedOptionsQulification.length > 0) &&
                                       selectedOptionsQulification.map((item, index) => (
                                          <>
                                             {
                                                (!qualificationProofDocuments[index]) ?
                                                   <>
                                                      {
                                                         <div className="form-group identityproof mt-2">
                                                            <div className="customuploadsec">
                                                               {!fileLoadingContent && <input type="file" accept="image/x-png,image/jpg,image/jpeg ,application/pdf"
                                                                  onChange={(e) => userFileUploadToServerVisaFun(`qualification_proof`, e.target.files[0], item.value)}
                                                               ></input>}
                                                               <div className="customuploadinner text-center">
                                                                  <div className="my-4"><img src="/front/images/uploadicon.png" className="mt-4"></img></div>
                                                                  <p className="mb-0">{fileLoadingContent ? `Docuemnt is uploading.....` : `Document upload for ${item.label}`}</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      }
                                                      <p className="mt-2 purpleclr font12">Accept only jpg, jpeg, png, doc and pdf files (Max file size 10 MB)</p>
                                                   </>
                                                   :
                                                   <>
                                                      <div className="documentwrap d-flex align-items-center mt-2 mb-2">
                                                         <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + qualificationProofDocuments[index].document_url}>
                                                            <p>Qualification proof {index + 1}</p>
                                                         </a>
                                                         <div className="documentwrapinner d-flex align-items-center">
                                                            <span className='cursor_pointer ml-2 editfile' onClick={e => editFileFromServer('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/edit.png" /></span>
                                                            <span className='cursor_pointer ml-2' onClick={e => removeFileFromServerstep9('qualification_proof', qualificationProofDocuments[index].id)}><img src="/front/images/delete.png" /></span>
                                                         </div>
                                                      </div>
                                                   </>
                                             }
                                          </>
                                       ))
                                    }
                                 </div>
                              </div>
                           </>


                           <div className="col-md-12">
                              <div className="form-group mb-2">
                                 <label className="form-label">Languages I know <span className="starr">*</span></label>

                                 <Select
                                    className={`react_select ${isAddedLanguage ? 'bordercss' : ''}`}
                                    options={allLanguageListOption}
                                    isMulti
                                    value={selectedOptionsLanguage}
                                    onChange={handleSelectChangeLanguage}
                                    placeholder="Select options..."
                                    styles={customStyles}
                                 />
                              </div>
                           </div>


                           <div className="form-group mb-2 commonchecbox2 termscheck">
                              <div className="form-check">
                                 <input required={true} checked={isChecked} onChange={handleCheckboxChange} name="terms_and_condition" type="checkbox" className="form-check-input" />
                                 <label title="" onClick={handleCheckboxChange} className="form-check-label">
                                    <label className="checklabel mr-3">
                                       I accept
                                       <Link to={`/privacy-policy`} target='_blank' className="linktxt"> Privacy policy</Link> and
                                       <Link to={`/terms-and-conditions?usertype=jobseeker`} target='_blank' className="linktxt"> Terms &amp; conditions</Link> agreement.
                                    </label>
                                 </label>
                                 <div className="invalid-feedback">You must agree before submitting.</div>
                              </div>
                           </div>

                        </div>
                        <div className="btnsgroup">
                           <div className="text-center">
                              <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="submit" >{fileLoadingContent ? 'Please Wait...' : 'Submit'} </button>
                           </div>
                        </div>
                     </Form>


                  </div>
               </div>
            </div>
         </div>
      )
   };

   const Step10 = () => {

      return (
         <div>
            {/* <span className="stepbackarrow mr-1" >
                              <img src="/front/images/stepbackarrow.svg" onClick={handlePrevious}></img>
                           </span> */}
            <div className="stepscont">
               <div className="row">
                  <div className="col-md-12 stepscontright">
                     <form className="commonform">
                        <div className="row">

                           <div className="col-md-12 text-center laststepp py-4">
                              <img src="/front/images/checkborder.png"></img>
                              <p className="mt-4 mb-3">Congratulations! You have successfully created your Profile</p>
                              <div>
                                 <div onClick={redirectToDashboard} className="batn batn-orange">Go to dashboard</div>
                              </div>
                           </div>

                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      )
   };
   /*-------new job role----------*/

   return (
      <div className="accountsetupwrap dashboarwrap">
         {/* <ToastContainer />*/}
         <div className="accountsetupinner">
            <div className='first'></div>
            {step === 1 && (
               <div>
                  <Step1 stepLabel="Profile Upload" />
                  <div className="btnsgroup">
                     <div className="text-center">
                        <button className="batn batn-orange w-100" type="button" onClick={handleNext}>Save and Continue</button>
                     </div>
                     <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
                  </div>
               </div>
            )}
            {step === 2 && (<div className="btnsgroup"> <Step2 stepLabel="Basic Details" /></div>)}
            {step === 3 && (<div><Step3 stepLabel="Basic Details" /></div>)}
            {step === 4 && (<div><Step4 stepLabel="Contact Details" /> </div>)}
            {step === 5 && (<div><Step5 stepLabel="Work Experience" /></div>)}

            {step === 6 && (
               <div>
                  <Step6 stepLabel="Work Experience 2" />
                  <div className="btnsgroup">
                     <div className="text-center">
                        <button className="batn batn-orange w-100" type="button" onClick={handleNext}>Save and Continue</button>
                     </div>
                     <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
                  </div>
               </div>
            )}

            {step === 7 && (<div><Step7 stepLabel="Expected Salary" /></div>)}

            {step === 8 && (
               <div>
                  <Step8 stepLabel="Work Experience 2" />
                  <div className="btnsgroup">
                     <div className="text-center">
                        <button className="batn batn-orange w-100" type="button" onClick={handleNext}>Save and Continue</button>
                     </div>
                     <div className="text-center  mt-2"><button className="btn-link" type="button" onClick={handleNext}>Skip this step</button></div>
                  </div>
               </div>
            )}

            {step === 9 && (<div> <Step9 stepLabel="Availability to work" /></div>)}

            {step === 10 && (<div><Step10 stepLabel="Availability to work" /> </div>)}
         </div>

         <div className="modal fade modalmedium phone-varificationmodle" id="phone-varificationmodle" tabIndex="-1" aria-labelledby="phone-varificationmodleLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <Form className="commonform lofinsignupform loginform">
                        <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                        <div className="text-center my-4"><img src="/front/images/varifyicon.png" /></div>
                        <h2 className="text-center mb-2">Verify your phone number</h2>
                        <div className="text-center formbtmtxt mb-4">
                           <p>We have sent a code on your Phone number</p>
                        </div>
                        <div className="form-group mb-2">
                           <input
                              required
                              type="text"
                              className="form-control varificationfield"
                              placeholder="Enter the code sent on your phone number"
                           />
                        </div>
                        <div className="form-group mb-2">
                           <NavLink className="">Re-send verification code</NavLink>
                        </div>
                        <div className="form-group mb-2"><input type="button" className="batn batn-orange" value="Verify" data-bs-toggle="modal" data-bs-target="#phonvarifiedpop" data-bs-dismiss="modal" /></div>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
         <div className="modal fade modalmedium phonvarifiedpop" id="phonvarifiedpop" tabIndex="-1" aria-labelledby="phonvarifiedpopLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-body loginsignupwrapformwrap">
                     <form className="commonform lofinsignupform loginform">
                        <div className="text-center my-5"><img src="/front/images/varified.png" /></div>
                        <h2 className="text-center mb-2">Congratulation!!</h2>
                        <div className="text-center formbtmtxt mb-4">
                           <p>Your phone number has been verified successfully</p>
                        </div>
                        <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Continue with your profile" /></div>
                     </form>
                  </div>
               </div>
            </div>
         </div>


      </div>
   );
}
export default Accountsetup;