import React from 'react';

const ImageDownloadButton = ({ url }) => {
    const handleDownloadClick = () => {
        const imageUrl = url ;

        fetch(imageUrl, { mode: 'cors' }) // Try with CORS mode
            .then(response => {
                if (!response.ok) {
                    // If response is not OK, open fallback URL
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'downloaded_image.jpg';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url); // Clean up the object URL
            })
            .catch(error => {
                console.error('Error downloading image:', error);
                // Open a fallback image or PDF in a new tab on error
                window.open(imageUrl, '_blank');
            });
    };

    return (
        <span className='cursor_pointer' onClick={handleDownloadClick}>
            <img src="/front/images/download.png" alt="Download" />
        </span>
    );
};

export default ImageDownloadButton;

// import React from 'react';

// const ImageDownloadButton = ({ url }) => {
//     const handleDownloadClick = () => {
//         const imageUrl = url || "https://squadgoo.com.au/api.squadgoo.com.au/uploads/identity/1698744539identity_document_proof64.jpg";

//         fetch(imageUrl, { mode: 'cors' }) // Try with cors mode
//             .then(response => {
//                 if (!response.ok) throw new Error('Network response was not ok');
//                 return response.blob();
//             })
//             .then(blob => {
//                 const url = window.URL.createObjectURL(blob);
//                 const a = document.createElement('a');
//                 a.href = url;
//                 a.download = 'downloaded_image.jpg';
//                 document.body.appendChild(a);
//                 a.click();
//                 document.body.removeChild(a);
//                 window.URL.revokeObjectURL(url); // Clean up the object URL
//             })
//             .catch(error => {
//                 console.error('Error downloading image:', error);
//             });
//     };

//     return (
//         <span className='cursor_pointer' onClick={handleDownloadClick}>
//             <img src="/front/images/download.png" alt="Download" />
//         </span>
//     );
// };

// export default ImageDownloadButton;