import axios from "axios";
import React, { useState, useEffect , useRef } from "react";
import Select from "react-select";



const CategorySelect = ({defaultJobID, onJobTitleChange ,classContainerName="" ,classForCategory='' , classForTitle='',fieldName='job_title'}) => {
  const [selectedOptionValue, setSelectedOptionValue] = useState('');
  const [optionsData, setJobtitleList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); 
  const [selectedOption, setSelectedOption] = useState(null); 
  const jobTitleSelectRef = useRef(null);

  useEffect(() => {

    if (defaultJobID) {
      setSelectedOptionValue(defaultJobID);
    }
    const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
    if (sessionStorageData) {
       setJobtitleList(sessionStorageData.get_jobtitle_options);
    } else {
       (async () => {
          try {
             const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_jobtitle_options");
             if (resData.data.status == 1) {
                setJobtitleList(resData.data.data.results);
             }
          } catch (error) { console.log(error); }
       })();
    }
 }, []);

  // Extract only the category labels for the first dropdown
  const categoryOptions = optionsData.map((category) => ({
    label: category.label,
    value: category.label,
  }));

  // Handle category selection
  const handleCategoryChange = (selected) => {
    setSelectedCategory(selected);
    setSelectedOption(null); // Reset the option select when a new category is selected
  };

  // Get category-specific options based on selectedCategory
  const getCategoryOptions = () => {
    if (!selectedCategory) return [];
    const category = optionsData.find((cat) => cat.label === selectedCategory.value);
    return category ? category.options : [];
  };

  useEffect(() => {
    if (selectedOptionValue) {
      const category = optionsData.find((cat) =>
        cat.options.some((option) => option.value === selectedOptionValue)
      );

      if (category) {
        setSelectedCategory({ label: category.label, value: category.label });
        const option = category.options.find(
          (option) => option.value === selectedOptionValue
        );
        setSelectedOption(option);
      }
    }
  }, [selectedOptionValue]);

  const customStyles = {
    option: (provided, state) => ({
       ...provided,
       padding: '3px 10px',
       fontSize: '13px'
    }),
    groupHeading: (provided) => ({
       ...provided,
       fontSize: '13px',
       fontWeight: 'bold', 
       color: '#000'
    })
 };

 const handleJobTitleChange = (selected) => {
  setSelectedOption(selected);
  onJobTitleChange(selected); // Notify parent component of the selected job title
  setTimeout(() => {
      if (jobTitleSelectRef.current) {
        jobTitleSelectRef.current.focus();
      }
    }, 0);
};

  return (
    <>
      <div className={`form-group mb-2 ${classContainerName}`}>
        <label className="form-label">Job category <span className="starr">*</span></label>
        <Select 
          className= {`react_select ${classForCategory}`}
          options={categoryOptions}
          value={selectedCategory}
          styles={customStyles}
          onChange={handleCategoryChange}
          placeholder="Select Category"
        />
      </div>
      <div className={`form-group mb-2 ${classContainerName}`}>
        <label className="form-label">Job title <span className="starr">*</span></label>
        <Select 
          className= {`react_select ${classForTitle}`}
          ref={jobTitleSelectRef} 
          options={getCategoryOptions()}
          value={selectedOption}
          onChange={handleJobTitleChange}
          styles={customStyles}
          placeholder="Select jobtitle"
          isDisabled={!selectedCategory} 
          name={fieldName}
        />
      </div>
    </>
  );
};

export default CategorySelect;