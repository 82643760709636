import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';

import JobOffersModals from './JobOffersModales';

import { useSelector, useDispatch } from 'react-redux'
import { recdeshjobactioncheck } from '../../../redux_state/reducres/findStaffBtnAction';

const UserAllPostedJobs = () => {
   const dispatch = useDispatch();

   const [isResumeModalShow, isSetResumeModalShow] = useState(false);
   const [isDataRemoving, setIsDataRemoving] = useState(false);
   const [deleteItemID, setDeleteItemID] = useState(0);
   const [deleteItemType, setDeleteItemType] = useState(0);


   const [userdetails, setUserdetails] = useState([]);
   const [userID, setuserID] = useState(false);
   //const profilepic = (userdetails.profilepic) ? userdetails.profilepic : '/front/images/user.svg';

   const [jobActiveListArray, setJobActiveListArray] = useState([]);
   const [isDataFatching, setisDataFatching] = useState(true);
   const showDeleteeModal = (itemid, type) => { isSetResumeModalShow(true); setDeleteItemID(itemid); setDeleteItemType(type) };


   const getUserDetailsFun = (userid) => {
      (async () => {
         try {
            setisDataFatching(true);
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userid + '?callingby=adminaccount');
            if (resData.data.status == 1) {
               setUserdetails(resData.data.data.results);
               setJobActiveListArray(resData.data.data.results.jobdata.data.ttldata);
               localStorage.setItem('signup_userdetails', JSON.stringify(resData.data.data.results));
               setisDataFatching(false);
            }
         } catch (error) { console.log(error); }
      })();
   }
   useEffect(() => {
      const queryParameters = new URLSearchParams(window.location.search)
      const userid = queryParameters.get("id");

      setuserID(userid);
      if (userid) { getUserDetailsFun(userid); }
   }, []);


   const deletefindStaffJob = () => {
      //isSetResumeModalShow(true);
      //setIsDataRemoving(true)
      if (isResumeModalShow) {
         (async () => {
            try {
               const formData = new FormData();
               axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/findstaffdata_form_remove/${userdetails.login_token}/${deleteItemID}/${deleteItemType}`, formData)
                  .then((response) => {
                     if (response.status == 200 && response.data.status == 1) {
                        //console.log(response.data)
                        toast.success('Removed Successfully!');
                        // getAllJobListing();
                        getUserDetailsFun(userID)
                        closeResumeModal();
                     }
                  })
                  .catch((error) => { console.error(error); });
            } catch (error) { console.log(error); }

         })();
      }
   }

   const closeResumeModal = () => { setIsDataRemoving(false); isSetResumeModalShow(false) };


   /*---------------------------*/
   const [isModalDataLoad, setIsModalDataLoad] = useState(false);
   const [staffViewDetailsDataArr, setstaffViewDetailsDataArr] = useState([]);
   const [isJobDetailsModalShow, setIsJobDetailsModalShow] = useState(false);
   const [currentActiveTab, setcurrentActiveTab] = useState('job-detail');
   const [itemUserTypeRole, setItemUserTypeRole] = useState(0);
   const jobDetailModuleShow = (job_id, role = 0) => {
      setItemUserTypeRole(role);
      setIsModalDataLoad(true); setIsJobDetailsModalShow(true); setcurrentActiveTab('job-detail');
      (async () => {
         try {
            const formData = new FormData();
            axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_jobdetail_for_jobseeker/${userdetails.login_token}/${job_id}/${0}`, formData)
               .then((response) => {
                  setIsModalDataLoad(false);
                  if (response.status == 200 && response.data.status == 1) { setstaffViewDetailsDataArr(response.data.data); }
               })
               .catch((error) => { console.error(error); });
         } catch (error) { console.log(error); setIsModalDataLoad(false); }
      })();
   }
   const closeJobDetailModal = () => { setIsJobDetailsModalShow(false) }


   return (
      <>


         <div className="joblistingwrap">
            {
               isDataFatching ?
                  <>
                     <div className='card-box work-experiencesec commonform mb-3' >

                        <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                     </div>
                  </>
                  :
                  <>
                     <div className="card-box joblistbox mb-3">
                        <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                           <div className="tblsearch"><input type="text" className="form-control" placeholder="Search" /></div>
                           <div className="d-flex">
                              <div className="tblrightfilters d-flex align-items-center mr-1">
                                 <select className="form-control ml-3 text-capitalize">
                                    <option value="all">all</option>
                                    <option value="verified">verified</option>
                                    <option value="unverified">unverified</option>
                                 </select>
                              </div>
                              <div className="tblrightfilters d-flex align-items-center">
                                 <select className="form-control ml-3 text-capitalize">
                                    <option value="all">all</option>
                                    <option value="active">Active</option>
                                    <option value="completed">Completed</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     {
                        jobActiveListArray.length ?
                           <>
                              {
                                 jobActiveListArray.map((item, index) => (
                                    <div className="card-box joblistbox mb-3">
                                       <span className="custom-tooltip" data-toggle="tooltip" title={item.status == 'expire' ? 'Expired Job' : item.status == 'completed' ? 'Completed Job' : 'Active Job'}>
                                          <div className="d-flex flex-wrap justify-content-between">
                                            
                                             <h3 className='text-capitalize align-items-center mb-3' onClick={(e) => jobDetailModuleShow(item.id, userdetails.user_type_role)} >

                                                <i className={`${item.status == 'expire' ? 'fared' : item.status == 'completed' ? 'fagreen' : 'fablue'}  fa fa-dot-circle-o ml-1 mr-5`} ></i>
                                                {item.job_title_text}


                                             </h3>
                                             <div className="d-flex align-items-center mb-3">
                                                <span className="postedjobstatus activejoblink">Active</span>
                                                {/* <span className="postedjobstatus complitedjoblink">Complited</span> */}
                                             </div>
                                          </div>
                                          <div className="d-flex flex-wrap">
                                             <div className="icontxt"><img src="/front/images/calender.png" />Job created:<span>{item.created_date_format}</span></div>
                                             <div className="icontxt"><img src="/front/images/calender.png" />Job closed:<span>{item.expired_date_format}</span></div>
                                             <div className="icontxt"><i className="fa fa-paper-plane-o" aria-hidden="true"></i>Offer sent:<span>{item.expired_date_format}</span></div>
                                             <div className="icontxt"><i className="fa fa-check-square-o" aria-hidden="true"></i>Accepted offers:<span>{item.expired_date_format}</span></div>
                                             <div className="icontxt"><i className="fa fa-handshake-o" aria-hidden="true"></i>Hired:<span>{item.expired_date_format}</span></div>
                                          </div>
                                          <div className="d-flex flex-wrap">
                                             <div className="icontxt">
                                                {
                                                   item.status == 'active' ?
                                                      <> <a className="icontxt icontxtbtm icontxtdelete mt-2" onClick={() => showDeleteeModal(item.id, 'active')} ><img src="/front/images/deletenew.png" /> Delete</a></>
                                                      :
                                                      item.status == 'completed' ?
                                                         <>
                                                            <Link onClick={() => dispatch(recdeshjobactioncheck({ id: item.id, actiontype: 'repost', screentype: 'active_job' }))} className="icontxt icontxtbtm icontxtview"><img src="/front/images/eyeicon.png"></img> Repost</Link>
                                                         </>
                                                         :
                                                         <></>
                                                }

                                             </div>

                                          </div>
                                       </span>
                                    </div>
                                 ))
                              }
                           </>
                           :
                           <><p>There is no active jobs</p></>
                     }
                  </>
            }


         </div>
         {/* modals */}

         <Modal className="modal fade modallarg jobmodle ssssssss jobdetailmdl" id="jobsetailmodle" show={isJobDetailsModalShow} onHide={closeJobDetailModal}>
            {
               <>
                  <div className="modal-dialog1">
                     <div className="modal-content">
                        <div className="modal-body">
                           <button type="button" className="btn-close" onClick={closeJobDetailModal} ></button>

                           <JobOffersModals itemUserTypeRole={itemUserTypeRole} staffViewDetailsDataArr={staffViewDetailsDataArr} />

                        </div>
                        <div className={`model_loader ${!isModalDataLoad ? 'display_none' : ''}`}>
                           <img src={'/front/images/loader.gif'} />
                        </div>
                     </div>
                  </div>
               </>
            }
         </Modal>
         <Modal show={isResumeModalShow} onHide={closeResumeModal}>
            <div className="modal-dialog1 findastaffmodle">
               <div className="modal-content">
                  <div className="modal-body p-5">
                     <button type="button" className="btn-close" onClick={closeResumeModal}></button>
                     {/* <div className="text-center mb-3">
                                       <img src="/front/images/deletenew.png" />
                                    </div> */}
                     <h4 className="text-center mb-4">Do you want to Delete?</h4>

                     <div className="btnsgroup text-center">
                        <Link onClick={deletefindStaffJob} className="batn batn-orange" data-bs-dismiss="modal">Yes</Link>
                        <Link onClick={closeResumeModal} className="batn batn-orange batn-border">No</Link>
                     </div>
                     {isDataRemoving ? <><h5 className="text-center mb-4 mt-4">Loading......</h5></> : <></>}
                  </div>
               </div>
            </div>
         </Modal>
      </>
   );
}
export default UserAllPostedJobs;