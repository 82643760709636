import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DoeanloadFileComp from './DownloadFileComp';
//import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { documentCheckValidation } from '../redux_state/reducres/checkDocumentstatus';

import customStyles from '../expocomp/SelectStyleComp';

const DocumentsComp = (getdata) => {
   const userdetails = JSON.parse(localStorage.getItem("squadGologin"));
   const squadGoUserType = (localStorage.getItem("squadGoUserType"));
   const [userDocumentList1, setUserDocumentList1] = useState([]);
   const [isDataLoading, setIsDataLoading] = useState(true);
   const [callUserID, setcallUserID] = useState((typeof (getdata.attr) == 'undefined') ? userdetails.unique_id : getdata.attr);
   const dispatch = useDispatch();
   const [isDocumentsModalShow, isSetDocumentsModalShow] = useState(false);
   const [isdocumentModallShow, setdocumentModallShow] = useState(false);
   const [selectItemType, setSelectItemType] = useState(false);
   const [modalformAction, setModalformAction] = useState('add');
   const [modalformActionId, setModalformActionId] = useState(0);
   const [currentWorkID, setCurrentWorkID] = useState(0);
   const [currentkey, setcurrentkey] = useState('');
   const [itemData, setitemData] = useState([]);
   const [workExpData, setWorkExpData] = useState([]);
   const [isCurrentVerified, setisCurrentVerified] = useState('unverified');
   const closeDocumentsModal = () => { isSetDocumentsModalShow(false) };
   const [identityDocumentType, setIdentityDocumentType] = useState([]);
   const [varifyDocumentList, setVarifyDocumentList] = useState([]);
   const [visaTypeList, setVisaTypeList] = useState([]);
   const [fileLoadingContent, setLoadingContent] = useState(0);
   const [fileType, setFileType] = useState('');
   const [fileTypeValue, setFileTypeValue] = useState(0);


   const getUserDetails = () => {
      (async () => {
         try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + callUserID);
            if (resData.data.status == 1) {
               //setUserDetailsArray(resData.data.data.results);
               //setUserDocumentList(resData.data.data.results.user_document_list);
               const userdata = resData.data.data.results;

               setUserDocumentList1(userdata.user_document_list_gb);
               setIsDataLoading(false);
               const hasUserDocValidation = userdata?.checkfieldvalidation?.userdoc_validation === 1;
               (hasUserDocValidation) ? dispatch(documentCheckValidation(1)) : dispatch(documentCheckValidation(0));

            }
         } catch (error) { console.log(error); }
      })();
   }


   useEffect(() => {

      const sessionStorageData = JSON.parse(localStorage.getItem("sessionStorageData"));
      if (sessionStorageData) {
         setIdentityDocumentType(sessionStorageData.get_all_identity_documenttype);
         setVisaTypeList(sessionStorageData.get_all_visatype);
         setVarifyDocumentList(sessionStorageData.get_all_address_type_options);
      } else {
         (async () => {
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_identity_documenttype");
               if (resData.data.status == 1) { setIdentityDocumentType(resData.data.data.results); }
            } catch (error) { console.log(error); }
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_visatype");
               if (resData.data.status == 1) { setVisaTypeList(resData.data.data.results) }
            } catch (error) { console.log(error); }
            try {
               const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_address_type");
               if (resData.data.status == 1) { setVarifyDocumentList(resData.data.data.option_array) }
            } catch (error) { console.log(error); }

         })();
      }

   

      if (callUserID) {
         getUserDetails()
      } else { setTimeout(function () { window.location.replace("/login"); }, 100) }

   }, []);



   const CheckExtension = (data) => {
      switch (data.data.file_extenstion) {
         case 'jpg':
            return <img className="w-100 height-250" src={`https://squadgoo.com.au/api.squadgoo.com.au/${data.data.document_url}`} />
            break;
         case 'pdf':
            return <img className="w-100 height-250" src={`front/images/pdfimage.png`} />
            break;
         default:
            return <img className="w-100 height-250" src={`https://squadgoo.com.au/api.squadgoo.com.au/${data.data.document_url}`} />
            break;
      }
   }

   const CheckFileType = (data) => {
      switch (data.data.uploaded_for) {
         case 'identity_document_proof':
            return 'Identity Document';
            break;
         case 'address_document_proof':
            return 'Address Document';
            break;
         case 'payment_pay_slip':
            return 'Salary Slip';
            break;
         case 'user_document_Documents':
            return 'Documents';
            break;
         default:
            return 'Document';
            break;
      }
   }

   const removeFileFromServer = async (field, id, indexkey) => {
      if (window.confirm("Do you want to remove this file?")) {
         setIsDataLoading(true);
         try {
            const formData = new FormData();
            formData.append('field', field);
            formData.append('file_id', id);
            const response = await axios.post(
               `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_upload_file_remove`,
               formData, { headers: { 'Content-Type': 'multipart/form-data' } });

            // setIsDataLoading(false);
            if (response.data.status == 1) {
               toast.success('Removed Successfully!');
               getUserDetails();
               if (currentkey == 'workexperience_payslips') {
                  getWorkExpeirenceData(true);
               }

               const updatedItems = [...itemData];
               updatedItems.splice(indexkey, 1);
               setitemData(updatedItems);


            } else { toast.warning(response.data.message); }
         } catch (error) { console.error('Error uploading file:', error); }
      }
   }

   const sharePostDocuments = (itemtype, action, action_id, is_workexp) => {
      action_id == 'add' ?
         setModalformActionId(0) :
         setCurrentWorkID(is_workexp);
      setModalformActionId(action_id); setModalformAction(action); setSelectItemType(itemtype); isSetDocumentsModalShow(true);
   }


   const userFileUploadToServerBasicFun = async (field, file) => {
      const maxSizeInBytes = 5 * 1024 * 1024; // For example, 1 MB
      if (file.size > maxSizeInBytes) {
         console.log("File size is greater than the specified limit.");
         toast.warning('File size is greater than the specified limit.');
      } else {
         setFileType(field); setFileTypeValue(file);
      }
   };

   const documentFileUpdate = async () => {
      try {
         setLoadingContent(1);
         const formData = new FormData();
         formData.append('file', fileTypeValue);
         formData.append('uploaded_for', fileType);
         formData.append('userid', callUserID);
         formData.append('action_type', modalformAction);
         formData.append('action_type_id', modalformActionId);
         formData.append('reference_id', currentWorkID);
         formData.append('screen_type', currentkey);
         formData.append('maxSizeInBytes', (5 * 1024 * 1024));
         const response = await axios.post(
            `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_file_uploads`,
            formData, { headers: { 'Content-Type': 'multipart/form-data' } });
         setLoadingContent(0);
         setdocumentModallShow(false);
         if (response.data.status == 1) {
            getUserDetails(); isSetDocumentsModalShow(false); toast.success(response.data.message);
         } else { toast.warning(response.data.message); }
      } catch (error) { console.error('Error uploading file:', error); }
   }

   const getWorkExpeirenceData = (type) => {
      (async () => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/get_work_experience/${callUserID}`);
            if (response.data.status == 1) {
               setdocumentModallShow(type);
               setWorkExpData(response.data.data.results);
            }
         } catch (error) { console.log(error); }
      })();
   }

   const setdocumentModallShowFun = (type, key, loaddata, isCurrentVerified) => {
      setcurrentkey(key);
      setSelectItemType(key);
      setitemData(loaddata);
      //console.log(isCurrentVerified)
      setisCurrentVerified(isCurrentVerified);
      if (key == 'workexperience_payslips') {
         getWorkExpeirenceData(type);
      } else {
         setdocumentModallShow(type);
      }
   }

   /*--------------docuemnt verified---------------*/
   const isDocumentVerifiedFun = (itemid) => {
      //alert();
      (async () => {
         try {
            const response = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/user_docuemnts_verifications/${userdetails.login_token}/${itemid}/${currentkey}`);
            if (response.data.status == 1) {
               toast.success(response.data.message);
               setitemData(response.data.data.itemdata);
               getUserDetails();
            } else {
               //toast.warning(response.data.message); 
            }
         } catch (error) { console.log(error); }
      })();


   }

   const HtmlDataFun = (attr) => {
      const item2 = attr.item;
      const indexkey = attr.indexkey;
      return (<div className="col-md-4 mb-3">
         <div className="card-box documentbox">
            <div className="documentboximg">
               <CheckExtension data={item2} />
            </div>
            <h4 className="text-center mt-3"><CheckFileType data={item2} /> </h4>
            <div className="documentwrap d-flex align-items-center ml-0 mt-3 justify-content-center">
               {
                  (isCurrentVerified == 'verified' && squadGoUserType != 'admin')
                     ?
                     <></>
                     :
                     <span className='cursor_pointer' onClick={(e) => sharePostDocuments(currentkey, 'edit', item2.id)} ><img src="/front/images/edit.png" /></span>
               }
               
               {isCurrentVerified == 'unverified' && <span className='cursor_pointer' onClick={(e) => removeFileFromServer(item2.uploaded_for, item2.id, indexkey)}  >
                  <img src="/front/images/delete.png" />
               </span>}
               
               <a target='_blank' href={`https://squadgoo.com.au/api.squadgoo.com.au/${item2.document_url}`} download={true} >
                  <img src="/front/images/Show.svg" />
               </a>
               
               
               <DoeanloadFileComp url={`https://squadgoo.com.au/api.squadgoo.com.au/${item2.document_url}`} />
               {
                  squadGoUserType == 'admin' &&
                  <>
                     {
                        item2.is_verified == 1 ?
                           <>
                              <span className='cursor_pointer' onClick={() => isDocumentVerifiedFun(item2.id)}>
                                 <img src="/front/images/vfy.png" className="vicon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Verified"></img>
                              </span>
                           </>
                           :
                           <>
                              <span className='cursor_pointer' onClick={() => isDocumentVerifiedFun(item2.id)}>
                                 <img src="/front/images/uvfy.png" className="vicon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Unverified "></img>
                              </span>
                           </>
                     }
                  </>
               }
            </div>
         </div>
      </div>);
   }

   const AddMoreContent = (attr) => {
      return (
         (isCurrentVerified == 'verified' && squadGoUserType != 'admin')
            ?
            <></>
            :
            <>
               <div className="col-md-4 mb-3">
                  <div onClick={(e) => sharePostDocuments(currentkey, 'add', 0, attr.currentId)} className="addnew_document d-flex align-items-center justify-content-center" type="button" >
                     <div className="addnew_documentinner">
                        <span><img src="/front/images/upload.png" /><span>Click here to upload a file</span></span>
                     </div>
                  </div>
               </div>
            </>
      )
   }


   /*----------------------------------*/
  //const customStyles = {option: (provided, state) => ({...provided,padding: '3px 10px',}),};


  const [addressProofType , setAddressProofType] = useState('');
  const addressProofChange = (select) => {
      setAddressProofType(select.value);
   }

   return (
      <>

         {/* <div className={`jobbtmsec py-5 ${isDataLoading ? 'bgpageload1' : ''}`}></div> */}
         {
            isDataLoading ?
               <>
                  <div className='card-box work-experiencesec commonform mb-3' >
                     <p className='text-center mt-10'><img className='width-100' src={'/front/images/loader.gif'} /></p>
                  </div>
               </>
               :
               <>
                  <div className={`jobbtmsec ${isDataLoading ? 'bgpageload1' : ''}`}>
                     <div className="container">
                        <div className="newdocumentsec row ">

                           {
                              (!isDataLoading && userDocumentList1.length) > 0 ?
                                 userDocumentList1.map((itemdata, indexkey) => (
                                    <>
                                       <div className="col-md-4">
                                          <div className="documenttitleicon card-box mb-3 text-center" onClick={() => setdocumentModallShowFun(true, itemdata.itemtype, itemdata.itemdata, itemdata.is_screen_verified)} >
                                             {
                                                <>
                                                   {
                                                      (itemdata.is_screen_verified == 'verified') ?
                                                         <>
                                                            <img src="/front/images/vfy.png" className="vicon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Verified"></img>
                                                            {/* <img className='veriunveri-images documentverification' src="/front/images/vfy.png"></img> */}
                                                         </>
                                                         :
                                                         <>
                                                            <img src="/front/images/uvfy.png" className="vicon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img>
                                                            {/* <img className='veriunveri-images documentverification' src="/front/images/uvfy.png"></img> */}
                                                         </>
                                                   }
                                                </>
                                             }
                                             <div className="documenticon mb-2">
                                                <img src={itemdata.image_path} />
                                             </div>
                                             <div className="documentttl text-capitalize">
                                                {itemdata.key} ({itemdata.totalcount})
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                 ))
                                 :
                                 <></>
                           }
                        </div>
                     </div>
                  </div>



                  <Modal show={isDocumentsModalShow} onHide={closeDocumentsModal} className="documentnewmodal">
                     <div className="modal-dialog1 findastaffmodle">
                        <div className="modal-content">
                           <div className="modal-body p-3">
                              <button type="button" className="btn-close" onClick={closeDocumentsModal} disabled={fileLoadingContent} ></button>
                              <h4 className="">Upload document</h4>
                              <div className="row stepscont">
                                 <div className="col-md-12 stepscontright">
                                    <div className="row">
                                       {/* {modalformAction} */}
                                      
                                       <div className="col-md-12">
                                          {
                                             selectItemType == 'identity_document_proof' ?
                                                <>
                                                   <div className="form-group mb-2">
                                                      <label className="form-label">Document for identity proof <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                                      <select className="form-control" name="identity_proof_type" fdprocessedid="m7dnpr">
                                                         <option value="">Select document</option>
                                                         {
                                                            <>
                                                               {
                                                                  identityDocumentType.map((item, index) => (
                                                                     <option value={item.id}>{item.name}</option>
                                                                  ))
                                                               }
                                                            </>
                                                         }
                                                      </select>
                                                   </div>
                                                </>
                                                : ''
                                          }

                                          {
                                             selectItemType == 'address_document_proof' ?
                                                <>
                                                   <div className="form-group mb-2">
                                                      <label className="form-label">Address Document Type<span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                                      {/* <select className="form-control" name="identity_proof_type" fdprocessedid="m7dnpr">
                                                         <option value="">Select document</option>
                                                         {
                                                            <>
                                                               {
                                                                  varifyDocumentList.map((item, index) => (
                                                                     <option value={item.document_id}>{item.document_type}</option>
                                                                  ))
                                                               }
                                                            </>
                                                         }
                                                      </select> */}


                                                      <Select className='react_select' name="address_document_proof_type" required aria-label="4"
                                                         options={varifyDocumentList}
                                                         isSearchable
                                                         placeholder="Search..."
                                                         styles={customStyles}
                                                         onChange={addressProofChange}
                                                         value={varifyDocumentList.find(item => item.value == addressProofType)}
                                                      />
                                                   </div>
                                                </>
                                                : ''
                                          } 
                                          {
                                             selectItemType == 'payment_pay_slip' ?
                                                <>
                                                   <div className="form-group mb-2">
                                                      <label className="form-label">Visa subclass <span className="starr">*</span> <span className="infowrap"><i className="fa fa-info"></i><span className="onhoverinfo">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</span></span></label>
                                                      <select className="form-control" name="identity_proof_type" fdprocessedid="m7dnpr">
                                                         <option value="">Select document</option>
                                                         {
                                                            <>
                                                               {
                                                                  visaTypeList.map((item, index) => (
                                                                     <option value={item.id}>{item.visa}</option>
                                                                  ))
                                                               }
                                                            </>
                                                         }
                                                      </select>
                                                   </div>
                                                </>
                                                : ''
                                          }

                                       </div>
                                       <div className="col-md-12 mb-1">
                                          <div className="form-group mb-2">
                                             <label className="form-label">Upload document<span className="starr">*</span> </label>
                                             <div className="form-group identityproof newfiletype">
                                                <input
                                                   className="form-control"
                                                   type="file"
                                                   accept="image/x-png,image/gif,image/jpeg ,application/pdf"
                                                   onChange={(e) => userFileUploadToServerBasicFun(selectItemType, e.target.files[0], true, true)}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-12 mb-1">
                                          <button disabled={fileLoadingContent} className="batn batn-orange w-100" type="button" onClick={documentFileUpdate} >{fileLoadingContent ? 'Uploading....' : 'Upload'} </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Modal>

                  <Modal show={isdocumentModallShow} onHide={() => setdocumentModallShow(false)} className="documentnewmodal">
                     <div className="modal-dialog1">
                        <div className="modal-content">
                           <div className="modal-body p-3 modal-minheight ">
                           {/* {isCurrentVerified == 'unverified' ? 'true' : 'false'} */}
                              <button type="button" className="btn-close" onClick={() => setdocumentModallShow(false)} ></button>
                              {currentkey == 'workexperience_payslips' ?
                                 <>

                                    {

                                       (workExpData.length > 0) ?
                                          <>

                                             {
                                                workExpData.map((item, index) => (
                                                   <>
                                                      {
                                                         // item.total_files_of_workexperience > 0 ?
                                                         <>
                                                            <h4 className='font-bold'>{item.work_expe_jobtitle + '  (' + (item.work_expe_companyname) + ')'}</h4>
                                                            {/* <h4>  
                                                               {`${item.work_expe_jobtitle} (${item.work_expe_companyname})
                                                                   ${item.work_expe_duration_from_month+' '+(item.work_expe_duration_from_year ? item.work_expe_duration_from_year : '.....')} to
                                                                   ${item.work_expe_duration_to_month+' '+(item.work_expe_duration_to_year ? item.work_expe_duration_to_year : '.....')}
                                                               `}
                                                            </h4> */}
                                                            <div className="row">
                                                               {/* {item.id} */}
                                                               {
                                                                  (item.workexperience_payslips.length > 0) ?
                                                                     <>
                                                                        {
                                                                           item.workexperience_payslips.map((item2, index2) => (
                                                                              <>
                                                                                 <HtmlDataFun item={item2} indexkey={index2} />
                                                                              </>
                                                                           ))
                                                                        }
                                                                        {
                                                                           item.workexperience_payslips.length < 12 && <AddMoreContent currentId={item.id} />
                                                                        }

                                                                     </>
                                                                     :
                                                                     <>
                                                                        <AddMoreContent currentId={item.id} />
                                                                     </>
                                                               }
                                                            </div>
                                                         </>
                                                         // :
                                                         // <></>
                                                      }

                                                   </>
                                                ))
                                             }

                                          </>
                                          :
                                          <>
                                             <div className='text-center pt-5'>
                                                <h4>You have not added any work experience.</h4>
                                                <Link to={`/work-experience`}>
                                                   Add work experience
                                                </Link>

                                             </div>
                                          </>
                                    }
                                 </>
                                 :
                                 <>
                                    <div className="row">
                                       {
                                          (itemData.length > 0) &&
                                          <>
                                             {
                                                itemData.map((item, index) => (
                                                   <>
                                                      <HtmlDataFun item={item} indexkey={index} />
                                                   </>
                                                ))
                                             }
                                          </>
                                       }
                                       <AddMoreContent currentId={0} />
                                    </div>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </Modal>
               </>
         }



      </>
   );
}
export default DocumentsComp;