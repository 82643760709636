import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ShowSoacialMedia from '../../ShowSocialMediaComp';

const JobOffersModals = (attr) => {

    var staffViewDetailsDataArr = attr.staffViewDetailsDataArr;
    var activeTab =  'job-detail';
    var itemUserTypeRole  = attr.itemUserTypeRole;
    const [currentActiveTab, setcurrentActiveTab] = useState(activeTab);
    const Availability = (data) => {
        let obj = (data.abdata) ? data.abdata : [];
        return (
           obj.length ?
              <>
                 {
                    obj.map((item, indxe) => (
                       <>
                          <div key={'availibility_' + indxe} className="availability-sec d-flex align-items-center  mb-2">
                             <p>{item.name}</p>
                             <strong>{item.from_value ? item.from_value : '--.--'}</strong>
                             <div className="subheadpera">To</div>
                             <strong>{item.to_value ? item.to_value : '--.--'}</strong>
                          </div>
                       </>
                    ))
                 }
  
              </>
              : <></>
        )
     }

    return (
        <>
            {staffViewDetailsDataArr.job_title_text && <>
                <nav className="jobseekertabs mt-0">
                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                        <button className={`${currentActiveTab == 'job-detail' ? 'active' : ''} nav-link`} id="jobdetail" onClick={() => setcurrentActiveTab('job-detail')} ><i className="fa fa-briefcase mr-1" aria-hidden="true"></i> Job Details</button>
                        <button className={`${currentActiveTab == 'about-recruiter' ? 'active' : ''} nav-link`} onClick={() => setcurrentActiveTab('about-recruiter')} id="aboutrecruiter"  ><i className="fa fa-user-o mr-1" aria-hidden="true"></i>
                            About {
                                itemUserTypeRole == 'rec_owners' ?
                                    <>Owner</>
                                    : itemUserTypeRole == 'rec_reprasentative' ? <>Reprasentative</>
                                        : <>Recruiter</>
                            }
                        </button>
                        <button className={`${currentActiveTab == 'accepted-by' ? 'active' : ''} nav-link`} onClick={() => setcurrentActiveTab('accepted-by')} id="acceptedby" >
                            <i className="fa fa-hand-pointer-o mr-1" aria-hidden="true"></i>Accepted Offers
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">

                    {currentActiveTab == 'job-detail' && <div className="tab-pane1" id="jobdetailpan" role="tabpanel" aria-labelledby="jobdetail">
                        <div className="joblistbox">
                            <div className="d-flex flex-wrap justify-content-between">
                                <h3 className='text-capitalize font-bold'>
                                    <b>{staffViewDetailsDataArr.job_title_text}</b>
                                </h3>
                                <h3 className="job_price orange_clr">

                                    {
                                        staffViewDetailsDataArr.fixed_salary ?
                                            <>
                                                <strong>{staffViewDetailsDataArr.fixed_salary ? '$' + staffViewDetailsDataArr.fixed_salary + '' : '--'}</strong>
                                            </>
                                            :
                                            <>
                                                <strong>{staffViewDetailsDataArr.salary_minimum ? '$' + staffViewDetailsDataArr.salary_minimum + '/Hour' : '--'}</strong>
                                                <strong className="subheadpera">To</strong>
                                                <strong>{staffViewDetailsDataArr.salary_maximum ? '$' + staffViewDetailsDataArr.salary_maximum + '/Hour' : '--'}</strong>
                                            </>
                                    }

                                </h3>
                            </div>
                            <div><span className="tags">{staffViewDetailsDataArr.job_type_text}</span></div>
                            {/* <p>Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Sed hendrerit. Maecenas nec odio et ante condimentum rhoncus, sem quam semper libero condimentum rhoncus, sem quam...</p> */}
                            <div className="d-flex flex-wrap mt-2">
                                <div className="icontxt"><img src="/front/images/calender.png"></img> Offer date: <span>{staffViewDetailsDataArr.created_date_format ?? '.....'}</span></div>
                                <div className="icontxt"><img src="/front/images/clock.png"></img> Offer expire date: <span>{staffViewDetailsDataArr.expired_date_format ?? '.....'}</span></div>
                                <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span>{staffViewDetailsDataArr.job_location_text ? staffViewDetailsDataArr.job_location_text : '......'}</span></div>
                                <div className="icontxt"><img src="/front/images/bag.png"></img> Job type: <span>{staffViewDetailsDataArr.job_type_text}</span></div>
                                <div className="icontxt"><img src="/front/images/star.png"></img> Experience: <span>{staffViewDetailsDataArr.total_experience_month + '.' + staffViewDetailsDataArr.total_experience_year + ' Years'}</span></div>
                                <div className="icontxt"><img src="/front/images/fa_route.png"></img> Range from location: <span>{staffViewDetailsDataArr.range_from_location}{staffViewDetailsDataArr.range_from_location > 0 ? 'KM' : ''}</span></div>
                                <div className="icontxt"><img src="/front/images/user.svg"></img> Total staff loooking: <span>{staffViewDetailsDataArr.staff_number}</span></div>
                                <div className="icontxt"><img src="/front/images/dollar.png"></img> Salary Type: <span>{staffViewDetailsDataArr.fixed_salary ? 'Fixed' : 'Hourly'}</span></div>
                            </div>
                            <hr>
                            </hr>
                            <h5>Availability to work</h5>
                            <p>Jobseeker should available on below time.</p>
                            <Availability abdata={staffViewDetailsDataArr.availibility} ></Availability>
                            <hr></hr>
                            <h5>Paying extra for</h5>
                            <ul>
                                <li className='text-capitalize'>Public holidays : {staffViewDetailsDataArr.are_you_paying_extra_in_holiday}  </li>
                                <li className='text-capitalize'>Weekend : {staffViewDetailsDataArr.are_you_paying_extra_in_weekend} </li>
                                <li className='text-capitalize'>Shift loading : {staffViewDetailsDataArr.are_you_paying_extra_in_overtime} </li>
                            </ul>
                            <hr></hr>
                            <h5>Education qualification</h5>
                            <p>Jobseeker should have following degree and diplomas:</p>
                            <ul>
                                <li>{staffViewDetailsDataArr.education_qualification_text}</li>
                            </ul>
                            <hr></hr>
                            <h5>Language known</h5>
                            <p>Jobseeker should be fluent in following languages:</p>
                            <ul> <li>{staffViewDetailsDataArr.language_text}</li></ul>

                            <hr></hr>
                            <h5>Job Descriptions</h5>
                            <div>
                                {staffViewDetailsDataArr.job_descriptions ? staffViewDetailsDataArr.job_descriptions : '.................................'}
                            </div>


                            <div className="btnsgroup">

                                {/* <Link disabled={isLoadingAcceptedOffer} className="batn batn-green" onClick={(e) => offerAccepted(offerID) } >Accept {isLoadingAcceptedOffer ? <><img   src={'/front/images/loader.gif'} /></> : <></>}</Link> */}
                                {/* <Link className="batn batn-red" onClick={(e) => jobDeclineModuleShow(offerID) } >Decline</Link> */}


                            </div>
                        </div>
                    </div>}

                    {currentActiveTab == 'about-recruiter' && <div className="tab-pane1" id="aboutrecruiterpan" role="tabpanel" aria-labelledby="aboutrecruiter">
                        {
                            <>
                                <div className="aboutrecuruter mt-2 d-flex">
                                    <div className="recruterimg">
                                        <img src={staffViewDetailsDataArr.recruiterdata.profilepic ? 'https://squadgoo.com.au/api.squadgoo.com.au/' + staffViewDetailsDataArr.recruiterdata.profilepic : '/front/images/user.svg'}></img>
                                    </div>
                                    <div className="recruterabout">
                                        <h5 className='text-capitalize'>{staffViewDetailsDataArr.recruiterdata.first_name + ' ' + staffViewDetailsDataArr.recruiterdata.last_name}</h5>
                                        <div className="d-flex recruiterdetailpop">
                                            <p>
                                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                {
                                                    staffViewDetailsDataArr.recruiterdata.email ?
                                                        <><a className="linktxt" href={`mailto:${staffViewDetailsDataArr.recruiterdata.email}`}>{staffViewDetailsDataArr.recruiterdata.email}</a> </>
                                                        : <>Email will show after accept offer</>
                                                }
                                            </p>
                                            <p>
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                                {
                                                    staffViewDetailsDataArr.recruiterdata.contact_number ?
                                                        <>
                                                            <a className="linktxt" href={`tel:${staffViewDetailsDataArr.recruiterdata.contact_number}`}>
                                                                {staffViewDetailsDataArr.recruiterdata.contact_country_code ? "+" + staffViewDetailsDataArr.recruiterdata.contact_country_code : ''} {staffViewDetailsDataArr.recruiterdata.contact_number}
                                                            </a>
                                                        </>
                                                        : <>Conatce will show after offer</>
                                                }
                                            </p>
                                            <p><i className="fa fa-industry" aria-hidden="true"></i>
                                                {staffViewDetailsDataArr.reccomp.industry ? staffViewDetailsDataArr.reccomp.industry_text : '-------------'}
                                            </p>
                                        </div>
                                        <div className="d-flex recruiterdetailpop">
                                            <p>
                                                <a href={staffViewDetailsDataArr.reccomp.website} target="_blank" className="linktxt">
                                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                                    {staffViewDetailsDataArr.reccomp.website ? staffViewDetailsDataArr.reccomp.website : '------------------'}
                                                </a>
                                            </p>
                                            <p className='text-capitalize'><i className="fa fa-map-marker" aria-hidden="true"></i>
                                                {`
                                                               ${staffViewDetailsDataArr.reccomp.address_street_name ? staffViewDetailsDataArr.reccomp.address_street_name : '------'} 
                                                               ${staffViewDetailsDataArr.reccomp.address_suburbs ? ` , ${staffViewDetailsDataArr.reccomp.address_suburbs}` : '------'}
                                                               `}
                                            </p>
                                        </div>
                                        <ShowSoacialMedia data={staffViewDetailsDataArr.recruiterdata} />
                                    </div>
                                </div>
                                <hr></hr>
                                <p>
                                    {staffViewDetailsDataArr.recruiterdata.profile_summary}
                                </p>

                                {staffViewDetailsDataArr.recruiterdata.user_type_role != 'rec_owners' && <>
                                    <hr></hr>
                                    <h5 className=''>Address details</h5>
                                    <div className="d-flex flex-wrap mt-2 joblistbox">
                                        <div className="icontxt"> Building No.: <span>{staffViewDetailsDataArr.recruiterdata.address_building_no}</span></div>
                                        <div className="icontxt"> Street: <span>{staffViewDetailsDataArr.recruiterdata.address_street_name}</span></div>
                                        <div className="icontxt"> Suburbs: <span>{staffViewDetailsDataArr.recruiterdata.address_suburbs}</span></div>
                                        <div className="icontxt"> Unit No.: <span>{staffViewDetailsDataArr.recruiterdata.address_unit_no}</span></div>
                                        <div className="icontxt"> State: <span>{staffViewDetailsDataArr.recruiterdata.address_user_state}</span></div>
                                    </div>
                                </>}

                                {
                                    staffViewDetailsDataArr.recruiterdata.user_type_role == 'rec_owners' &&
                                    <>

                                        <hr></hr>
                                        <h5 className=''>Comapny details</h5>
                                        <div className="d-flex flex-wrap mt-2 joblistbox">
                                            <div className="icontxt"> Comapny Name: <span>{staffViewDetailsDataArr.reccomp.name ? staffViewDetailsDataArr.reccomp.name : '........'}</span></div>
                                            <div className="icontxt"> Company website: <span>{staffViewDetailsDataArr.reccomp.website ? staffViewDetailsDataArr.reccomp.website : '........'}  </span></div>
                                            <div className="icontxt"> Industry: <span>{staffViewDetailsDataArr.reccomp.industry_text ? staffViewDetailsDataArr.reccomp.industry_text : '........'} </span></div>
                                        </div>
                                        <hr></hr>
                                        <h5 className=''>Comapny address</h5>
                                        <div className="d-flex flex-wrap mt-2 joblistbox">
                                            <div className="icontxt"> Building No.: <span>{staffViewDetailsDataArr.reccomp.address_building_no}</span></div>
                                            <div className="icontxt"> Street: <span>{staffViewDetailsDataArr.reccomp.address_street_name}</span></div>
                                            <div className="icontxt"> Suburbs: <span>{staffViewDetailsDataArr.reccomp.address_suburbs}</span></div>
                                            <div className="icontxt"> Unit No.: <span>{staffViewDetailsDataArr.reccomp.address_unit_no}</span></div>
                                            <div className="icontxt"> State: <span>{staffViewDetailsDataArr.reccomp.address_state}</span></div>
                                        </div>
                                    </>
                                }

                            </>
                        }
                    </div>}

                    {currentActiveTab == 'accepted-by' && <div className="tab-pane1" id="acceptedbypan" role="tabpanel" aria-labelledby="acceptedby">
                        <div className="table-responsive">

                            {
                                staffViewDetailsDataArr.offer_data.length > 0 ?
                                    <>
                                        <table id="acceptedbytabl" className="table admintbl profiletbl">
                                            <thead>
                                                <tr>
                                                    <th>Full name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Accepted Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    staffViewDetailsDataArr.offer_data.map((item, index) => (
                                                        <>

                                                            <tr className="active_tr">
                                                                <td className="text-capitalize">
                                                                    <Link target='_blank' to={'/jobseeker-detail?id=' + item.unique_id}>
                                                                        {item.first_name + ' ' + item.last_name}
                                                                    </Link>

                                                                </td>
                                                                <td>{item.email}</td>
                                                                <td>{item.contact_number}</td>
                                                                <td>{item.offer_created_date2}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    </>
                                    :
                                    <>
                                        <p className='text-center'>No offers have been accepted for this job.</p>
                                    </>
                            }


                        </div>
                    </div>}

                </div>
            </>}
        </>
    );
}
export default JobOffersModals;