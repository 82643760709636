import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StarRating from '../../expocomp/StarRatingComp';
import ShowSoacialMedia from '../ShowSocialMediaComp'
import DoeanloadFileComp from '../DownloadFileComp';
import GoogleMapPathComp from '../GoogleMapPathComp';

const JsrProModalComp = (dataattr) => {

    const staffViewDetailsDataArr = dataattr.data;
    const jobStatusType = dataattr.jobStatusType;
    const [getCurrentWorkexp, setCurrentExp] = useState([]);
    const selectedOptionsEductions = (staffViewDetailsDataArr.all_educations_proof !== null && typeof (staffViewDetailsDataArr.all_educations_proof) != 'undefined') ? JSON.parse(staffViewDetailsDataArr.all_educations_proof) : [];
    const selectedOptionsQualification = (staffViewDetailsDataArr.all_qualifications !== null && typeof (staffViewDetailsDataArr.all_qualifications) != 'undefined') ? JSON.parse(staffViewDetailsDataArr.all_qualifications) : [];
    return (
        <>
            <div className="joblistbox staflistw jsrprofilemodal">
                <div className="d-flex flex-wrap stafflisttop">
                    <div className="staffimgg">
                        <img src={staffViewDetailsDataArr.profilepic} />
                    </div>
                    <div className="staffnamerating">
                        <div className='profiletopright'>
                        <h3 className='text-capitalize text-blueshade'>
                            {staffViewDetailsDataArr.first_name + ' ' + staffViewDetailsDataArr.last_name}

                            {

                                staffViewDetailsDataArr?.verifications_status ?
                                    (staffViewDetailsDataArr.verifications_status.verification_status ?
                                        <><img style={{marginTop:'-10px'}} src="/front/images/varified.png" /></>
                                        :
                                        <><img style={{marginTop:'-10px'}} src="/front/images/unvarified.png" /></>)
                                    :
                                    <></>
                            }
                        </h3>
                        </div>
                        
                        <div className="reting d-flex">
                            <div className="stars d-flex">
                                <StarRating rating={(typeof (staffViewDetailsDataArr.total_rating_count) != 'undefined' && staffViewDetailsDataArr.total_rating_count.average_rating != null) ? staffViewDetailsDataArr.total_rating_count.average_rating : 0} />
                            </div>
                            <p>
                                <Link className='m-0 cursor_pointer' target='_blank' to={`/reviews?uniqueid=${staffViewDetailsDataArr.unique_id}`} >
                                    {(typeof (staffViewDetailsDataArr.total_rating_count) != 'undefined' && staffViewDetailsDataArr.total_rating_count.average_rating != null) ? staffViewDetailsDataArr.total_rating_count.average_rating : 0} |
                                    <span className='ml-2' >{(typeof (staffViewDetailsDataArr.total_rating_count) != 'undefined' && staffViewDetailsDataArr.total_rating_count.total_count != null) ? staffViewDetailsDataArr.total_rating_count.total_count : 0} Reviews</span>
                                </Link>
                            </p>
                        </div>

                        <ShowSoacialMedia data={staffViewDetailsDataArr} nopadding={'yes'} />
                    </div>
                    {jobStatusType == 'accepted' && <div className='ml-auto chatnowicon'>

                        <Link className='candidate_chat_btn' to={`/chat?contactto=${staffViewDetailsDataArr.unique_id}`} >

                            <span className="text-safron cursor_pointer ml-2">
                                <i className="fa fa-comments-o" aria-hidden="true"></i> Chat Now
                            </span>
                        </Link>
                    </div>}
                </div>
                <hr></hr>
                <p className=''>{staffViewDetailsDataArr.profile_summary}</p>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mb-3">Basic details</h3>
                </div>
                <div className="d-flex flex-wrap stafdata">
                    <div className="icontxt"><img src="/front/images/location.png"></img> Location: <span className='text-capitalize'>{staffViewDetailsDataArr.address_suburbs}</span></div>
                    <div className="icontxt"><img src="/front/images/edulogo.png"></img> Education:
                        <span>
                            {
                                (selectedOptionsEductions.length > 0) ?
                                selectedOptionsEductions.map((item, index) => (
                                    <>{item.label} </>
                                )) :
                                <>---------</>
                            }
                        </span>
                    </div>
                    <div className="icontxt"><i className="fa fa-building" aria-hidden="true"></i> Qualification:
                        <span className=''>
                            {
                                (selectedOptionsQualification.length > 0) ?
                                selectedOptionsQualification.map((item, index) => (
                                    <>{item.label} </>
                                )) :
                                <>---------</>
                            }
                        </span>
                    </div>
                    <div className="icontxt"><img src="/front/images/multichat.png"></img> Speak: <span>{staffViewDetailsDataArr.language_text ? staffViewDetailsDataArr.language_text : 'English'}</span></div>
                    <div className="icontxt"><i className="fa fa-birthday-cake" aria-hidden="true"></i>Date of birth:
                        <span>
                            dd-
                            {staffViewDetailsDataArr.dob_month ? staffViewDetailsDataArr.dob_month : 'mm'}-
                            {staffViewDetailsDataArr.dob_year ? staffViewDetailsDataArr.dob_year : 'yyyy'}
                        </span>
                    </div>
                </div>
            </div>
            <hr>
            </hr>
            <div className=" work-experiencesec commonform">
                <div className="d-flex align-items-center1 justify-content-between">
                    <h3 className="mb-3">Address details</h3>
                </div>
                <div className="editblock">
                    <div className="editblockinner d-flex align-items-start">
                        <h4>
                            <div><span className="mr-2 firstcoldtl">Street name :</span><span className="text-capitalize">{staffViewDetailsDataArr.address_street_name ?? '......'}</span></div>
                            <div><span className="mr-2 firstcoldtl">Suburb :</span><span className="text-capitalize">{staffViewDetailsDataArr.address_suburbs ?? '......'}</span></div>
                            <div><span className="mr-2 firstcoldtl">State:</span><span className="text-capitalize">{staffViewDetailsDataArr.address_user_state_name ?? '......'}</span></div>
                        </h4>
                    </div>
                </div>
            </div>
            <hr>
            </hr>
            {
                jobStatusType == 'accepted' ?
                    <>
                        <div className=" work-experiencesec commonform">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="mb-3">Contact details</h3>
                            </div>
                            <div className="editblock">
                                <div className="editblockinner d-flex ">
                                    <h4>
                                        <div className='mr-10'><span className="mr-2 firstcoldtl">Email :</span><span className="">{(staffViewDetailsDataArr.email) ? staffViewDetailsDataArr.email : <><span className="infowrap">.......... <i className="fa fa-info"></i><span className="onhoverinfo">Offer is not accepted</span></span></>}</span></div>
                                        <div><span className="mr-2 firstcoldtl">Phone :</span>
                                            <span className="text-capitalize">
                                                {(staffViewDetailsDataArr.contact_number) ?
                                                    '+' + staffViewDetailsDataArr.contact_country_code + '-' + staffViewDetailsDataArr.contact_number :
                                                    <><span className="infowrap">.......... <i className="fa fa-info"></i><span className="onhoverinfo">Offer is not accepted</span></span></>}</span></div>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <hr>
                        </hr>
                    </>
                    :
                    <></>
            }
            <div className=" work-experiencesec commonform">
                {!getCurrentWorkexp.work_expe_jobtitle && <div className="d-flex align-items-center1 justify-content-between">
                    <h3 className="mb-3">Total Experience</h3>
                </div>}


                {
                    staffViewDetailsDataArr.workexperience ?
                        <>
                            <div className="table-responsive">
                                {/* {getCurrentWorkexp.work_expe_jobtitle} */}
                                {!getCurrentWorkexp.work_expe_jobtitle && <table className='table experienctbl'>
                                    <thead>
                                        <tr>
                                            <th>Organization</th>
                                            <th>Jobtype</th>
                                            <th>Worked from</th>
                                            <th>Worked till</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {
                                        staffViewDetailsDataArr.workexperience.map((item, index) => (
                                            <>

                                                <tbody className='text-capitalize'>
                                                    <tr className='cursor_pointer' key={item}>
                                                        <td>{item.work_expe_companyname}</td>
                                                        <td>{item.work_expe_jobtitle}</td>
                                                        <td>{item.work_expe_duration_from_month}-{item.work_expe_duration_from_year}</td>
                                                        <td>{item.work_expe_duration_to_month}-{item.work_expe_duration_to_year}</td>
                                                        <td>
                                                            <span onClick={() => setCurrentExp(item)} className='text-ornage'>More info</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </>
                                        ))
                                    }
                                </table>}
                                {/* getCurrentWorkexp  , setCurrentExp */}
                                {getCurrentWorkexp.work_expe_jobtitle && <>
                                    <div className="row m-0 align-items-start editfieldss workexp-description">
                                        <div className="col-md-12 d-flex justify-content-between pb-0 mb-2">
                                            <h3 className="mb-0 text-capitalize">{getCurrentWorkexp.work_expe_jobtitle}</h3>
                                            <span className='cursor_pointer' onClick={() => setCurrentExp([])}><i className="fa fa-close text-orange "></i></span>
                                        </div>

                                        <div className="col-md-6  mb-2">
                                            <div className="editblock">
                                                <label>Company name</label>
                                                <div className="editblockinner d-flex align-items-start">
                                                    <h4 className="text-capitalize">{getCurrentWorkexp.work_expe_companyname}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6  mb-2">
                                            <div className="editblock">
                                                <label>Country</label>
                                                <div className="editblockinner d-flex align-items-start">
                                                    <h4>{getCurrentWorkexp.work_expe_country_name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12  mb-2">
                                            <div className="editblock">
                                                <label>Work duration</label>
                                                <div className="editblockinner d-flex align-items-start">
                                                    <h4>
                                                        <span className="mr-2">{getCurrentWorkexp.work_expe_duration_from_month}-{getCurrentWorkexp.work_expe_duration_from_year} to {getCurrentWorkexp.work_expe_duration_to_month}-{getCurrentWorkexp.work_expe_duration_to_year}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12  mb-2">
                                            <div className="editblock">
                                                <label>Job description</label>
                                                <div className="editblockinner d-flex align-items-start">
                                                    <h4>{getCurrentWorkexp.work_expe_jobdescription}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12  mb-2">
                                            <label>Experience Proof</label>

                                            <div className='row'>


                                                {
                                                    (getCurrentWorkexp.workexperience_payslips.length > 0) ?
                                                        (<>
                                                            {
                                                                getCurrentWorkexp.workexperience_payslips.map((iwp, key1) => (
                                                                    <>
                                                                        <div className='col-md-4 mb-2 '>
                                                                            <div className="documentwrap d-flex align-items-center ml-0 mt-1 mr-2">
                                                                                <a className='document_list' target="_blank" href={'https://squadgoo.com.au/api.squadgoo.com.au/' + iwp.document_url}>
                                                                                    <p>Payslip {key1 + 1}</p>
                                                                                </a>
                                                                                <div className="ml-auto d-flex">
                                                                                    <DoeanloadFileComp url={`https://squadgoo.com.au/api.squadgoo.com.au/${iwp.document_url}`} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>)
                                                        :
                                                        (
                                                            <>
                                                                <div className='col-md-12 mb-2 '> Not added</div>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>

                                        {
                                            (getCurrentWorkexp.workexperience_referencetype.length > 0 && getCurrentWorkexp.work_expe_prooftype == 'reference') ?
                                                <>
                                                    {
                                                        getCurrentWorkexp.workexperience_referencetype.map((reference, index_ref) => (
                                                            <>
                                                                <div className="col-md-12  mb-2">
                                                                    <div className="editblock">
                                                                        <label>References {index_ref + 1} {reference.admin_varification == 1 ? <><span className="badge bg-success">Verified </span></> : <><span className="badge bg-danger">Not Verified </span></>}</label>
                                                                        <div className="refferancbolok spacindsec editblockinner d-flex align-items-start">
                                                                            <h4>
                                                                                <div><span>Full Name:</span> <span className='text-capitalize'>{reference.name}</span></div>
                                                                                <div><span>Position:</span> {reference.position}</div>
                                                                                <div><span>Contact number:</span> {reference.contactno}</div>
                                                                                <div><span>Email address:</span> {reference.email}</div>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </>
                                                : ''
                                        }
                                    </div>
                                </>}
                            </div>
                        </>
                        :
                        <>
                            <p>There is no Experiences found</p>
                        </>
                }
            </div>
            <div className="col-md-12  mb-2">
                {dataattr && dataattr.data.length != 0 && dataattr.formdata && dataattr.formdata.searchtype == "quick_search" && <GoogleMapPathComp pathA={dataattr.data.address_suburbs} pathB={dataattr.formdata.job_location} formdata={dataattr.formdata} />}
            </div>
        </>
    );
}
export default JsrProModalComp;