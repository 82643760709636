import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux'
//import { basicContactValidationCheck } from '../../redux_state/reducres/checkDocumentstatus';
import { basicContactValidationCheck } from '../redux_state/reducres/checkDocumentstatus';
// import PhoneVerificationModel from './PhoneVerificationModel';

import ModalDialog from '../expocomp/WarningModal';

const ContactDetailsComp = () => {


    const [isHidden, setIsHidden] = useState(true);
    const toggleHidden = () => { setIsHidden(!isHidden); };
    const [contactStatus , setContactStatus] = useState(0);
    const userdetails = JSON.parse(localStorage.getItem("squadGologinSession"));
    // const [userDetailsArray, setUserDetailsArray] = useState([]);

    const fetchData = async (unique_id) => {
        if(unique_id){
            try {
              const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/userdetails/${unique_id}`);
              if (resData.data.status === 1) {
                //console.log(contactStatus)
                setContactStatus(parseInt(resData.data.data.results.contact_status))
              }
            } catch (error) {
              console.log(error);
            }
        }
      };


    const [getAllCountry, setGetallCountry] = useState([]);
    const [contactDetails, setContactDetails] = useState({
        email: userdetails.email,
        contact_country_code: userdetails.contact_country_code,
        contact_number: userdetails.contact_number
    });


    const dispatch = useDispatch();

    const getNewSessionData = async () => {
        try {
            const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetailforsession/" + userdetails.login_token);
            if (resData.data.status == 1) { localStorage.setItem('squadGologinSession', JSON.stringify(resData.data.data)) }
        } catch (error) { console.log(error); }
    }

    const [isAccVerified, setIsAccVerified] = useState('false');
    const getUserDetails = () => {
        (async () => {
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/userdetails/" + userdetails.unique_id);
                if (resData.data.status == 1) {
                    //setUserDetailsArray(resData.data.data.results);
                    setContactDetails(resData.data.data.results);
                    setIsAccVerified(resData.data.data.results.verifications_status.statustype.contact_details);
                }
            } catch (error) { console.log(error); }
            try {
                const resData = await axios.get("https://squadgoo.com.au/api.squadgoo.com.au/get_all_country");
                if (resData.data.status == 1) { setGetallCountry(resData.data.data.results); }
            } catch (error) { console.log(error); }

        })();
    }

    useEffect(() => {
        if (userdetails.unique_id) {
            fetchData(userdetails.unique_id);
            setContactStatus(userdetails.contact_status);
            getUserDetails()
        } else { setTimeout(function () { window.location.replace("/login"); }, 100) }
    }, []);



    const [validated, setValidated] = useState(false);
    const oncontactDetailsSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            setValidated(true); // Show validation errors
            return; // Don't proceed if the form is invalid
        }

        if (isAccVerified == true) {
            setIsModalShow(true);
        } else {
            formSubmitData();
        }
        setValidated(false);
    };


    const formSubmitData = async () => {
        try {
            const formData = new FormData();
            formData.append('contact_country_code', contactDetails.contact_country_code);
            formData.append('contact_number', contactDetails.contact_number);
            formData.append('contact_status', 0);
            if (userdetails.unique_id) {
                formData.append('userid', userdetails.unique_id);
            }
            const response = await axios.post(
                `https://squadgoo.com.au/api.squadgoo.com.au/userdetails_data_update`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            if (response.data.status === 1) {
                getUserDetails(); // Refresh user details
                toast.success(response.data.message);
                dispatch(basicContactValidationCheck(1));
                setIsModalShow(false); // Close the modal
            } else {
                toast.warning(response.data.message);
                setIsModalShow(false); // Close the modal
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            toast.error('An error occurred while submitting the form.');
        }
    };

    const [isModalShow, setIsModalShow] = useState(false);
    const actionConfirmed = () => { formSubmitData(); };



    const [contactNumberError, setContactNumberError] = useState('');
    const contactDetailsUpdateFun = (event) => {
        var { name, value } = event.target;
        if (name == 'contact_number') {
            const regex = /^\d+$/; // 10-digit number pattern
            if (value && !regex.test(value)) {
                return false;
            }
            setContactNumberError('');
            if (value.length > 9) {
                (async () => {
                    try {
                        const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${userdetails.unique_id}/${value}?action=update`);
                        if (resData.data.status == 1) {
                            value = '';
                            setContactNumberError(resData.data.message);
                        }
                    } catch (error) { console.log(error); }
                })();
            }
        }
        setContactDetails((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    /*-------------Varify Email modal-----------------*/

    /*modela hid enad seek*/
    const [showModal, setShowModal] = useState(false);
    const varifyEmailModel = () => { resendVerificationCode('newcall'); setShowModal(true); };
    const handleHideModal = () => { setShowModal(false); };

    /*--------------------Email verification action -----------------------------*/
    const [varificationCode, setVarificationCode] = useState('');
    //const [validated, setValidated] = useState(false);
    const [serverResMessage, setServerResMessage] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = new FormData();
            formData.append('unique_id', userdetails.unique_id);
            formData.append('contact_number', userdetails.contact_number);
            formData.append('contact_country_code', userdetails.contact_country_code);
            formData.append('contact_varification_code', varificationCode);
            axios.post('https://squadgoo.com.au/api.squadgoo.com.au/check_contact_activation', formData)
                .then((response) => {
                    if (response.data.status == 1) {
                        setServerResMessage(response.data.message);
                        getNewSessionData();
                        dispatch(basicContactValidationCheck(1));
                        handleHideModal(); toggleHidden();
                        setContactStatus(1);
                        //setTimeout(function() { window.location.reload(); }, 3000);  
                    }
                    else { setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 5000); }
                })
                .catch((error) => { console.error(error); });
        }
        setValidated(true);
    };
    //
    const resendVerificationCode = (callingtype ='functioncall') => {
        const formData = new FormData();
        formData.append('unique_id', userdetails.unique_id);
        formData.append('contact_varification_code', varificationCode);
        axios.post('https://squadgoo.com.au/api.squadgoo.com.au/contact_resend_verification', formData)
            .then((response) => {

                console.log(callingtype);
               if(callingtype == 'functioncall'){
                   setServerResMessage(response.data.message); setTimeout(function () { setServerResMessage('') }, 5000);
               }
            })
            .catch((error) => { console.error(error); });
    }

    return (
        <>
            {/* <ToastContainer />*/}
            <div className="card-box tfn-sec">
                <div className="d-flex justify-content-between">
                    <h3 className="mb-3">
                        Email and Phone number

                        {
                            isAccVerified == true ?
                                <><img className='veri_unveri_icon' src="/front/images/vfy.png" data-toggle="tooltip" data-placement="top" title="verified"></img></> :
                                isAccVerified == false ?
                                    <><img className='veri_unveri_icon' src="/front/images/uvfy.png" data-toggle="tooltip" data-placement="top" title="Your Profile is in Review"></img></> :
                                    <></>
                        }
                    </h3>
                    {(contactStatus == 0) && <div className="editbtn" onClick={toggleHidden} ><img src="/front/images/edit.png" /></div>}
                </div>

                <div className="">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">{contactDetails.email ?? <><b>...................</b></>}</p>
                        {
                            userdetails.validationdata_check.basicemail_validation == 0 ?
                                <><NavLink onClick={varifyEmailModel} href="#" className="batn batn-rounded rounded-orange">Verify</NavLink></>
                                :
                                <><span className="batn batn-rounded rounded-green">Verified</span></>
                        }

                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">+{contactDetails.contact_country_code ? contactDetails.contact_country_code : '61'} {contactDetails.contact_number ? contactDetails.contact_number : '000 000 0000'}</p>
                        {
                            contactStatus == 0 ?
                                <><NavLink onClick={varifyEmailModel} href="#" className="batn batn-rounded rounded-orange">Verify</NavLink></>
                                :
                                <><span className="batn batn-rounded rounded-green">Verified</span></>
                        }
                    </div>
                    {!isHidden &&
                        <>
                            <Form noValidate validated={validated} onSubmit={oncontactDetailsSubmit}>
                                <div className={`phonefield d-flex align-items-center ${!contactNumberError && 'mb-4'} `}>
                                    <select className="form-control" name='contact_country_code' onChange={contactDetailsUpdateFun} >
                                        {
                                            getAllCountry.length ?
                                                <>
                                                    {
                                                        getAllCountry.map((item, index) => (
                                                            <option selected={contactDetails.contact_country_code == item.phonecode ? true : false} value={item.phonecode}>+{item.phonecode}</option>
                                                        ))
                                                    }
                                                </>
                                                : ''
                                        }
                                    </select>
                                    <input
                                        type="tel"
                                        name='contact_number'
                                        required={true}
                                        value={contactDetails.contact_number}
                                        className="form-control"
                                        placeholder="Enter mobile number"
                                        onChange={contactDetailsUpdateFun}
                                        maxLength={10}
                                    />
                                </div>
                                {contactNumberError && <div className='uniquenu_error'>
                                    {contactNumberError}
                                </div>}


                                <div className="col-md-12 mt-3">
                                    <button className="batn batn-rounded rounded-orange mr-2">Save</button>
                                    <button onClick={(e) => setIsHidden(true)} className="batn batn-rounded rounded-orange mr-2">Cancel</button>
                                </div>
                            </Form>
                            {/* -------------------confirem to save---------------------- */}
                            <ModalDialog
                                show={isModalShow}
                                onClose={() => setIsModalShow(false)} // Close the modal without taking action
                                onConfirm={actionConfirmed} // Action to take when confirming
                                title="Existing details are already verified, Are you sure to change it?"
                                message="This may cause you to wait for re-verification from Squad Goo."
                            />
                            {/* ----------------------------------------- */}
                        </>
                    }
                </div>
            </div>
            {/* ----------------------------------------- */}
            <Modal show={showModal} onHide={handleHideModal}>
                <div className="modal-dialog1 findastaffmodle">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={handleHideModal} data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="loginsignupwrapformwrap">
                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform loginform">
                                    <div className="formbranding text-center"><img src="/front/images/formlogo.png" /></div>
                                    <div className="text-center my-1"><img src="/front/images/varifyicon.png" /></div>
                                    <h2 className="text-center mb-2">Verify your contact number </h2>
                                    <div className="text-center formbtmtxt mb-4">
                                        <p>We have sent a otp on your contact number</p>
                                    </div>
                                    <div className="form-group mb-0">
                                        <input
                                            required
                                            type="text"
                                            onChange={(event) => setVarificationCode(event.target.value)}
                                            className="form-control varificationfield"
                                            placeholder="Enter varification code"
                                        />
                                    </div>
                                    <div className="form-group mb-0">
                                        <NavLink className="" onClick={() => resendVerificationCode('functioncall') } >Resend verification code</NavLink>
                                    </div>
                                    {serverResMessage ? <p className="text-danger text-center  mb-0">{serverResMessage}</p> : ''}
                                    <div className="form-group mb-2"><input type="Submit" className="batn batn-orange" value="Verify" /></div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ----------------------------------------- */}
        </>
    );
}
export default ContactDetailsComp;