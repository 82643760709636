import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link } from 'react-router-dom';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//hideAndSeekMyRequestModel
import SuperAdminSidebar from './superadmin_sidebar';
import SuppReqConverationComp from '../SuppReqConverationComp';

const AdminReviewCall = () => {

    const typeofpriority = ['High', 'Medium', 'Low'];
    const statusType = ['All', 'Open', 'Awating', 'Solved'];

    const useriddata = JSON.parse(localStorage.getItem("squadGologinSession"));
    const [orderByData, setorderByData] = useState('ASC');
    // const [supportFilterFun, sethandleInput] = useState('');
    const [myRequestDataArr, setmyRequestDataArr] = useState([]);
    const [myRequestDataArrCopy, setmyRequestDataArrCopy] = useState([]);
    const [screenLoader, setScreenLoader] = useState(true);
    const [currentEditId, setcurrentEditId] = useState(0);
    const [searchText, setSearchText] = useState('');
    const showUserBlockAction = (id, status) => {
        setcurrentEditId(id);
        //setIsBlockModelShow(true) 
        //alert(id)
        get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/get_request_for_call/" + useriddata.login_token + '?blockid=' + id + '&blockstatus=' + status + '&search=' + searchValue + '&order_by=' + orderByData)
    }
    const [isBlockModelShow, setIsBlockModelShow] = useState(false);
    const [searchValue, setsearchValue] = useState('');
    const showUserBlockActionExecute = () => {
        get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/get_request_for_call/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData)
    }



    const get_requesteddata = (apiurl) => {
        setScreenLoader(true);
        (async () => {
            try {
                const resData = await axios.get(apiurl);
                if (resData.data.status == 1) {
                    setmyRequestDataArr(resData.data.data);
                    setIsBlockModelShow(false);
                    setmyRequestDataArrCopy(resData.data.data);
                    setScreenLoader(false);
                }
            } catch (error) { console.log(error); }
        })();
    }


    const [isDesModalShow, setisDesModalShow] = useState(false);
    const [getCurrentcallData, setgetCurrentcallData] = useState([]);
    const showSupportCallData = (data) => {
        setisDesModalShow(true);
        setgetCurrentcallData(data);
    }

    useEffect(() => {
        if (useriddata) { get_requesteddata("https://squadgoo.com.au/api.squadgoo.com.au/get_request_for_call/" + useriddata.login_token + '?blockid=' + currentEditId + '&blockstatus=pending&search=' + searchValue + '&order_by=' + orderByData); }
    }, [orderByData, searchValue]);


    // const  supportFilterFun  = (e) => {
    //     let value = e.target.value ;
    //     setsearchValue(value);
    // }


    const supportFilterSelectFun = (term) => {
        // Reset to the original data first
        if (term === 'All' || term.length === 0) {
            setmyRequestDataArr(myRequestDataArrCopy);
            return; // Exit the function early
        }
    
        // Filter data from the original data array
        const filteredData = myRequestDataArrCopy.filter((item) =>
            item.status.toLowerCase().includes(term.toLowerCase())
        );
        setmyRequestDataArr(filteredData);
    };
    


    const supportFilterFun = (term) => {
       
        setSearchText(term);
        if (term.length > 0 && term != 'All') {
            const filteredData = myRequestDataArr.filter((item) =>
                item.user_details.first_name.toLowerCase().includes(term.toLowerCase()) ||
                item.user_details.last_name.toLowerCase().includes(term.toLowerCase()) ||
                item.user_details.contact_number.toLowerCase().includes(term.toLowerCase())
            );
            setmyRequestDataArr(filteredData)
        } else {
            setmyRequestDataArr(myRequestDataArrCopy)
        }
    };

    const recTyeOption = [
        { key: 'pending', value: 'Pending' },
        { key: "not_answered", value: "Not Answered" },
        { key: "answered", value: "Answered" },
        { key: "busy", value: "Busy" },
        { key: "voicemail", value: "Voicemail" },
        { key: "missed_call", value: "Missed Call" },
        { key: "scheduled_callback", value: "Scheduled Callback" },
        { key: "disconnected", value: "Disconnected" },
        { key: "follow_up", value: "Follow-Up" },
        { key: "completed", value: "Completed" },
    ];

    return (
        <>


            <div className="dashboarwrap">

                <div className="pagebanner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Requested Calls</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ToastContainer />*/}
                <div className="jobbtmsec py-5 helppage">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-3 adminsidebar">
                                <SuperAdminSidebar></SuperAdminSidebar>
                            </div>
                            <div className="col-md-9">
                                <div className="box mb-4">
                                    {/* searchText setSearchText */}
                                    {

                                        <>
                                            <div className="table_filter mb-2 d-flex align-items-center justify-content-between commonform">
                                                <div className="tblsearch d-flex">
                                                    <input type="text" className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => supportFilterFun(e.target.value)}
                                                        value={searchText}
                                                        style={{width:'100px'}}
                                                    />
                                                  
                         

                                                    <select className="width-120 form-control ml-3 text-capitalize" onChange={(e) => supportFilterSelectFun(e.target.value)} >
                                                        <option value="">Select status</option>
                                                       {
                                                            recTyeOption.map((item2, index) => (
                                                                <option value={item2.key} key={item2.key}>{item2.value}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="tblrightfilters d-flex align-items-center">
                                                    {/* <select className="form-control ml-3 text-capitalize" onChange={handleInputByStatus} >

                                                            {
                                                                statusType.map((item, index) => (
                                                                    <option key={item} value={item}>{item}</option>
                                                                ))
                                                            }
                                                        </select> */}



                                                    <span onClick={() => {
                                                        setSearchText('');
                                                        setmyRequestDataArr(myRequestDataArrCopy)
                                                    }}
                                                        className="reseticon filtericon" >
                                                        <i className="fa fa-refresh"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {
                                                screenLoader ?
                                                    <>
                                                        <div className={`tab-pane fade show active mt45px`} >
                                                            <div className='loader_container'>
                                                                <p className='text-center'><img className='width-100 loader_image' src={'/front/images/loader.gif'} /></p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="table-responsive">
                                                        <table className="table admintbl profiletbl">
                                                            <thead>
                                                                <tr>
                                                                    <th className='cursor_pointer'>S.N.</th>
                                                                    <th>Request by</th>
                                                                    <th>Contact</th>
                                                                    <th>Created date</th>
                                                                    <th>Request date</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>


                                                                <>
                                                                    {
                                                                        myRequestDataArr.length ?
                                                                            <>
                                                                                {
                                                                                    myRequestDataArr.map((item, index) => (
                                                                                        <tr>
                                                                                            <td>{index + 1}</td>
                                                                                            <td className='text-capitalize'>
                                                                                                <Link className="text-website text-decoration-none1" to={`${item.user_details.user_type == 3 ? '/jobseeker-detail?backto=adminticallhistory&id=' + item.user_details.unique_id : '/recruiter-detail?backto=admincallhistory&id=' + item.user_details.unique_id}`} >
                                                                                                    {item.user_details.first_name + ' ' + item.user_details.last_name}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td>+{item.user_details.contact_country_code}-{item.user_details.contact_number}</td>
                                                                                            <td>{item.created_date}</td>
                                                                                            <td>{item.schedule_date_time}</td>
                                                                                            <td>
                                                                                                {/* <Link onClick={(e) => showUserBlockAction(item.id)} className={`text-capitalize btnsmall  ${item.status == 1 ? 'bluebtn' : 'greenbtn'}`}>
                                                                                                    {(item.status == 1) ? 'Peniding' : 'Done'}
                                                                                                </Link> */}
                                                                                                <select className="width-120 form-control ml-3 text-capitalize" onChange={(e) => showUserBlockAction(item.id, e.target.value)} >
                                                                                                    {
                                                                                                        recTyeOption.map((item2, index) => (
                                                                                                            <option selected={item2.key == item.status} value={item2.key} key={item2.key}>{item2.value}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>

                                                                                                <div className="d-flex">
                                                                                                    {/* <Link data-toggle="tooltip" title="User full description" to={`${item.user_details.user_type == 3 ? '/jobseeker-detail?backto=callhistory&id=' + item.user_details.unique_id : '/recruiter-detail?backto=callhistory&id=' + item.user_details.unique_id}`} className="ml-3"><img src="/front/images/Show.svg" /></Link> */}
                                                                                                    <Link onClick={() => showSupportCallData(item)} data-toggle="tooltip" title="User full description" className="ml-3"><img src="/front/images/Show.svg" /></Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <tr>
                                                                                    <td className='text-center' colSpan={7}>No information available to display</td>
                                                                                </tr>
                                                                            </>
                                                                    }
                                                                </>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                            }
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Modal className="modal fade modalsmall jobacceptmodle" id="jobacceptmodle" show={isBlockModelShow} onHide={() => setIsBlockModelShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setIsBlockModelShow(false)}></button>
                            <div className="text-center mb-3">
                                {/* <img src="/front/images/greencheck.svg" /> */}
                            </div>
                            <h5 className="text-center mb-4">Do you want to change status?</h5>

                            <div className="btnsgroup text-center">
                                <Link onClick={showUserBlockActionExecute} className="batn batn-orange" >Yes</Link>
                                <Link onClick={() => setIsBlockModelShow(false)} className="batn batn-orange batn-border">No</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="modal fade supportcallhistory" id="supportcallhistory" show={isDesModalShow} onHide={() => setisDesModalShow(false)}>
                <div className="modal-dialog1">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => setisDesModalShow(false)}></button>


                            {
                                typeof (getCurrentcallData.schedule_date_time) != 'undefined' ?
                                    <>
                                        <div id="contentdata_show">
                                            <p className="reportdate d-flex">
                                                Schedule date time :
                                                {getCurrentcallData.schedule_date_time}
                                            </p>
                                            <h4 className='text-capitalize m-0'>
                                                Current Status :
                                                {getCurrentcallData.status}</h4>
                                            <p className='p-0 m-0'>
                                                Reason for call
                                                <br></br>
                                                {getCurrentcallData.reason_for_call}
                                            </p>
                                            <h4>Call history</h4>
                                            <ul>
                                                {
                                                    getCurrentcallData.history.map((item, inde) => (
                                                        <>
                                                            <li>{item.formatted_created_date} - <span className='text-capitalize'>{item.status}</span></li>
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }





                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}
export default AdminReviewCall;