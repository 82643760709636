import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import {useNavigate} from 'react-router-dom';

import { useUser } from '../../admin/ApiDataContext';

import { useSelector, useDispatch } from 'react-redux'
import { docStatusValueCheck } from '../../redux_state/reducres/checkDocumentstatus';
import { userdataarray_status } from '../../redux_state/reducres/userDataRegister';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

function GmailLoginApp() {
    const navigate = useNavigate();
    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState([]);

    const dispatch = useDispatch();
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    const { getDataDuringLogin } = useUser();



    const search_and_get_results2 = async (gemail='',token='') => {

        (async () => {
            const loginFormData = {
                email : gemail ,
                password : token ,
                google_token : token ,
            }
            let getres = await fetch("https://squadgoo.com.au/api.squadgoo.com.au/login", {
                method: "POST",
                body: JSON.stringify(loginFormData),
            });
            let resJson = await getres.json();
            console.log(getres)
            if(resJson.status == 1){
               

                let getuserdata = resJson.data.user_data;
                let session_storage_data = resJson.data.session_storage_data;

                dispatch(userdataarray_status(getuserdata));
                localStorage.setItem('app_version', 1);
                localStorage.setItem('is_user_login', 1);
                localStorage.setItem('squadGologin', JSON.stringify(getuserdata));
                localStorage.setItem('profileStatus', getuserdata.profile_enabled)
                localStorage.setItem('squadGologinSession', JSON.stringify(getuserdata))
                localStorage.setItem('sessionStorageData', JSON.stringify(session_storage_data))
                localStorage.setItem('validationdataCheckData', JSON.stringify(getuserdata.validationdata_check));
                dispatch(docStatusValueCheck({ doctype: 'all', type: 'onload', sessiondata: JSON.stringify(getuserdata.validationdata_check) }));
                getDataDuringLogin(getuserdata.unique_id);
                if (getuserdata.user_type == 1) {
                localStorage.setItem('squadGoUserType', 'admin');
                navigate('/admin-dashboard')
                } else if (getuserdata.user_type == 4) {
                localStorage.setItem('squadGoUserType', 'admin');
                navigate('/admin-dashboard')
                } else if (getuserdata.user_type == 2) {
                localStorage.setItem('squadGoUserType', 'recruiter');
                navigate('/recruiter/dashboard')
                } else if (getuserdata.user_type == 3) {
                localStorage.setItem('squadGoUserType', 'jobseeker');
                navigate('/jobseeker/dashboard')
                } else {
                    toast.warning("This email id is not exist.")  
                    navigate('/')
                }
            } else {
                toast.warning("This email id is not exist.")  
            }
            
        })();
        
    }

    useEffect(
        () => {
            if (user && user.access_token) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                        search_and_get_results2(res.data.email,user.access_token)
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

 

      

    return (
        <div>
            <ToastContainer />
            <button onClick={() => login()} className="btn social-login hover-up mb-20"  >
                    <img src="/front/images/icon-google.svg" alt="squadgoo" /><strong>Sign in with Google</strong>
                </button>
        </div>
    )
}
export default GmailLoginApp;